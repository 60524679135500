import decode from "jwt-decode";

import { Dispatch } from "./reducer";
import {
  destroyToken,
  setToken,
  getToken,
  validToken,
} from "../../lib/localStorage";
import {
  loginUser,
  createUserPost,
  onboardingRequest,
  resetPasswordRequest,
  requestPasswordReset as requestPasswordResetRequest,
} from "../../lib/authRequests";
import {
  importSubscriptionRequest,
  getActiveSubscriptionRequest,
  getOldPlanRequest,
} from "../../lib/subscriptionRequests";
import {
  editCurrentUserRequest,
  getCurrentUserRequest,
  getPaymentMethodsRequest,
  getWineSearchesRequest,
  updateWineSearchesRequest,
} from "../../lib/userRequests";

export const logout = async (dispatch: Dispatch, cellarsDispatch: Dispatch) => {
  clearAuth(dispatch);
  cellarsDispatch({
    type: "clearCellarsState",
  });
  destroyToken();
  return;
};

export const login = async (dispatch: Dispatch, email: string, pw: string) => {
  dispatch({
    type: "loadingAuth",
  });

  try {
    const response = await loginUser(email, pw);
    setToken(response.data.token);
    return dispatch({
      type: "setAuthUser",
      payload: {
        ...response.data,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

export const resetPassword = async (
  dispatch: Dispatch,
  email: string,
  verifyEmailToken: string,
  password: string,
  confirmPassword: string
) => {
  try {
    const response = await resetPasswordRequest(
      email,
      verifyEmailToken,
      password,
      confirmPassword
    );
    setToken(response.data.token);
    return dispatch({
      type: "setAuthUser",
      payload: {
        ...response.data,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

export const createUser = async (
  dispatch: Dispatch,
  userParams: any,
  history: any,
  params: any
) => {
  dispatch({
    type: "postingUser",
  });

  if (!validateUserParams(userParams)) {
    return dispatch({
      type: "setAuthError",
      payload: "Error: Please enter valid information.",
    });
  }

  try {
    const response = await createUserPost(userParams);
    const title = params.get("title");
    const coupon = params.get("coupon");

    const titleQuery = title ? "?title=" + title : "";
    const couponQuery = coupon ? "&coupon=" + coupon : "";

    setToken(response.data.token);
    dispatch({
      type: "setAuthUser",
      payload: {
        ...response.data,
      },
    });
    history.push(
      response.data.user.old_sub_id ? `/add-payment-method` : "/select-plan" + titleQuery + couponQuery
    );
  } catch (err) {
    dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

export const importSubscription = async (
  dispatch: Dispatch,
  oldSubId: string,
  history: any
) => {
  try {
    const response = await importSubscriptionRequest(oldSubId);
    dispatch({
      type: "setAuthUser",
      payload: {
        ...response.data,
      },
    });
    history.replace("/cellars");
  } catch (err) {
    dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

export const getOldPlan = async (dispatch: Dispatch, oldSubId: string) => {
  try {
    const response = await getOldPlanRequest(oldSubId);
    dispatch({
      type: "setOldPlan",
      payload: {
        ...response.data,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const requestPasswordReset = async (
  dispatch: Dispatch,
  email: string
) => {
  try {
    const response = await requestPasswordResetRequest(email);
    return dispatch({
      type: "startOnboarding",
      payload: response.data.err,
    });
  } catch (err) {
    return dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

export const startOnboarding = async (
  dispatch: Dispatch,
  email: string,
  forgotPassword: boolean
) => {
  try {
    const response = await onboardingRequest({ email, forgotPassword });
    return dispatch({
      type: "startOnboarding",
      payload: response.data.err,
    });
  } catch (err) {
    return dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

export const getCurrentUser = async (dispatch: Dispatch) => {
  try {
    const response = await getCurrentUserRequest();
    return dispatch({
      type: "setAuthUser",
      payload: {
        ...response,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

export const editCurrentUser = async (params: any, dispatch: Dispatch) => {
  try {
    const response = await editCurrentUserRequest(params);
    dispatch({
      type: "setMessage",
      payload: {
        message: "Changes were saved successfully.",
      },
    });
    return dispatch({
      type: "setAuthUser",
      payload: {
        ...response.data,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

export const getWineSearches = async (dispatch: Dispatch) => {
  try {
    const response = await getWineSearchesRequest();
    return dispatch({
      type: "setWineSearches",
      payload: {
        ...response,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

export const updateWineSearches = async (dispatch: Dispatch) => {
  try {
    const response = await updateWineSearchesRequest();
    return dispatch({
      type: "setWineSearches",
      payload: {
        wineSearches: response.data.wineSearches,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

export const getActiveSubscription = async (dispatch: Dispatch) => {
  try {
    const response = await getActiveSubscriptionRequest();
    return dispatch({
      type: "setActiveSubscription",
      payload: {
        ...response,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

export const getPaymentMethods = async (dispatch: Dispatch) => {
  try {
    const response = await getPaymentMethodsRequest();
    return dispatch({
      type: "setPaymentMethods",
      payload: {
        ...response,
      },
    });
  } catch (err) {
    return dispatch({
      type: "setAuthError",
      payload: err,
    });
  }
};

export const clearAuth = async (dispatch: Dispatch) => {
  dispatch({
    type: "clearAuth",
  });
};

export const checkToken = (dispatch: Dispatch) => {
  const token = getToken();

  if (validToken(token)) {
    const decoded: any = decode(token || "");

    if (decoded && decoded.userId) {
      dispatch({
        type: "setAuthUser",
        payload: { token, user: { id: decoded.userId } },
      });
      return true;
    } else {
      destroyToken();
      clearAuth(dispatch);
      return false;
    }
  }
};

export const showMessage = (dispatch: Dispatch, message: string) => {
  if(message) {
    dispatch({
      type: "setMessage",
      payload: {
        message: message,
      }
    })
  }
}

export const emailError = (email: string) => {
  if (validEmail(email)) {
    return "";
  } else {
    return "Please enter a valid email address.";
  }
};

export const passwordError = (password: string) => {
  if (validPassword(password)) {
    return "";
  } else {
    return "Your password must be at least 6 characters.";
  }
};

export const confirmPasswordError = (
  password: string,
  confirmPassword: string
) => {
  if (password === confirmPassword) {
    return "";
  } else {
    return "Passwords do not match.";
  }
};

export const newPasswordError = (oldPassword: string, newPassword: string) => {
  if (!validPassword(newPassword)) {
    return "Your password must be at least 6 characters.";
  } else if (newPassword === oldPassword) {
    return "New password should be different from the current password.";
  } else {
    return "";
  }
};

export const firstNameError = (firstName: string) => {
  if (validName(firstName)) {
    return "";
  } else {
    return "First name is required.";
  }
};

export const lastNameError = (lastName: string) => {
  if (validName(lastName)) {
    return "";
  } else {
    return "Last name is required.";
  }
};

export const validateUserParams = (params: any) => {
  const { firstName, lastName, email, password } = params;
  return (
    validName(firstName) &&
    validName(lastName) &&
    validEmail(email) &&
    validPassword(password)
  );
};

export const validName = (name: string) => {
  return name !== null && name !== "null" && name !== "" && name.length > 0;
};

export const validPassword = (password: string) => {
  return (
    password !== null &&
    password !== "null" &&
    password !== "" &&
    password.length > 5
  );
};

export const validEmail = (email: string) => {
  return /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email);
};

export const vintageError = (vintage: string) => {
  if (!validVintage(vintage)) {
    return "Please enter a valid vintage";
  } else {
    return "";
  }
};

export const validVintage = (vintage: string) => {
  const currentYear = new Date().getFullYear();
  return !(
    vintage.length !== 4 ||
    !vintage.match(/\d{4}/) ||
    parseInt(vintage) > currentYear ||
    parseInt(vintage) < 1900
  );
};
