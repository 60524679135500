import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { TextField, Grid } from "@material-ui/core";
import useStyles from "../util/styles";

const AddReviewFields: React.FC<{
  form: any;
  setForm: (obj: any) => void;
  setFormErrors: (obj: any) => void;
  errors: any;
}> = ({ form, setForm, errors, setFormErrors }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <KeyboardDatePicker
          format="MM/dd/yyyy"
          fullWidth
          margin="none"
          id="tasting-date-picker-inline"
          label="Tasting Date"
          value={form.tasting_date || null}
          helperText={errors.tasting_date && "Required"}
          error={errors.tasting_date}
          onChange={(e) => {
            errors.tasting_date &&
              setFormErrors({ ...errors, tasting_date: false });
            setForm({ ...form, tasting_date: e as Date });
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Score"
          value={form.score}
          helperText={errors.score && "Required"}
          error={errors.score}
          onChange={(e) => {
            errors.score && setFormErrors({ ...errors, score: false });
            setForm({
              ...form,
              score: (e.target.value as unknown) as number,
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          classes={{ root: classes.textRoot }}
          fullWidth
          label="Tasting note"
          value={form.tasting_note}
          helperText={errors.tasting_note && "Required"}
          error={errors.tasting_note}
          onChange={(e) => {
            errors.tasting_note &&
              setFormErrors({ ...errors, tasting_note: false });
            setForm({
              ...form,
              tasting_note: (e.target.value as unknown) as number,
            });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AddReviewFields;
