import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import useStyles from "../util/styles";
import { bottleFormats, packedAsOptions } from "../../config";
import LwinSearchField from "../LwinSearchField";
import { currencies } from "../../constants";

const AddFields: React.FC<{
  form: any;
  setForm: (obj: any) => void;
  setFormErrors: (obj: any) => void;
  errors: any;
}> = ({ form, setForm, errors, setFormErrors }) => {
  const classes = useStyles();
  const [vintageOptions, setVintageOptions] = React.useState([]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <LwinSearchField
          error={errors.LWIN}
          setLwin={(lwin: string) => {
            setForm({ ...form, lwin });
          }}
          removeError={() => setFormErrors({ ...errors, LWIN: false })}
          setVintageOptions={setVintageOptions}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Autocomplete
          id="cw-form-vintage"
          freeSolo
          autoHighlight={true}
          autoSelect={true}
          disableClearable={true}
          options={vintageOptions}
          onChange={(_e: any, newValue: unknown) => {
            errors.vintage && setFormErrors({ ...errors, vintage: false });
            setForm({
              ...form,
              vintage: (newValue as unknown) as number,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id="input-vintage"
              label="Vintage"
              fullWidth
              style={{ width: 140 }}
              helperText={errors.vintage && "Required"}
              error={errors.vintage}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <InputLabel style={{ fontSize: "12px" }} htmlFor="size">
          Size
        </InputLabel>
        <Select
          classes={{ root: classes.selectRoot }}
          labelId="size"
          value={form.bottleSize}
          fullWidth
          onChange={(e) => {
            setForm({
              ...form,
              bottleSize: (e.target.value as unknown) as string,
            });
          }}
          inputProps={{
            name: "Size",
            id: "size",
          }}
        >
          {bottleFormats.map((f) => (
            <MenuItem key={f} value={f}>{`${f}cl`}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={3}>
        <InputLabel style={{ fontSize: "12px" }} htmlFor="packed-as">
          Packed As
        </InputLabel>
        <Select
          fullWidth
          labelId="Packed as"
          classes={{ root: classes.selectRoot }}
          value={form.bottlePackedAs || ""}
          onChange={(e) => {
            setForm({
              ...form,
              bottlePackedAs: (e.target.value as unknown) as number,
            });
          }}
          inputProps={{
            name: "Packed As",
            id: "packed-as",
          }}
        >
          {packedAsOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option !== "Packed As"
                ? `${option}x${parseFloat(form.bottleSize)}cl`
                : option}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          id="input-bottles"
          helperText={errors.bottles && "Required"}
          error={errors.bottles}
          label="Bottles"
          onChange={(e) => {
            errors.bottles && setFormErrors({ ...errors, bottles: false });
            setForm({
              ...form,
              bottleCount: (e.target.value as unknown) as number,
            });
          }}
          defaultValue={form.bottleCount}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          id="input-total-cost"
          helperText={errors.totalCost && "Invalid cost"}
          error={errors.totalCost}
          classes={{ root: classes.textRoot }}
          label="Total Cost"
          onChange={(e) => {
            const numbers = /^[0-9\b]+$/;
            errors.totalCost && setFormErrors({ ...errors, totalCost: false });
            if (e.target.value === "" || numbers.test(e.target.value)) {
              setForm({
                ...form,
                totalCost: (e.target.value as unknown) as number,
              });
            } else {
              setFormErrors({ ...errors, totalCost: true });
            }
          }}
          defaultValue={form.totalCost}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Select
          style={{
            marginTop: 32,
          }}
          labelId="currency"
          value={form.currency}
          fullWidth
          onChange={(e) => {
            setForm({
              ...form,
              currency: (e.target.value as unknown) as string,
            });
          }}
        >
          {currencies.map((currency) => (
            <MenuItem
              key={currency.value}
              value={currency.value}
            >{`${currency.value}`}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        <KeyboardDatePicker
          format="MM/dd/yyyy"
          fullWidth
          margin="normal"
          id="purchase-date-picker-inline"
          label="Purchase Date"
          value={form.purchaseDate || new Date()}
          onChange={(e) => setForm({ ...form, purchaseDate: e as Date })}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Stored at"
          onChange={(e) =>
            setForm({
              ...form,
              storedAt: (e.target.value as unknown) as number,
            })
          }
          defaultValue={form.storedAt}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Condition"
          onChange={(e) =>
            setForm({
              ...form,
              conditionNotes: (e.target.value as unknown) as number,
            })
          }
          defaultValue={form.conditionNotes}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Supplier"
          onChange={(e) =>
            setForm({
              ...form,
              supplier: (e.target.value as unknown) as number,
            })
          }
          defaultValue={form.supplier}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <KeyboardDatePicker
          id="drink-from-picker-inline"
          label="Drink From"
          value={form.drinkFrom}
          onChange={(e) => setForm({ ...form, drinkFrom: e as Date })}
          views={["year"]}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <KeyboardDatePicker
          id="drink-to-picker-inline"
          label="Drink To"
          value={form.drinkTo}
          onChange={(e) => setForm({ ...form, drinkTo: e as Date })}
          views={["year"]}
        />
      </Grid>
    </Grid>
  );
};

export default AddFields;
