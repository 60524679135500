import React from "react";
import {
  Button,
  Typography,
  Dialog,
  FormGroup,
  FormControlLabel,
  Checkbox,
  AppBar,
  Toolbar,
  IconButton,
  Card,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import _ from "lodash";
import { useToggle } from "react-use";
import { makeStyles } from "@material-ui/core/styles";
import { PriceHistoryRecords } from "./index";
import { getHistoricDataForLwins } from "../../lib/livexRequests";

export type HistoricData = {
  lwin11: string;
  value: string;
  date_string: string;
  display?: string;
};

type Props = {
  winesToCompare: PriceHistoryRecords[];
  setHistoricData: Function;
  historicData?: HistoricData[];
  toggleChartLoading: Function;
};

const useStyles = makeStyles(() => ({
  modalButton: {
    margin: "5px 0px",
  },
  appBar: {
    position: "sticky",
    top: 0
  },
}));

const VintageSelect: React.FC<Props> = ({
  winesToCompare,
  setHistoricData,
  historicData,
  toggleChartLoading,
}: Props) => {
  const classes = useStyles();
  const [open, toggleOpen] = useToggle(false);
  const [selectedLwins, setSelectedLwins] = React.useState<string[]>([
    ...winesToCompare.map(wine => wine.lwin)
  ]);

  const data = historicData || [];

  const handleClick = (lwin: string) => {
    const index = selectedLwins.indexOf(lwin);
    const updatedLwinList =
      index >= 0
        ? selectedLwins.filter((_, i) => i !== index)
        : [...selectedLwins, lwin];
    setSelectedLwins(updatedLwinList);
  };

  const handleSubmit = async () => {
    await toggleChartLoading();
    toggleOpen(false);
    const currentLwins = data
      .map(({ lwin11 }: HistoricData) => lwin11)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    const missingLwins = _.difference(selectedLwins, currentLwins);
    const deletedLwins = _.difference(currentLwins, selectedLwins);
    if (deletedLwins.length) {
      const filteredData = data.filter(
        ({ lwin11 }: HistoricData) => !deletedLwins.includes(lwin11)
      );
      await setHistoricData(filteredData);
    }

    if (missingLwins.length) {
      const {
        data: { data },
      } = await getHistoricDataForLwins(selectedLwins.filter(lwin => lwin.length > 7));
      const dataWithDisplay: HistoricData[] = data.map((item: any) => {
        const vintage = item.lwin11.substring(7);
        const wineForLwin11 = winesToCompare.find(
          ({ lwin }: PriceHistoryRecords) => lwin === item.lwin11
        );
        const name = wineForLwin11 && wineForLwin11.name;
        return { ...item, display: `${name}, ${vintage}` };
      });
      await setHistoricData([...data, ...dataWithDisplay]);
    }
    await toggleChartLoading();
  };

  const updateLwins = async (lwins: string[]) => {
    selectedLwins.push(...lwins);
    await setSelectedLwins([
      ...selectedLwins
    ])

    handleSubmit()
  }

  React.useEffect(() => {
    if(!open) {
      const winesWithVintage = winesToCompare.filter(wine => !selectedLwins.includes(wine.lwin) && wine.lwin !== undefined);
      const lwins = winesWithVintage.map(wine => wine.lwin);

      updateLwins(lwins);
    }
  }, [winesToCompare]);

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        size="small"
        className="modalButton"
        onClick={() => toggleOpen(true)}
      >
        Select Vintages
      </Button>
      <Dialog aria-labelledby="title" open={open} fullWidth maxWidth="md">
        <AppBar className={classes.appBar}>
          <Toolbar style={{ display: "flex" }}>
            <Typography variant="h6">Select Vintages to Compare</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleSubmit}
              aria-label="save"
              style={{ marginLeft: "auto" }}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                toggleOpen()
              }}
              aria-label="close"
              style={{ marginLeft: "10px" }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {winesToCompare.map(({ lwin: lwin11, name, data }) => {
            const hasData = data.some(({ price }: any) => price !== "-");
            return (
              <Card style={{ width: "50%", padding: "15px" }} key={name}>
                <Typography variant="h6">{name}</Typography>
                {hasData ? (
                  <FormGroup style={{ height: "400px" }}>
                    {data
                      .filter(({ price }) => price !== "-")
                      .map(({ vintage, lwin }) => (
                        <FormControlLabel
                          key={`${name},${lwin}`}
                          label={vintage}
                          control={
                            <Checkbox
                              checked={selectedLwins.includes(lwin) || lwin === lwin11}
                              onChange={() => handleClick(lwin)}
                            />
                          }
                        />
                      ))}
                  </FormGroup>
                ) : (
                  <div>No vintage data for this wine</div>
                )}
              </Card>
            );
          })}
        </div>
        <Button
          color="primary"
          style={{ height: "40px", margin: "10px" }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Dialog>
    </>
  );
};

export default VintageSelect;
