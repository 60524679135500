import { get, post } from "./api";

export const createCheckoutSessionRequest = (
  tier: string,
  coupon: string | null,
  currency: string
) => {
  return post("/subscriptions/checkout-session", { tier, coupon, currency });
};

export const subscribeToFreePlanRequest = ( currency: string ) => {
  return post("/subscriptions/subscribe-to-free-plan", { currency });
};

export const getCheckoutSessionRequest = () => {
  return get("/subscriptions/checkout-session");
};

export const upgradeSubscriptionRequest = ( tier: string, currency: string ) => {
  return post("/subscriptions/upgrade", { tier, currency });
};

export const importSubscriptionRequest = ( subscriberId: string ) => {
  return post("/subscriptions/import", { subscriberId });
};

export const getActiveSubscriptionRequest = () => {
  return get("/subscriptions/active");
};

export const cancelOldSubscriptionRequest = () => {
  return get("/subscriptions/cancelOldSubscription");
};

export const cancelSubscriptionRequest = () => {
  return get("/subscriptions/cancel");
};

export const getOldPlanRequest = ( subscriberId: string ) => {
  return post("/subscriptions/oldPlan", { subscriberId });
};

export const validateAndProcessCoupon = ( tier: string | null, coupon: string | null, currency: string ) => {
  return post("/subscriptions/process-coupon", { tier, coupon, currency });
};

export const validateCouponCode = ( code: string | null ) => {
  return get("/subscriptions/validate-coupon", { code });
}

export const getPlans = () => {
  return get("/subscriptions/plans");
}