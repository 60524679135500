import React from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { numberFormattedWithCurrency } from "../util/format";
import useStyles from "../util/styles";
import Title from "../title";
import { Currency } from "../../context/authContext/reducer";
import moment from "moment-timezone";

type props = {
  disposedType: string;
  disposedWines: any[];
  currency?: Currency;
};

const headerColumns = [
  "Region",
  "Color",
  "Wine",
  "Quantity",
  "Date",
  "Value at disposal",
];

const DWTableCell: React.FC<{ style?: object }> = ({ children, style }) => {
  const classes = useStyles();
  return (
    <TableCell style={style} className={classes.cwTableCell} align="left">
      {children}
    </TableCell>
  );
};

const DisposedWineTableRow: React.FC<{
  disposedWine: any;
  currencySymbol: string;
}> = ({ disposedWine, currencySymbol }) => {
  const classes = useStyles();

  return disposedWine ? (
    <>
      <TableRow>
        <TableCell className={classes.cwTableCell}> </TableCell>
        <DWTableCell>{`${disposedWine.region}`}</DWTableCell>
        <DWTableCell>{`${disposedWine.color}`}</DWTableCell>
        <DWTableCell>{`${disposedWine.vintage} ${disposedWine.displayName}`}</DWTableCell>
        <DWTableCell>{disposedWine.disposed_qty}</DWTableCell>
        <DWTableCell>
          {disposedWine.disposal_date
            ? `${moment(Date.parse(disposedWine.disposal_date))
              .tz("Europe/London")
              .format("D MMMM yyyy")}`
            : "-"}
        </DWTableCell>
        <DWTableCell>
          {numberFormattedWithCurrency(
            currencySymbol,
            parseFloat(disposedWine.value_at_disposal)
          )}
        </DWTableCell>
      </TableRow>
    </>
  ) : null;
};

const DisposedWinesTable: React.FC<props> = ({
  disposedType,
  disposedWines,
  currency,
}) => {
  const classes = useStyles();

  const CWHeadTableCell: React.FC<{ text: string }> = ({ text }) => {
    const classes = useStyles();

    return (
      <TableCell align="left" className={classes.cwHeadTableCell}>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <Typography className={classes.cwHeadTableCellText}>
            {text}
          </Typography>
        </div>
      </TableCell>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" style={{padding: 8}}>
        <Title>
          {disposedType === "consumed" ? "Consumed Wines" : "Sold Wines"}
        </Title>
      </Box>
      {disposedWines.length > 0 && currency ? (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="Cellar Wines Table">
            <TableHead style={{ borderColor: "red" }}>
              <TableRow>
                <TableCell className={classes.cwHeadTableCell} />
                {headerColumns.map((c) => (
                  <CWHeadTableCell key={c} text={c} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {disposedWines
                .sort((a, b) => a.region.localeCompare(b.region))
                .map((disposedWine) => {
                  return (
                    <DisposedWineTableRow
                      disposedWine={disposedWine}
                      currencySymbol={currency.symbol}
                      key={disposedWine && disposedWine.id}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className={classes.centerContainer}>
          <CircularProgress style={{ margin: 16 }} />
        </div>
      )}
    </>
  );
};

export default DisposedWinesTable;
