import { get, post, put } from "./api";
import { Cellar, CellarParams } from "../types/Cellar";

export const getCellarsForUser = () => {
  return get("/cellars/");
};

export const getCellarForSlug = (slug: string) => {
  return get(`/cellars/${slug}`);
};

export const getCellarWinesForCellar = (slug: string, limit: number, offset: number, sortColumn: string, sortDirection: boolean) => {
  return get(`/cellars/${slug}/cellarWines?limit=${limit}&offset=${offset}&sortColumn=${sortColumn.toLowerCase()}&sortDirection=${sortDirection}`);
};

export const generateCellarCSV = (slug: string) => {
  return get(`/cellars/${slug}/csv`);
}

export const listCellarsForUser = () => {
  return get("/cellars/list");
};

export const getHistoricDataForCellar = (cellarId: string) => {
  return get(`/cellars/historicData/${cellarId}`);
};

export const postCreateCellar = (name: string) => {
  return post("/cellars", { name });
};

export const createCellarWine = (params: object) => {
  return post("/cellars/addWine", params);
};

export const deleteCellarWine = (wineId: string) => {
  return post("/cellars/deleteWine", { wineId });
};

export const sellCellarWine = (
  cellarId: string,
  wineId: string,
  sellDate: string,
  value: string,
  bottles: number
) => {
  return post("/cellars/sellWine", {
    cellarId,
    wineId,
    sellDate,
    value,
    bottles,
  });
};

export const drinkCellarWine = (
  cellarId: string,
  wineId: string,
  drinkDate: string,
  bottles: number,
  score: string,
  drinkingWindow: string,
  tastingNote: string,
  value: string
) => {
  return post("/cellars/drinkWine", {
    cellarId,
    wineId,
    drinkDate,
    bottles,
    score,
    drinkingWindow,
    tastingNote,
    value,
  });
};

export const getDisposedWinesForCellarRequest = (cellarId: string) => {
  return get(`/cellars/disposedWines/${cellarId}`);
};

export const transferCellarWine = (
  wineId: string,
  transferCellarId: string
) => {
  return post("/cellars/transferWine", { wineId, transferCellarId });
};

export const editCellarWine = (params: object) => {
  return post("/cellars/editWine", params);
};

export const getCellarWineReviews = (wineId?: string) => {
  return get(`/cellars/reviews/${wineId}`);
};

export const addCellarWineReviewRequest = (wineReview: object) => {
  return post("/cellars/reviews/add", { wineReview });
};

export const deleteCellarWineReviewRequest = (reviewId: string) => {
  return post(`/cellars/reviews/delete`, { reviewId });
};

export const editCellar = (params: CellarParams) => {
  const { slug } = params;
  return post(`/cellars/${slug}/editCellar`, params);
};

export const deleteCellar = (params: { id: string; slug: string }) => {
  return post(`/cellars/deleteCellar`, { id: params.id });
};

export const duplicateCellar = (cellar: Cellar) => {
  return post(`/cellars/duplicateCellar`, { ...cellar });
};

export const putImageRequest = (url: string, data: any) => {
  return put(url, data, { "Content-Type": "image/*" });
};

export const putCsvRequest = (url: string, data: any) => {
  return put(url, data, { "Content-Type": "text/csv" });
};

export const getSignedUrlRequest = (fileName: string, fileType: string, permissions: string | undefined = undefined) => {
  return post("/cellars/signedUrl", { fileName, fileType, permissions });
};

export const uploadCellarCsvRequest = (filename: string, userId: string, cellarId: string | undefined = undefined) => {
  return post("/cellars/uploadCellarCsv", { filename, userId, cellarId });
};
