import React from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { ThemeProvider } from "@material-ui/core/styles";
import Copyright from "../../components/copyright";
import theme from "../../components/util/theme";
import Header from "../../components/header";
import useStyles from "../../components/util/styles";
import { useAuth } from "../../context/authContext";
import { useRouteMatch } from "react-router-dom";
import CustomSnackbar from "../../components/snackbar";
import {
  emailError,
  startOnboarding,
  validEmail,
  requestPasswordReset,
} from "../../context/authContext/actions";

export type LoginFormState = {
  email: string;
  emailError: string;
};

export const Onboarding = () => {
  const classes = useStyles();
  const { dispatch } = useAuth();
  const forgotPassword = !!useRouteMatch("/forgotPassword");

  const [onboardingForm, setOnboardingForm] = React.useState<LoginFormState>({
    email: "",
    emailError: "",
  });

  const handleOnboarding = async (e: any, dispatch: any) => {
    e.preventDefault();

    if (!validEmail(onboardingForm.email)) {
      setOnboardingForm({
        ...onboardingForm,
        emailError: emailError(onboardingForm.email),
      });
    } else {
      setOnboardingForm({ email: "", emailError: "" });
      if (forgotPassword) {
        await requestPasswordReset(dispatch, onboardingForm.email);
      } else {
        await startOnboarding(dispatch, onboardingForm.email, forgotPassword);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.rootLogin}>
        <Header />
        <CustomSnackbar />
        <Container maxWidth="md">
          <Grid
            item
            component={Paper}
            className={classes.paperLogin}
            elevation={6}
            square
          >
            <div className={classes.paperLogin}>
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.avatarContainer}>
                    <Avatar className={classes.avatar}>
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" align="center">
                      Please enter your email address
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      error={onboardingForm.emailError.length > 0}
                      helperText={onboardingForm.emailError}
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      type="email"
                      value={onboardingForm.email}
                      onChange={(e) =>
                        setOnboardingForm({
                          ...onboardingForm,
                          email: e.target.value,
                          emailError: emailError(e.target.value),
                        })
                      }
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={async (e) => handleOnboarding(e, dispatch)}
                    >
                      Continue
                    </Button>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </div>
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
};
