import React from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "../util/styles";
import { Cellar } from "../../types/Cellar";
import Grid from "@material-ui/core/Grid";
import { Card } from "@material-ui/core";
import { numberWithCommas } from "../util/format";

type CellarCardProps = {
  cellar: Cellar;
  showingDetails: boolean;
  currencySymbol?: string;
};

const CellarCardCondensed: React.FC<CellarCardProps> = ({
  cellar,
  showingDetails,
  currencySymbol,
}) => {
  const classes = useStyles();
  const [cardState, setCardState] = React.useState<{
    hover: boolean;
  }>({
    hover: false,
  });

  return (
    <div
      onMouseEnter={() => setCardState({ ...cardState, hover: true })}
      onMouseLeave={() => setCardState({ ...cardState, hover: false })}
    >
      <Card
        elevation={!showingDetails && cardState.hover ? 8 : 3}
        className={`${classes.wineCard} ${classes.boxShadowNone} ${classes.borderBottom}`}
        style={{maxWidth: 800, marginTop: 0, marginBottom: 16}}
      >
        <div style={{display: "flex", flexDirection: "row"}}>
        {/* Cellar name */}
        <Grid container item direction="column" 
          alignItems="baseline"
        >
          <div style={{display: "flex", flexDirection: "row", alignItems: "baseline"}}>
            <Typography
              component="h2"
              variant="h6"
              color={showingDetails ? "textPrimary" : "primary"}
              style={{marginRight: 6, fontWeight: 800, lineHeight: 1}}
            >
              {cellar.name}
            </Typography>
            <Typography
              variant="h6"
              className={classes.sans}
              style={{lineHeight: 1, fontSize: "20px"}}
            >
              {currencySymbol}
              {cellar.valueAtCurrent
                ? numberWithCommas(cellar.valueAtCurrent)
                : "0"}{" "}
            </Typography>
          </div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <Typography
              align="left"
              color="textSecondary"
              component="p"
              className={classes.depositContext}
              style={{marginRight: 6}}
            >
              {cellar.numBottles !== undefined
                ? numberWithCommas(cellar.numBottles)
                : "loading"}{" "}
              {cellar.numBottles && cellar.numBottles === 1
                ? "bottle"
                : "bottles"}
            </Typography>
            <Typography
              align="left"
              color="textSecondary"
              component="p"
              className={classes.depositContext}
            >
              {cellar.numWines !== undefined
                ? numberWithCommas(cellar.numWines)
                : "loading"}{" "}
              {cellar.numWines && cellar.numWines === 1 ? "wine" : "wines"}
            </Typography>         
          </div>
        </Grid>
        {/* Contents and Value */}
        <Grid container item spacing={3} direction="row"
          style={{paddingBottom: "16px"}}
          >
          {/* Contents */}
          
          {/* Value */}
          <Grid direction="row" item container xs={12} spacing={5} justify="flex-start">
          <Grid item container direction="column"  xs={12} sm={3} className={classes.flexBasisZero}>
              <Typography
                align="left"
                component="p"
                className={classes.depositContext}
              >
                Now
              </Typography>
              {/* <Typography
                align="left"
                color="textSecondary"
                component="p"
                className={classes.depositContext}
              >
                {currencySymbol}
                {cellar.valueAtCurrent
                  ? numberWithCommas(cellar.valueAtCurrent)
                  : "0"}{" "}
              </Typography> */}
              <Typography
                align="left"
                color="textSecondary"
                component="p"
                className={classes.depositContext}
              >
                {currencySymbol}
                {cellar.valueAtCurrent
                  ? numberWithCommas(cellar.valueAtCurrent)
                  : "0"}{" "}
              </Typography>
            </Grid>            
            <Grid item container direction="column"  xs={12} sm={3} className={classes.flexBasisZero}>
              <Typography
                align="left"
                component="p"
                className={classes.depositContext}
              >
                30d
              </Typography>
              {/* <Typography
                align="left"
                color="textSecondary"
                component="p"
                className={classes.depositContext}
              >
                {currencySymbol}
                {cellar.value30dAgo
                  ? numberWithCommas(cellar.value30dAgo)
                  : "0"}{" "}
              </Typography> */}
              <Typography
                align="left"
                color="textSecondary"
                component="p"
                className={classes.depositContext}
                style={{
                  color:
                    cellar.valueChange30d && cellar.valueChange30d < 0
                      ? "red"
                      : cellar.valueChange30d && cellar.valueChange30d > 0
                      ? "green"
                      : "inherit",
                }}
              >
                {cellar.valueChange30d && cellar.valueChange30d > 0 ? "+" : ""}
                {cellar.valueChange30d ? cellar.valueChange30d.toFixed(1) : 0}%
              </Typography> 
            </Grid>
            <Grid item xs={12} sm={3} className={classes.flexBasisZero}>
              <Typography
                align="left"
                component="p"
                className={classes.depositContext}
              >
                12m
              </Typography>
              {/* <Typography
                align="left"
                color="textSecondary"
                component="p"
                className={classes.depositContext}
              >
                {currencySymbol}
                {cellar.value12mAgo
                  ? numberWithCommas(cellar.value12mAgo)
                  : "0"}{" "}
              </Typography> */}
              <Typography
                align="left"
                color="textSecondary"
                component="p"
                className={classes.depositContext}
                style={{
                  color:
                    cellar.valueChange12m && cellar.valueChange12m < 0
                      ? "red"
                      : cellar.valueChange12m && cellar.valueChange12m > 0
                      ? "green"
                      : "inherit",
                }}
              >
                {cellar.valueChange12m && cellar.valueChange12m > 0 ? "+" : ""}
                {cellar.valueChange12m ? cellar.valueChange12m.toFixed(1) : 0}%
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.flexBasisZero}>
              <Typography
                align="left"
                component="p"
                className={classes.depositContext}
              >
                Cost
              </Typography>
              {/* <Typography
                align="left"
                color="textSecondary"
                component="p"
                className={classes.depositContext}
              >
                {currencySymbol}
                {cellar.valueAtCost
                  ? numberWithCommas(cellar.valueAtCost)
                  : "0"}{" "}
              </Typography> */}
              <Typography
                align="left"
                color="textSecondary"
                component="p"
                className={classes.depositContext}
                style={{
                  color:
                    cellar.valueChangeVsCost && cellar.valueChangeVsCost < 0
                      ? "red"
                      : cellar.valueChangeVsCost && cellar.valueChangeVsCost > 0
                      ? "green"
                      : "inherit",
                }}
              >
                {cellar.valueChangeVsCost && cellar.valueChangeVsCost > 0
                  ? "+"
                  : ""}
                {cellar.valueChangeVsCost
                  ? cellar.valueChangeVsCost.toFixed(1)
                  : 0}
                %
              </Typography>            
            </Grid>
          </Grid>

          {/* Picture
          <Grid item xs={12} sm={3} className={classes.depositContext}>
            <img
              src={cellar.image_url ? cellar.image_url : cellarImg}
              alt="cellarImage"
              style={{
                margin: 8,
                maxHeight: 120,
                maxWidth: 120,
                minHeight: 120,
              }}
            />
          </Grid> */}
        </Grid>
        </div>
      </Card>
    </div>
  );
};

export default CellarCardCondensed;