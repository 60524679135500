import React from "react";
import { Button } from "@material-ui/core";
import CellarModalForm from "../CellarModalForm";
import { addCellarWineReviewRequest } from "../../lib/cellarRequests";

const initialValues = {
  tasting_date: "",
  tasting_note: "",
  score: "",
};

const AddReview = ({
  cellarWineId: cellar_wine_id,
  setWineReviews,
  wineReviews,
}: any) => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});

  const submitReviewForm = async (e: any, form: any) => {
    e.preventDefault();
    type Errors = {
      [key: string]: boolean;
    };
    let errors: Errors = {
      score: false,
      tasting_date: false,
      tasting_note: false,
    };
    if (!form.tasting_note) {
      errors["tasting_note"] = true;
    }
    if (!form.tasting_date) {
      errors["tasting_date"] = true;
    }
    if (!form.score) {
      errors["score"] = true;
    }

    if (Object.keys(errors).some((key: string) => errors[key])) {
      setFormErrors(errors);
    } else {
      const {
        data: { wineReview },
      } = await addCellarWineReviewRequest({
        cellar_wine_id,
        ...form,
      });
      setWineReviews([...wineReviews, wineReview]);
      setModalOpen(false);
    }
  };
  return (
    <>
      <CellarModalForm
        type="Add Tasting Note"
        title="Add Tasting Note"
        open={isModalOpen}
        buttonText="Save"
        setOpen={setModalOpen}
        setFormErrors={setFormErrors}
        initialValues={initialValues}
        onSubmit={submitReviewForm}
        errors={formErrors}
      />
      <Button color="primary" onClick={() => setModalOpen(true)}>
        Add Tasting Note
      </Button>
    </>
  );
};

export default AddReview;
