import { get, post } from "./api";

export const authenticateToken = (token: string) => {
  return get("/auth/authorize");
};

export const loginUser = (email: string, password: string) => {
  return post("/auth/login", { email, password });
};

export const createUserPost = (params: any) => {
  const { firstName, lastName, email, password, verifyEmailToken, customerId, currency } = params;
  return post("/auth/signup", {
    firstName,
    lastName,
    email,
    password,
    verifyEmailToken,
    customerId,
    currency
  });
};

export const onboardingRequest = (params: any) => {
  const { email, forgotPassword } = params;
  return post("/auth/onboarding", {
    email,
    forgotPassword,
  });
};

export const requestPasswordReset = (email: string) => {
  return post("/auth/requestPasswordReset", { email });
}

export const resetPasswordRequest = (
  email: string,
  verifyEmailToken: string,
  password: string,
  confirmPassword: string
) => {
  return post("/auth/resetPassword", {
    email,
    password,
    verifyEmailToken,
    confirmPassword,
  });
};
