import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import {
  createCheckoutSessionRequest,
  upgradeSubscriptionRequest,
} from "../../lib/subscriptionRequests";
import { useAuth } from "../../context/authContext";
import { getActiveSubscription } from "../../context/authContext/actions";

type UpgradeDialogProps = {
  open: boolean;
  setOpen: (toggle: boolean) => void;
};

const UpgradeDialog: React.FC<UpgradeDialogProps> = ({ open, setOpen }) => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    state: { loading, isPremiumUser, paymentCurrency },
    dispatch: authDispatch,
  } = useAuth();

  React.useEffect(() => {
    if (!loading && isPremiumUser === undefined) {
      getActiveSubscription(authDispatch);
    }
  }, [loading, isPremiumUser, authDispatch]);

  const handleUpgrade = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js or User has not yet loaded.
      return;
    }

    if (!!isPremiumUser) {
      // Upgrading to the Pro tier
      await upgradeSubscriptionRequest("Pro", paymentCurrency);
    } else {
      const response = await createCheckoutSessionRequest(
        "Premium",
        null,
        paymentCurrency
      );
      stripe
        .redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: response.data.session_id,
        })
        .then(function (result) {
          console.log("redirectToCheckout failed with: ", result.error.message);
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Free plan limit reached."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please upgrade to add more cellars and items.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Later
          </Button>
          <Button onClick={(e) => handleUpgrade(e)} color="primary" autoFocus>
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpgradeDialog;
