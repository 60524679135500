import React from "react";
import List from "@material-ui/core/List";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

import { useHistory } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "../../components/util/styles";
import { LayoutWrapper } from "../../components/layoutWrapper";
import CellarForm from "../../components/CellarForm";
import { useCellarsContext, loadCellars } from "../../context/cellarsContext";
import { useCellarWinesContext } from "../../context/cellarWinesContext";
import { useAuth } from "../../context/authContext";
import UpgradeDialog from "../../components/upgradeDialog";
import StripeElementsWrapper from "../../components/stripeElementsWrapper";
import CustomSnackbar from "../../components/snackbar";
import {
  getActiveSubscription,
  getCurrentUser,
  getOldPlan,
} from "../../context/authContext/actions";
import CellarCardCondensed from "../../components/cellarCardCondensed";
import { currencies } from "../../constants";
import { currencyChanged } from "../../context/cellarsContext/actions";
import { clearCellarWines } from "../../context/cellarWinesContext/actions";
import { useLocation } from "react-router-dom";
import { cancelOldSubscriptionRequest } from "../../lib/subscriptionRequests";

const Cellars: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [formOpen, setFormOpen] = React.useState(false);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = React.useState(false);
  const {
    state: { isProUser, user, oldPlan },
    dispatch: authDispatch,
  } = useAuth();
  const {
    state: { cellars, loading, currencyChange },
    dispatch,
  } = useCellarsContext();
  const {
    dispatch: wineDispatch
  } = useCellarWinesContext();

  React.useEffect(() => {
    if (location.search === "?upgradeSuccess") cancelOldSubscriptionRequest();
  }, [location]);

  React.useEffect(() => {
    if (!user) getCurrentUser(authDispatch);
    if ((!cellars && !loading) || currencyChange) {
      currencyChanged(dispatch, false);
      loadCellars(dispatch);
    }
    if (isProUser === undefined) getActiveSubscription(authDispatch);
    if (!oldPlan && user && user.old_sub_id)
      getOldPlan(authDispatch, user.old_sub_id);
  }, [
    cellars,
    loading,
    isProUser,
    dispatch,
    authDispatch,
    user,
    currencyChange,
    oldPlan,
  ]);

  const currencyObj = user && currencies.find((c) => c.value === user.currency);

  return (
    <LayoutWrapper pathName="cellars" loading={loading}>
      <CellarForm open={formOpen} setOpen={setFormOpen} />
      <StripeElementsWrapper>
        <UpgradeDialog
          open={upgradeDialogOpen}
          setOpen={setUpgradeDialogOpen}
        />
      </StripeElementsWrapper>
      <Container maxWidth="lg" className={classes.container}>
        <CustomSnackbar />
        <List>
          <Button
            variant="outlined"
            color="primary"
            className={classes.marginBottom24}
            onClick={() => {
              if (
                isProUser ||
                (oldPlan &&
                  oldPlan.plan === "Pro" &&
                  oldPlan.renewsAt &&
                  Date.parse(oldPlan.renewsAt) > Date.now()) ||
                (cellars && Object.entries(cellars).length < 1)
              ) {
                // Allow 1 cellar for free users
                setFormOpen(true);
              } else {
                // Show upgrade dialog otherwise
                setUpgradeDialogOpen(true);
              }
            }}
          >
            <ListItemIcon>
              <AddIcon/>
            </ListItemIcon>
            <ListItemText primary="Add Cellar" />
          </Button>
          {user &&
            cellars &&
            Object.entries(cellars)
              .sort((a: any, b: any) =>
                a[1] && b[1] ? a[1].name.localeCompare(b[1].name) : 0
              )
              .map((val) => {
                const [id, cellar] = val;
                if (cellar) {
                  return (
                    <div
                      key={id}
                      onClick={(e) => {
                        clearCellarWines(wineDispatch);
                        history.push(`/cellars/${cellar.slug}`);
                      }}
                      role="button"
                      style={{ cursor: "pointer" }}
                    >
                      <CellarCardCondensed
                        cellar={cellar}
                        currencySymbol={currencyObj && currencyObj.symbol}
                        showingDetails={false}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
        </List>
      </Container>
    </LayoutWrapper>
  );
};

export default Cellars;
