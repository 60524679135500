import { Dispatch } from '../../types/Action';
import { Cellar, CellarParams } from "../../types/Cellar";

import {
  getCellarsForUser,
  listCellarsForUser,
  postCreateCellar,
  deleteCellar as deleteCellarRequest,
  duplicateCellar as duplicateCellarRequest,
  editCellar as editCellarRequest,
  getHistoricDataForCellar,
} from "../../lib/cellarRequests";
import {
  getLivexIndicesPerformanceRequest,
  getPriceHistoryForIndices,
} from "../../lib/livexRequests";

export const loadCellars = async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: "loadingCellars",
    });

    const response = await getCellarsForUser();

    dispatch({
      type: "setCellars",
      payload: response,
    });
    setTimeout(() => {
      dispatch({
        type: "setAggregatedTotals",
        payload: response,
      });
    }, 50);
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const listCellars = async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: "loadingCellars",
    });

    const response = await listCellarsForUser();

    dispatch({
      type: "setCellars",
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const loadHistoricDataForCellar = async (
  dispatch: Dispatch,
  cellarId: string
) => {
  try {
    const response = await getHistoricDataForCellar(cellarId);
    dispatch({
      type: "setHistoricDataForCellar",
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const loadLivexIndicesPerformance = async (dispatch: Dispatch) => {
  try {
    const response = await getLivexIndicesPerformanceRequest();
    dispatch({
      type: "setLivexIndices",
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const loadPriceHistoryForIndices = async (
  dispatch: Dispatch,
  symbols: string[]
) => {
  try {
    if (symbols && symbols.length > 0) {
      const response = await getPriceHistoryForIndices(symbols.join(","));
      dispatch({
        type: "setPriceHistoryForIndices",
        payload: response,
      });
    } else {
      dispatch({
        type: "setPriceHistoryForIndices",
        payload: { priceData: undefined },
      });
    }
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const createCellar = async (
  dispatch: Dispatch,
  name: string,
  history: any
) => {
  try {
    const response = await postCreateCellar(name);

    dispatch({
      type: "addCellar",
      payload: response.data,
    });
    history.push(`/cellars/${response.data.cellar.slug}`);
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const editCellar = async (dispatch: Dispatch, params: CellarParams) => {
  try {
    await editCellarRequest(params);
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const deleteCellar = async (
  dispatch: Dispatch,
  params: { id: string; slug: string }
) => {
  try {
    const response = await deleteCellarRequest(params);

    const { deleted_cellar } = response.data;
    const cellar = deleted_cellar[0];

    dispatch({
      type: "deleteCellar",
      payload: { cellar },
    });
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const duplicateCellar = async (dispatch: Dispatch, c: Cellar) => {
  try {
    const response = await duplicateCellarRequest(c);

    const { cellar } = response.data;

    dispatch({
      type: "addCellar",
      payload: { cellar },
    });
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const setError = async (dispatch: Dispatch, error: string) => {
  dispatch({
    type: "setError",
    payload: error,
  });
};

export const clearMessage = async (dispatch: Dispatch) => {
  dispatch({
    type: "clearMessage",
  });
};

export const currencyChanged = async (dispatch: Dispatch, change: boolean) => {
  dispatch({
    type: "currencyChange",
    payload: change,
  });
};