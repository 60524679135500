import React from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  Container,
  ThemeProvider,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Copyright from "../../components/copyright";
import Header from "../../components/header";
import useStyles from "../../components/util/styles";
import { useAuth } from "../../context/authContext";
import { RouteComponentProps, useParams } from "react-router-dom";
import CustomSnackbar from "../../components/snackbar";
import {
  confirmPasswordError,
  passwordError,
  resetPassword,
  validPassword,
} from "../../context/authContext/actions";
import theme from "../../components/util/theme";

export type ResetPasswordFormState = {
  newPassword: string;
  newPasswordError: string;
  confirmNewPassword: string;
  confirmNewPasswordError: string;
};

export const ResetPassword = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  const { dispatch } = useAuth();
  const { email, token } = useParams<{ email?: string, token?: string }>();

  const [resetPasswordForm, setResetPasswordForm] = React.useState<
    ResetPasswordFormState
  >({
    newPassword: "",
    newPasswordError: "",
    confirmNewPassword: "",
    confirmNewPasswordError: "",
  });

  const handlePasswordReset = async (e: any, dispatch: any) => {
    e.preventDefault();

    email &&
      token &&
      validPassword(resetPasswordForm.newPassword) &&
      validPassword(resetPasswordForm.confirmNewPassword) &&
      resetPasswordForm.newPassword === resetPasswordForm.confirmNewPassword &&
      resetPassword(
        dispatch,
        email,
        token,
        resetPasswordForm.newPassword,
        resetPasswordForm.confirmNewPassword
      ).then(() => history.push("/cellars"));

    setResetPasswordForm({
      ...resetPasswordForm,
      newPasswordError: passwordError(resetPasswordForm.newPassword),
      confirmNewPasswordError: confirmPasswordError(
        resetPasswordForm.newPassword,
        resetPasswordForm.confirmNewPassword
      ),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.rootLogin}>
        <Header />
        <CustomSnackbar />
        <Container maxWidth="md">
          <Grid
            item
            component={Paper}
            className={classes.paperLogin}
            elevation={6}
            square
          >
            <div className={classes.paperLogin}>
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.avatarContainer}>
                    <Avatar className={classes.avatar}>
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Set new password
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      autoComplete="new-password"
                      name="new-password"
                      label="New Password"
                      type="password"
                      id="new-password"
                      value={resetPasswordForm.newPassword}
                      error={resetPasswordForm.newPasswordError.length > 0}
                      helperText={resetPasswordForm.newPasswordError}
                      onChange={(e) =>
                        setResetPasswordForm({
                          ...resetPasswordForm,
                          newPassword: e.target.value,
                          newPasswordError: passwordError(e.target.value),
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      autoComplete="new-password-confirmation"
                      name="new-password-confirmation"
                      label="Confirm New Password"
                      type="password"
                      id="new-password-confirmation"
                      value={resetPasswordForm.confirmNewPassword}
                      error={
                        resetPasswordForm.confirmNewPasswordError.length > 0
                      }
                      helperText={resetPasswordForm.confirmNewPasswordError}
                      onChange={(e) =>
                        setResetPasswordForm({
                          ...resetPasswordForm,
                          confirmNewPassword: e.target.value,
                          confirmNewPasswordError: confirmPasswordError(
                            resetPasswordForm.newPassword,
                            e.target.value
                          ),
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={async (e) => handlePasswordReset(e, dispatch)}
                    >
                      Continue
                    </Button>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </div>
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
};
