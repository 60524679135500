import React from "react";

import { showLoginError, showSignupError } from "./actions";
import { Dispatch, ErrorState, errorReducer, errorReducerInitialState } from "./reducer";

const ErrorStateContext = React.createContext<ErrorState | undefined>(undefined);
const ErrorDispatchContext = React.createContext<Dispatch | undefined>(undefined);

const ErrorProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(errorReducer, errorReducerInitialState);

  return (
    <ErrorStateContext.Provider value={state}>
      <ErrorDispatchContext.Provider value={dispatch}>
        { children }
      </ErrorDispatchContext.Provider>
    </ErrorStateContext.Provider>
  );
};

const useErrorState = () => {
  const context = React.useContext(ErrorStateContext);

  if (!context) { throw new Error("useSnackbarState must be used within SnackbarProvider"); }
  return context;
};

const useErrorDispatch = () => {
  const context = React.useContext(ErrorDispatchContext);

  if (!context) { throw new Error("useSnackbarDispatch must be used within SnackbarProvider"); }
  return context;
};

export { ErrorProvider, useErrorState, useErrorDispatch, showLoginError, showSignupError };
