import React from "react";

import { checkToken, createUser, login, logout } from "./actions";
import { IAuthContext, authReducerInitialState, authReducer } from "./reducer";

const AuthContext = React.createContext<IAuthContext>({
  state: {
    loading: false,
    paymentCurrency: "USD",
    wineSearches: 0,
    paymentMethods: [],
  },
  dispatch: () => {},
});

const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    authReducer,
    authReducerInitialState
  );
  const authValue = {
    state,
    dispatch,
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth, logout, login, checkToken, createUser };
