import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddFields from "./AddFields";
import EditFields from "./EditFields";
import SellFields from "./SellFields";
import ImageUpload from "../imageUpload";
import { useAuth } from "../../context/authContext";
import AddReviewFields from "./AddReviewFields";

const useStyles = makeStyles({
  textRoot: {
    marginTop: 16,
  },
  selectRoot: {
    marginTop: 16,
  },
  performance: {
    marginTop: 8,
  },
  performanceChild: {
    padding: 0,
  },
  footer: {
    marginTop: 16,
  },
  boldText: {
    fontWeight: 700,
  },
});

const CellarModalForm: React.FC<{
  type: string;
  title: string;
  buttonText: string;
  open: boolean;
  setOpen: (t: boolean) => void;
  setFormErrors: (t: any) => void;
  initialValues: any;
  onSubmit: (e: any, form: any, cb: any) => void;
  errors: any;
}> = ({
  type,
  title,
  buttonText,
  open,
  setOpen,
  setFormErrors,
  initialValues,
  onSubmit,
  errors,
}) => {
  const classes = useStyles();
  const {
    state: { user },
  } = useAuth();
  const currency = user ? user.currency : "USD";
  const [form, setForm] = React.useState(initialValues);

  React.useEffect(() => {
    setForm(initialValues);
  }, [initialValues]);

  const onCancel = () => {
    setForm(initialValues);
    setFormErrors({});
    setOpen(false);
  };

  const defaultProps = {
    form,
    setForm,
    errors,
    setFormErrors,
    currency,
  };
  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="sm">
      <DialogTitle id="form-dialog-title">
        <span style={{ fontWeight: 700 }}>{title}</span>
      </DialogTitle>
      <DialogContent>
        {form.image_url && (
          <img
            alt="cellarImage"
            style={{
              margin: 16,
              maxHeight: 120,
            }}
            src={form.image_url}
          />
        )}
        <form noValidate autoComplete="off">
          {type === "Add" && <AddFields {...defaultProps} />}
          {type === "Edit" && <EditFields {...defaultProps} />}
          {type === "Sell" && <SellFields {...defaultProps} />}
          {type === "Add Tasting Note" && <AddReviewFields {...defaultProps} />}
        </form>
        {(type === "Add" || type === "Edit") && (
          <ImageUpload
            id={initialValues.id}
            setImageUrl={(imageUrl) => {
              setForm({
                ...form,
                image_url: imageUrl,
              });
            }}
          />
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.footer }}>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            onSubmit(e, form, () => setForm(initialValues));
          }}
          color="primary"
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CellarModalForm;
