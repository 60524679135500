import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useAuth } from "../../context/authContext";
import {
  getCurrentUser,
  getOldPlan,
  importSubscription,
} from "../../context/authContext/actions";
import Typography from "@material-ui/core/Typography";
import { Button, CircularProgress } from "@material-ui/core";
import useStyles from "../../components/util/styles";
import { format } from "date-fns";
import { setPaymentMethodRequest } from "../../lib/userRequests";

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CardDetailsForm = () => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const history = useHistory();
  const setPaymentMethod = !!useRouteMatch("/set-payment-method");
  const {
    state: { user, oldPlan },
    dispatch: authDispatch,
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [symbol, setSymbol] = useState("$");

  React.useEffect(() => {
    if (!user) {
      getCurrentUser(authDispatch);
    }
    if (oldPlan) {
      setSymbol(oldPlan.currency === "GBP" ? "£" : "$");
    } else if (user && user.old_sub_id) {
      getOldPlan(authDispatch, user.old_sub_id);
    }
  }, [authDispatch, user, oldPlan]);

  // Handle real-time validation errors from the card Element.
  const handleChange = (event: any) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle skip.
  const handleSkip = async (event: any) => {
    event.preventDefault();
    if (user && user.old_sub_id) {
      setLoading(true);
      await importSubscription(authDispatch, user.old_sub_id, history);
      setLoading(false);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js or User has not yet loaded.
      return;
    }

    setLoading(true);

    const card = elements.getElement(CardElement);
    if (card) {
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: card,
      });

      if (result.paymentMethod) {
        await setPaymentMethodRequest(result.paymentMethod.id);
      }
    }
    setLoading(false);
    if (setPaymentMethod) {
      history.goBack();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <Typography
          align="center"
          component="h1"
          variant="h4"
          style={{ margin: 16 }}
        >
          Please enter your card details
        </Typography>
        {oldPlan && (
          <Typography
            align="center"
            style={{ marginTop: 16, color: "#aab7c4" }}
          >
            {`You are currently subscribed to plan: Cellar Watch ${oldPlan.plan} (${symbol}${oldPlan.price}/mo)`}
          </Typography>
        )}
        {oldPlan && oldPlan.renewsAt && (
          <Typography
            align="center"
            style={{ marginBottom: 16, color: "#aab7c4" }}
          >
            {`Your subscription will automatically renew on ${format(
              Date.parse(oldPlan.renewsAt),
              "MMMM do, yyyy"
            )}`}
          </Typography>
        )}
        <CardElement
          id="card-element"
          options={CARD_ELEMENT_OPTIONS}
          onChange={handleChange}
        />
        <div className="card-errors" role="alert">
          {error}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {loading ? (
          <CircularProgress style={{ margin: 16 }} />
        ) : (
          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.saveCard}
            >
              Save
            </Button>
            {!setPaymentMethod && (
              <Button
                variant="text"
                className={classes.saveCard}
                style={{ color: "#aab7c4", marginLeft: 16 }}
                onClick={(e: any) => handleSkip(e)}
              >
                Skip
              </Button>
            )}
          </div>
        )}
      </div>
    </form>
  );
};

export default CardDetailsForm;
