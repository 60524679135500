import React from "react";
import { AuthProvider } from "./authContext";
import { CellarsProvider } from "./cellarsContext";
import { CellarWinesProvider } from "./cellarWinesContext";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ErrorProvider } from "./errorContext";
import { VinousReviewsProvider } from "./vinousReviewsContext";

const AppProviders: React.FC = ({ children }) => {
  return (
    <ErrorProvider>
      <AuthProvider>
        <CellarsProvider>
          <CellarWinesProvider>
            <VinousReviewsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {children}
              </MuiPickersUtilsProvider>
            </VinousReviewsProvider>
          </CellarWinesProvider>
        </CellarsProvider>
      </AuthProvider>
    </ErrorProvider>
  );
};

export default AppProviders;
