import React from "react";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import useStyles from "../util/styles";
import { bottleFormats, packedAsOptions } from "../../config";

const SellFields: React.FC<{
  form: any;
  setForm: (obj: any) => void;
  setFormErrors: (obj: any) => void;
  errors: any;
}> = ({ form, setForm, errors, setFormErrors }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Current Bottles"
          defaultValue={form.bottleCount}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputLabel disabled style={{ fontSize: "12px" }} htmlFor="size">
          Size
        </InputLabel>
        <Select
          classes={{ root: classes.selectRoot }}
          labelId="size"
          value={form.bottleSize}
          fullWidth
          disabled
          inputProps={{
            name: "Size",
            id: "size",
          }}
        >
          {bottleFormats.map((f) => (
            <MenuItem key={f} value={f}>{`${f}cl`}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputLabel disabled style={{ fontSize: "12px" }} htmlFor="packed-as">
          Packed As
        </InputLabel>
        <Select
          classes={{ root: classes.selectRoot }}
          fullWidth
          labelId="Packed As"
          value={form.bottlePackedAs || ""}
          disabled
          inputProps={{
            name: "Packed As",
            id: "packed-as",
          }}
        >
          {packedAsOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option !== "Packed As" ? `${option}cl` : option}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={4} classes={{ root: classes.datePickerContainer }}>
        <KeyboardDatePicker
          format="MM/dd/yyyy"
          fullWidth
          margin="none"
          id="sell-date-picker-inline"
          label="Sell Date"
          helperText={errors.bottles && "Required"}
          error={errors.sellDate}
          value={form.sellDate || null}
          onChange={(e) => setForm({ ...form, sellDate: e as Date })}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          classes={{ root: classes.textRoot }}
          label="Bottles Sold"
          id="bottles-sold"
          helperText={errors.bottles && "Required"}
          error={errors.bottles}
          onChange={(e) => {
            errors.bottles && setFormErrors({ ...errors, bottles: false });
            setForm({
              ...form,
              bottles: (e.target.value as unknown) as number,
            });
          }}
          defaultValue={form.bottles}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          id="sold-amount"
          helperText={errors.soldFor && "Required"}
          error={errors.soldFor}
          classes={{ root: classes.textRoot }}
          label="Sold For"
          onChange={(e) => {
            errors.soldFor && setFormErrors({ ...errors, soldFor: false });
            setForm({
              ...form,
              soldFor: (e.target.value as unknown) as number,
            });
          }}
          defaultValue={form.soldFor}
        />
      </Grid>
    </Grid>
  );
};

export default SellFields;
