import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Add from "@material-ui/icons/Add";
import { getSignedUrlRequest, putCsvRequest } from "../../lib/cellarRequests";
import useStyles from "../util/styles";

export default function CsvUpload({
  id,
  uploadUser,
  setCsvInformation,
}: {
  id: string;
  uploadUser: any;
  setCsvInformation: (filename: string) => void;
}) {
  const classes = useStyles();
  const [upload, setUpload] = React.useState<{
    isUploading: boolean;
    hover: boolean;
  }>({
    isUploading: false,
    hover: false,
  });

  const onDrop = useCallback(
    async (files: any) => {
      if (files.length === 0) return;
      setUpload({
        isUploading: true,
        hover: false,
      });
      const file = files[0];
      const timestamp = Date.now().toString();
      const fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1);
      const fileName = `admin/cellarUploads/cellar-csv/${uploadUser.email}-cellar-${timestamp}.${fileExtension}`;

      const response = await getSignedUrlRequest(fileName, file.type, "authenticated-read");
      const { signedUrl } = response.data;

      await putCsvRequest(signedUrl, file);
      setCsvInformation(fileName);

      setUpload({
        isUploading: false,
        hover: false,
      });
    },
    [setCsvInformation, uploadUser.email]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    accept: ".csv,text/csv",
  });

  return (
    <div style={{ margin: 16 }}>
      <div
        {...getRootProps()}
        onMouseEnter={() => setUpload({ ...upload, hover: true })}
        onMouseLeave={() => setUpload({ ...upload, hover: false })}
        className={
          upload.hover ? classes.imageUploadHover : classes.imageUpload
        }
      >
        <input {...getInputProps()} />
        {upload.isUploading ? (
          <p>Uploading ...</p>
        ) : isDragActive ? (
          <Add />
        ) : isDragReject ? (
          <p>Wrong file type. .csv required</p>
        ) : (
          <p>Drop .csv here</p>
        )}
      </div>
    </div>
  );
}
