import decode from "jwt-decode";

export const getToken = () => { return localStorage.getItem('token') };

export const hasToken = () => {
  const token = getToken();
  return validToken(token);
};

export const validToken = (token: string | null) => {
  return token !== null && token !== "null" && token !== "";
};

export const setToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const destroyToken = () => {
  localStorage.setItem("token", "");
};

export const tokenIsAdmin = () => {
  const token = getToken();
  const decoded: any = decode(token || "");
  return decoded && decoded.adminRole && (decoded.adminRole === "admin" || decoded.adminRole === "superadmin");
}
