import { makeStyles } from "@material-ui/core";
import Colors from "./colors";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => {
  const wineInfoFontWeight = theme.typography.fontWeightBold;
  const wineInfoFontSize = theme.typography.fontSize;
  return {
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
      ul: {
        margin: 0,
        padding: 0,
      },
      li: {
        listStyle: "none",
      }
    },
    root: {
      display: "flex",
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    drawerItemSelected: {
      color: Colors.vinousRed,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("xs")]: {
        width: "100vw",
      },
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(8),
      },
      [theme.breakpoints.down("sm")]: {
        width: 0,
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    borderRight: {
      borderRight: `1px solid ${Colors.stagingGrey}`
    },
    borderRightHideMobile: {
      borderRight: `1px solid ${Colors.stagingGrey}`,
      [theme.breakpoints.down("xs")]: {
        borderRight: "none"
      }
    },
    vinousRed: {
      color: Colors.vinousRed
    },
    borderBottom: {
      borderBottom: `1px solid ${Colors.stagingGrey}`
    },
    border: {
      border: `1px solid ${Colors.stagingGrey}`
    },
    borderRadiusNone: {
      borderRadius: "0"
    },
    boxShadowNone: {
      boxShadow: "none"
    },
    marginZero: {
      margin: "0 !important"
    },
    sans: {
        fontFamily: "'Open-sans', sans-serif;", 
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      margin: 0,
      [theme.breakpoints.up("md")]: {
        paddingLeft: "96px"
      }
    },
    paper: {
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    paperBorder: {
      boxShadow: "none",
      border: `1px solid ${Colors.stagingGrey}`,
      borderRadius: "0"
    },
    fixedHeight: {
      height: 320,
      marginBottom: 20,
    },
    centerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    flexBasisZero: {
      flexBasis: 0
    },
    flexBasisAuto: {
      flexBasis: "auto"
    },
    marginBottom24: {
      marginBottom: "24px"
    },
    cellarHeader: {
      height: 300,
    },
    appBarPricing: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbarPricing: {
      flexWrap: "wrap",
    },
    toolbarTitle: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 1.5),
    },
    heroContent: {
      padding: theme.spacing(8, 0, 4),
    },
    cardHeader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    cardPricing: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      marginBottom: theme.spacing(2),
    },
    footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
    toolbarSecondary: {
      justifyContent: "space-between",
      overflowX: "auto",
    },
    toolbarLink: {
      padding: theme.spacing(1),
      flexShrink: 0,
    },
    mainFeaturedPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainFeaturedPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    card: {
      display: "flex",
    },
    cardDetails: {
      flex: 1,
    },
    cardMedia: {
      margin: theme.spacing(2, 2),
      height: 160,
      width: 160,
    },
    toolbarHome: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbarLogo: {
      height: 64,
      maxWidth: 320,
      margin: 8,
      [theme.breakpoints.down("sm")]: {
        margin: 4,
        height: 50,
        maxWidth: 120,
      },
    },
    rootLogin: {
      flexDirection: "column",
      alignItems: "center",
      minHeight: "100vh",
    },
    paperLogin: {
      margin: theme.spacing(4, 0, 4, 0),
      padding: theme.spacing(0, 2, 0, 2),
      display: "grid",
      flexDirection: "column",
      alignItems: "center",
    },
    avatarContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    saveCard: {
      margin: theme.spacing(3, 0, 2),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      width: 320,
    },
    headerButton: {
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 0, 0, 0),
      },
      margin: theme.spacing(0, 1, 0, 1),
    },
    settingsField: {
      width: 320,
    },
    wineCard: {
      marginTop: theme.spacing(3),
    },
    wineColumn: {
      padding: 0,
      minWidth: "200px",      
      [theme.breakpoints.up("md")]: {
        width: "350px"
      },
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    lwinNum: {
      fontWeight: theme.typography.fontWeightBold,
    },
    wineCardActions: {
      justifyContent: "space-between",
    },
    spinnerContainer: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    wineInfoTopGrid: {
      flexGrow: 1,
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.grey[400],
      paddingBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    wineInfoBottomGrid: {
      marginTop: theme.spacing(3),
      flexGrow: 1,
    },
    gridItem: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
      },
    },
    wineInfoLabel: {
      fontWeight: wineInfoFontWeight,
      fontSize: wineInfoFontSize,
      width: "50%",
    },
    wineInfoLabelGrid6: {
      fontWeight: wineInfoFontWeight,
      fontSize: wineInfoFontSize,
      width: "24%",
    },
    wineInfoLabelGrid4: {
      fontWeight: wineInfoFontWeight,
      fontSize: wineInfoFontSize,
      width: "36.5%",
    },
    wineInfoLabelItem: {
      fontSize: wineInfoFontSize,
    },
    mobileGridSpace: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    vinousReviews: {
      flexGrow: 1,
      paddingBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    vinousReview: {
      marginTop: theme.spacing(3),
    },
    vinousReviewScore: {
      color: Colors.vinousRed,
    },
    vinousReviewScoreUnit: {
      fontSize: 12,
    },
    vinousReviewByline: {
      color: Colors.vinousRed,
    },
    depositContext: {
      display: "flex",
    },
    textRoot: {
      marginTop: 16,
    },
    selectRoot: {
      marginTop: 4,
    },
    datePickerContainer: {
      marginTop: 16,
    },
    imageUpload: {
      width: 160,
      height: 160,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderWidth: 2,
      borderColor: "#666",
      borderStyle: "solid",
      borderRadius: 5,
    },
    imageUploadHover: {
      width: 160,
      height: 160,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderWidth: 3,
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",
      borderRadius: 5,
    },
    cwTableCell: {
      border: "none",
    },
    cwHeadTableCell: {
      borderColor: Colors.vinousRed,
    },
    cwHeadTableCellText: {
      fontWeight: wineInfoFontWeight,
      fontSize: wineInfoFontSize,
      "&:hover": {
        color: theme.palette.primary.main,
        cursor: "pointer",
      },
    },
  };
});

export default useStyles;
