import React from "react";
import { cellarsReducer, ICellarsContext } from "./reducer";
import {
  loadCellars,
  createCellar,
  editCellar,
  deleteCellar,
  duplicateCellar,
} from "./actions";

const CellarsContext = React.createContext<ICellarsContext | undefined>(
  undefined
);

const CellarsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(cellarsReducer, {
    loading: false,
    cellars: undefined,
    slugs: undefined,
    message: undefined,
    currencyChange: false,
  });

  const cellarsValue = { state, dispatch };

  return (
    <CellarsContext.Provider value={cellarsValue}>
      {children}
    </CellarsContext.Provider>
  );
};

const useCellarsContext = () => {
  const context = React.useContext(CellarsContext);

  if (!context) {
    throw new Error("useCellarsContext must be used within CellarsProvider");
  }
  return context;
};

export {
  CellarsProvider,
  useCellarsContext,
  loadCellars,
  createCellar,
  editCellar,
  deleteCellar,
  duplicateCellar,
};
