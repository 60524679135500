import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import GAListener from "./components/GAListener";
import Login from "./pages/login";
import Cellars from "./pages/cellars";
import Cellar from "./pages/cellar";
import StripeElementsWrapper from "./components/stripeElementsWrapper";
import Home from "./pages/landing/Home";
import Privacy from "./pages/landing/Privacy";
import Terms from "./pages/landing/Terms";
import SignUp from "./pages/signup";
import AppProviders from "./context";
import { hasToken, tokenIsAdmin } from "./lib/localStorage";
import theme from "../src/components/util/theme";
import Settings from "./pages/settings";
import { Onboarding } from "./pages/onboarding";
import EditCellar from "./pages/editCellar";
import { ResetPassword } from "./pages/resetPassword";
import { CardDetailsPage } from "./pages/addPaymentMethod";
import PriceHistory from "./pages/priceHistory";
import MarketReports from "./pages/marketReports";
import Glossary from "./pages/glossary";
import UploadCellar from "./pages/uploadCellar";

export default function Router() {
  return (
    <AppProviders>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <GAListener trackingId="G-421N3C9BF4">
            <Switch>
              <Routes />
            </Switch>
          </GAListener>
        </BrowserRouter>
      </ThemeProvider>
    </AppProviders>
  );
}

const Routes: React.FC = () => {
  return (
    <>
      <Route exact path="/" component={Home} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/privacy" component={Privacy} />
      <Route path="/pricing" component={StripeElementsWrapper} />
      <Route path="/login" component={Login} />
      <Route path="/signup/" component={SignUp} />
      <Route
        path="/signupExisting/:email/:token/:fname/:lname"
        component={SignUp}
      />
      <Route path="/onboarding" component={Onboarding} />
      <Route path="/forgotPassword" component={Onboarding} />
      <Route path="/resetPassword/:email/:token" component={ResetPassword} />
      <ProtectedRoutes />
      <AdminRoutes />
    </>
  );
};

const ProtectedRoute = ({ component, ...rest }: any) => {
  const Cmp = component;
  return (
    <Route
      {...rest}
      render={(props) =>
        hasToken() ? (
          <Cmp {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/login", // TODO: redirect to /signup in case of signup error
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const ProtectedRoutes = () => (
  <>
    <ProtectedRoute path="/cellars" exact component={Cellars} />
    <ProtectedRoute path="/cellars/:slug" exact component={Cellar} />
    <ProtectedRoute
      path="/cellars/:slug/editCellar"
      exact
      component={EditCellar}
    />
    <ProtectedRoute path="/settings" exact component={Settings} />
    <ProtectedRoute path="/select-plan" component={StripeElementsWrapper} />
    <ProtectedRoute path="/add-payment-method" component={CardDetailsPage} />
    <ProtectedRoute path="/set-payment-method" component={CardDetailsPage} />
    <ProtectedRoute path="/priceHistory" exact component={PriceHistory} />
    <ProtectedRoute path="/marketReports" exact component={MarketReports} />
    <ProtectedRoute path="/glossary" exact component={Glossary} />
  </>
);

const AdminRoute = ({ component, ...rest }: any) => {
  const Cmp = component;
  return (
    <Route
      {...rest}
      render={(props) =>
        tokenIsAdmin() ? (
          <Cmp {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/cellars", 
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const AdminRoutes = () => (
  <>
    <AdminRoute path="/admin/uploadCellar" exact component={UploadCellar} />
  </>
);
