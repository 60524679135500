import { format } from "date-fns";

// Add commas to the number (1000000 => 1,000,000)
export const numberWithCommas = (x: number | string): string => {
  if(x === null) return x;
  return x.toLocaleString(undefined, { maximumFractionDigits: 0 });
};

export const numberFormattedWithCurrency = (
  currency: string,
  num: number | string | undefined
): string => {
  return `${currency}${(num || num === 0) && num != null ? numberWithCommas(num) : "-"}`;
};

export const colorForPercentage = (percent: number | boolean): string => {
  let color = "inherit";
  if (percent < 0) color = "red";
  if (percent > 0) color = "green";

  return color;
};

export const formattedPercentage = (percent: number) => {
  return `${percent > 0 ? "+" : ""}${percent.toFixed(1).toLocaleString()}%`;
};

export const formatToYear = (date: string | Date) => {
  try {
    const nonStringDate = typeof date === "string" ? Date.parse(date) : date;
    return format(nonStringDate, "yyyy");
  } catch (e) {
    return "";
  }
};
