import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import AddIcon from "@material-ui/icons/AddAPhoto";
import { getSignedUrlRequest, putImageRequest } from "../../lib/cellarRequests";
import useStyles from "../util/styles";

export default function ImageUpload({
  id,
  setImageUrl,
}: {
  id: string;
  setImageUrl: (imageUrl: string) => void;
}) {
  const classes = useStyles();
  const [upload, setUpload] = React.useState<{
    isUploading: boolean;
    hover: boolean;
  }>({
    isUploading: false,
    hover: false,
  });

  const onDrop = useCallback(
    async (files: any) => {
      setUpload({
        isUploading: true,
        hover: false,
      });
      const file = files[0];
      const timestamp = Date.now().toString();
      const fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1);
      const fileName = `images/${id}/${timestamp}-lg.${fileExtension}`;

      const response = await getSignedUrlRequest(fileName, file.type);
      const { bucket, region, signedUrl } = response.data;

      await putImageRequest(signedUrl, file);
      const imageUrl = `https://${bucket}.s3.${region}.amazonaws.com/${fileName}`;
      setImageUrl(imageUrl);

      setUpload({
        isUploading: false,
        hover: false,
      });
    },
    [id, setImageUrl]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div style={{ margin: 16 }}>
      <div
        {...getRootProps()}
        onMouseEnter={() => setUpload({ ...upload, hover: true })}
        onMouseLeave={() => setUpload({ ...upload, hover: false })}
        className={
          upload.hover ? classes.imageUploadHover : classes.imageUpload
        }
      >
        <input {...getInputProps()} />
        {upload.isUploading ? (
          <p>Uploading ...</p>
        ) : isDragActive ? (
          <p>Drop image here</p>
        ) : (
          <AddIcon />
        )}
      </div>
    </div>
  );
}
