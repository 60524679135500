import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";

type ConfirmDialogProps = {
  title?: string;
  children?: JSX.Element | string;
  open: boolean;
  setOpen: (b: boolean) => void;
  onConfirm: () => void;
};

const ConfirmDialog = ({ title, children, open, setOpen, onConfirm }: ConfirmDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          color="default"
        >
          Cancel
        </Button>
        <Button
          autoFocus
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
