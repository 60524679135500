import React from "react";
import { Paper, CircularProgress, Button, Switch, FormControlLabel, FormGroup, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import { livexIndexOptions } from "../../constants";
import { loadPriceHistoryForIndices } from "../../context/cellarsContext/actions";
import { useCellarsContext } from "../../context/cellarsContext";
import LivexIndicesDialog from "./LivexIndicesDialog";
import Title from "../title";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

type ChartSeries = {
  name: string;
  data: [string, number][];
  formatter: () => string;
};

type Datum = {
  date: string,
  level: number,
};

const Chart: React.FC<{
  historicData: ChartSeries[];
  loading: boolean;
  includeLivexIndices: boolean;
  title?: string;
  showRebaseToggle?: boolean;
}> = ({
        historicData,
        loading,
        includeLivexIndices,
        title,
        showRebaseToggle,
      }) => {
  const classes = useStyles();

  const {
    state: {
      priceRecordsForIndices,
    },
    dispatch,
  } = useCellarsContext();

  const [selectedIndices, setSelectedIndices] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [rebaseToggle, setRebaseToggle] = React.useState(false);

  const handleClose = async () => {
    await loadPriceHistoryForIndices(dispatch, selectedIndices);

    if (selectedIndices.length > 0) {
      showRebaseToggle && setRebaseToggle(true); 
    } else {
      showRebaseToggle && setRebaseToggle(false);
    }

    setOpen(false);
  };

  const handleClick = (symbol: string) => {
    const index = selectedIndices.indexOf(symbol);
    const updatedIndicesList =
      index >= 0
        ? selectedIndices.filter((_, i) => i !== index)
        : [...selectedIndices, symbol];
    setSelectedIndices(updatedIndicesList);
  };

  const processDatumForChart = ({ date, level }: Datum) => {
    return [
      Date.parse(date),
      Math.round((level + Number.EPSILON) * 100) / 100,
    ];
  };
  

  const processedData = historicData.map(({ data, name, formatter}) => {
    return {
      data: data.sort((a, b) => a[0].localeCompare(b[0])).map((d) => processDatumForChart({ date: d[0], level: d[1]})),
      name,
      tooltip: {
        pointFormatter: formatter,
      }
    }
  });

  const firstEntries = processedData.map((s) => s.data.length > 0 ? s.data[0] : null).sort();
  const firstHistoricalDatum = firstEntries[0] ? firstEntries[0][0] : null;

  const selectedPriceRecordsForIndices = priceRecordsForIndices?.filter((pri) => selectedIndices.includes(pri.symbol));
  const processedPriceRecordsForIndices = _.chain(selectedPriceRecordsForIndices)
    .groupBy("symbol")
    .map((value, key) => {
      const isStock = value[0] && value[0].type && value[0].type === "stock";
      const indiceName = isStock && value[0].name ? value[0].name : key;
      return {
        name: indiceName,
        data: value.sort((a, b) => a.date.localeCompare(b.date)).filter((pd) => firstHistoricalDatum ? Date.parse(pd.date) >= firstHistoricalDatum : true).map(processDatumForChart),
        tooltip: {
          pointFormatter: function(): string {
            // @ts-expect-error
            let s = `<span style="color:${this.color}">${this.series.name}</span>: `;
            
            // @ts-expect-error
            if (this.change) {
               // @ts-expect-error
              s = s + `${this.change.toLocaleString(undefined, { maximumFractionDigits: 2 })}%<br/>`;
            } else {
              // @ts-expect-error
              isStock ? s = s + `${this.y.toLocaleString(undefined, { maximumFractionDigits: 2 })}<br/>`
                // @ts-expect-error
                : s = s + `${this.y.toLocaleString(undefined, { maximumFractionDigits: 2 })}%<br/>`;
            }

            return s;
          },
        },
      };
    }).value();

  let chartOptions: any = {
    series: [...processedPriceRecordsForIndices, ...processedData],
  };

  if (showRebaseToggle ? rebaseToggle : selectedIndices.length > 0) {
    chartOptions = {
      ...chartOptions,
      tooltip: {
        split: true,
      },
      xAxis: {
        type: "datetime",
        tickInterval: 24 * 3600 * 1000,
        units: [
          ["month", [1, 3, 6]],
          ["day", [1]],
        ],
      },
      plotOptions: {
        series: {
          compare: "percent",
          compareStart: true,
          showInNavigator: true,
        },
      },
    }
  }

  return (
    <Paper className={classes.root}>
      { title && (
        <div
          style={{ padding: "8px 16px" }}
          className={`${classes.borderBottom}`}
        >
          <Title>{title}</Title>
        </div>
      )}

      { includeLivexIndices &&
      <Box justifyContent="space-between" display="flex">
        <LivexIndicesDialog
          open={open}
          handleClose={handleClose}
          livexIndexOptions={livexIndexOptions}
          selectedIndices={selectedIndices}
          handleClick={handleClick}
        />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          className="modalButton"
          style={{
            margin: 16,
          }}
          onClick={() => setOpen(true)}
        >
          Compare to Indices
        </Button>

        { showRebaseToggle && <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={rebaseToggle}
                onChange={(_e): void => setRebaseToggle(!rebaseToggle)}
                name="cellarWineChartRebaseToggle"
                color="primary"
              />
            }
            label="Rebase Chart"
          />
        </FormGroup>
      }
      </Box>
      }
      {!loading ? (
        <HighchartsReact
          highcharts={Highcharts}
          updateArgs={[true, true, false]}
          immutable={true}
          options={chartOptions}
          constructorType="stockChart"
        />
      ) : (
        <div className={classes.loadingContainer}>
          <CircularProgress style={{ margin: 32 }} />
        </div>
      )}
    </Paper>
  );
};
export default Chart;
