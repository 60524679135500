import React from "react";
import { VinousReview } from "../../context/vinousReviewsContext";
import ReactHtmlParser from "react-html-parser";
import { Typography, Grid, Link, CircularProgress } from "@material-ui/core";
import { format } from "date-fns";
import useStyles from "../util/styles";

type vinousReviewsProps = {
  vinousInfo: {
    loading: boolean;
    vinousReviews?: VinousReview[];
  };
};

export const VinousReviews: React.FC<vinousReviewsProps> = ({ vinousInfo }) => {
  const classes = useStyles();
  const { vinousReviews, loading } = vinousInfo;
  return (
    <div className={classes.vinousReviews}>
      <Typography component="h2" variant="h6" gutterBottom>
        Vinous Reviews
      </Typography>
      {!loading && vinousReviews ? (
        vinousReviews.length > 0 ? (
          vinousReviews.map((vr) => (
            <VinousReviewEl vinousReview={vr} key={vr.id} />
          ))
        ) : (
          <p>No Vinous reviews found.</p>
        )
      ) : (
        <div className={classes.centerContainer}>
          <CircularProgress style={{ margin: 16 }} />
        </div>
      )}
    </div>
  );
};

export const parseScoreRawForDisplay = (scoreRaw: string): string => {
  return scoreRaw.replace(".5", "+");
};

const VinousReviewEl: React.FC<{ vinousReview: VinousReview }> = ({
  vinousReview,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.vinousReview}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography className={classes.vinousReviewScore} variant="subtitle1">
            {parseScoreRawForDisplay(vinousReview.score_raw)}
            <span className={classes.vinousReviewScoreUnit}>pts</span>
          </Typography>
          {vinousReview.drinking_window_begin &&
          vinousReview.drinking_window_end ? (
            <Grid item xs={12}>
              <Typography variant="body2">
                {`Drinking window: ${vinousReview.drinking_window_begin} - ${vinousReview.drinking_window_end}`}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          {ReactHtmlParser(vinousReview.tasting_note)}
          <Link href={vinousReview.full_article_url}>
            {`- ${vinousReview.author} on ${format(
              new Date(vinousReview.review_date),
              "MMMM yyyy"
            )} from the article "${vinousReview.article_title}"`}
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};
