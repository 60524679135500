import React, { ReactNode } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Pricing from "../../pages/pricing";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || "pk_test_DOfurO67SQxRMaaALhDC7e1o";
const stripePromise = loadStripe(stripePublicKey);

type StripeElementsWrapperProps = {
  children?: ReactNode;
};

const StripeElementsWrapper: React.FC<StripeElementsWrapperProps> = ({
  children,
}) => {

  if (children) {
    return <Elements stripe={stripePromise}>{children}</Elements>;
  } else {
    return (
      <Elements stripe={stripePromise}>
        <Pricing />
      </Elements>
    );
  }
};

export default StripeElementsWrapper;
