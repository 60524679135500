import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { getLwinSearchRequest } from "../../lib/livexRequests";

type SearchFieldValue = {
  lwin?: string;
  display_name: string;
};

const getVintageOptions = () => {
  const currentYear = new Date().getFullYear();
  const minYear = 1800;
  const vintages: string[] = [];
  const lengthDifference = currentYear - minYear + 1;
  Array(lengthDifference)
    .fill(0)
    .forEach((_, i) => {
      vintages.push((i + minYear).toString());
    });
  vintages.push("No Vintage");
  vintages.reverse();
  return vintages;
};

const vintages = getVintageOptions();

const LwinSearchField = ({
  setLwin,
  setVintageOptions,
  error,
  removeError,
}: any) => {
  const [value, setValue] = React.useState("");
  const [options, setOptions] = React.useState<SearchFieldValue[]>([]);

  const fetchOptions = async (value: string) => {
    const { results } = await getLwinSearchRequest(value);
    return results;
  };

  React.useEffect(() => {
    let active = true;

    setOptions((prevState: SearchFieldValue[]) => (value ? prevState : []));
    if (!value) {
      return;
    }

    fetchOptions(value).then((res) => {
      setOptions(() => (active ? res : []));
    });

    return () => {
      active = false;
    };
  }, [value]);

  return (
    <Autocomplete
      id="lwin-search"
      options={options}
      autoHighlight
      filterOptions={(passedOptions: SearchFieldValue[]) => passedOptions}
      autoSelect
      inputValue={value}
      getOptionLabel={({ display_name }) => display_name}
      onInputChange={(_event, value, _reason) => {
        setValue(value);
      }}
      onChange={(_e, newValue) => {
        error && removeError();
        setLwin(newValue ? newValue.lwin : null);
        setVintageOptions(vintages);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            id="search-wine"
            label="Search Wine"
            fullWidth
            helperText={error && "Required"}
            error={error}
          />
        );
      }}
    />
  );
};

export default LwinSearchField;
