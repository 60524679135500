import React, { Dispatch } from "react";
import { getVinousReviewsForCellarSlugRequest } from "../../lib/vinousReviewsRequests";

export type VinousReview = { 
  tasting_note: string;
  id: number;
  score_raw: string;
  drinking_window_begin: number;
  drinking_window_end: number;
  review_date: Date;
  full_article_url: string;
  author: string;
  article_title: string;
}
export type VinousReviewsState = {
  loading: boolean;
  vinousReviewsByCellarWineId: {
    [cellarWineId: string]: VinousReview[]
  } | undefined;
}

const VinousReviewsStateContext = React.createContext<VinousReviewsState | undefined>(undefined);
const VinousReviewsDispatchContext = React.createContext<Dispatch<VinousReviewsState> | undefined>(undefined);

const VinousReviewsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useState<VinousReviewsState>({ loading: false, vinousReviewsByCellarWineId: undefined });

  return (
    <VinousReviewsStateContext.Provider value={state}>
      <VinousReviewsDispatchContext.Provider value={dispatch}>
        { children }
      </VinousReviewsDispatchContext.Provider>
    </VinousReviewsStateContext.Provider>
  );
};

const useVinousReviewsState = () => {
  const context = React.useContext(VinousReviewsStateContext);

  if (!context) { throw new Error("useVinousReviewsState context must be used within provider"); }
  return context;
};

const useVinousReviewsDispatch = () => {
  const context = React.useContext(VinousReviewsDispatchContext)

  if (!context) { throw new Error("useVinousReviewsDispatch context must be used within provider"); }
  return context;
};

const getVinousReviews = async (cellarSlug: string, setVinousReviews: Dispatch<VinousReviewsState>): Promise<void> => {
  try {
    const response = await getVinousReviewsForCellarSlugRequest(cellarSlug);
    setVinousReviews({ loading: false, ...response });
  } catch (err) {
    console.log("error fetching Vinous reviews");
  }
}

export { VinousReviewsProvider, useVinousReviewsState, useVinousReviewsDispatch, getVinousReviews };