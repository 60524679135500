import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { deleteCellarWine } from "../../context/cellarWinesContext/actions";
import { useCellarWinesContext } from "../../context/cellarWinesContext";
import { loadCellars, useCellarsContext } from "../../context/cellarsContext";

const useStyles = makeStyles({
  footer: {
    marginTop: 16,
  },
});

const DeleteWine: React.FC<{ wine: any }> = ({ wine }) => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const { dispatch: cellarDispatch } = useCellarsContext();
  const { dispatch: wineDispatch } = useCellarWinesContext();
  const classes = useStyles();
  const { id, cellarId } = wine;

  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <span style={{ fontWeight: 700 }}>Delete Wine</span>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you would like to delete this wine?
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.footer }}>
          <Button onClick={() => setModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => { 
              await deleteCellarWine(wineDispatch, id, cellarId)
              await loadCellars(cellarDispatch);
            }}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Button color="primary" onClick={() => setModalOpen(true)}>
        Delete Wine
      </Button>
    </>
  );
};

export default DeleteWine;
