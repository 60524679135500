import { Action, Dispatch } from '../../types/Action';
import { AggregatedTotals, Cellar, Cellars, Slugs } from '../../types/Cellar';
import { PriceDataMap } from '../../types/CellarWine';
import { LivexIndex, PriceRecordForIndex } from '../../types/Livex';

export type ICellarsContext = {
  state: CellarsState;
  dispatch: Dispatch;
};

export type CellarsState = {
  cellars?: Cellars;
  currencyChange: boolean;
  error?: any;
  livexIndices?: LivexIndex[];
  priceRecordsForIndices?: PriceRecordForIndex[];
  loading: boolean;
  message?: string;
  priceDataMap?: PriceDataMap;
  slugs?: Slugs;
};

const initialCellarsState: CellarsState = {
  currencyChange: false,
  livexIndices: undefined,
  priceRecordsForIndices: undefined,
  loading: false,
  message: undefined,
  priceDataMap: {},
  slugs: {},
};

export const cellarsReducer = (state: CellarsState, action: Action) => {
  switch (action.type) {
    case "loadingCellars": {
      return { ...state, loading: true };
    }
    case "setCellars": {
      return {
        ...state,
        loading: false,
        slugs: action.payload.cellars.reduce((obj: Slugs, cellar: Cellar) => {
          obj[cellar.slug] = cellar.id;
          return obj;
        }, {}),
        cellars: action.payload.cellars.reduce(
          (obj: Cellars, cellar: Cellar) => {
            obj[cellar.id] = cellar;
            return obj;
          },
          {}
        ),
      };
    }
    case "setHistoricDataForCellar": {
      const cellarsCopy = { ...state.cellars };
      const cellarId = action.payload.cellarId;
      const cellar = cellarsCopy[cellarId];
      cellar.historicData = action.payload.cellarHistoricData;

      return {
        ...state,
        cellars: {
          ...state.cellars,
          [cellarId]: cellar,
        }
      };
    }
    case "addCellar": {
      const { cellar } = action.payload;
      return {
        ...state,
        cellars: {
          ...state.cellars,
          [cellar.id]: cellar,
        },
        slugs: {
          ...state.slugs,
          [cellar.slug]: cellar.id,
        },
      };
    }
    case "deleteCellar": {
      const { cellar } = action.payload;
      return {
        ...state,
        cellars: {
          ...state.cellars,
          [cellar.id]: undefined,
        },
      };
    }
    case "currencyChange": {
      return {
        ...state,
        currencyChange: action.payload,
      };
    }
    case "setAggregatedTotals": {
      const { aggregatedTotals } = action.payload;
      const copy: Cellars = { ...state.cellars };

      for (let [key, value] of Object.entries(aggregatedTotals)) {
        const cellarId = copy[key] ? copy[key].id : null;
        if (cellarId) {
          const totals = value as AggregatedTotals;
          copy[cellarId].numBottles = totals.numBottles;
          copy[cellarId].numWines = totals.numWines;
          copy[cellarId].valueAtCost = totals.valueAtCost;
          copy[cellarId].valueAtCurrent = totals.valueAtCurrent;
          copy[cellarId].value30dAgo = totals.value30dAgo;
          copy[cellarId].value12mAgo = totals.value12mAgo;
          copy[cellarId].valueChange30d = totals.valueChange30d;
          copy[cellarId].valueChange12m = totals.valueChange12m;
          copy[cellarId].valueChangeVsCost = totals.valueChangeVsCost;
        }
      }

      if (copy && aggregatedTotals) {
        return {
          ...state,
          cellars: copy,
        };
      } else {
        return { ...state };
      }
    }
    case "setLivexIndices": {
      const { livexIndices } = action.payload;
      return {
        ...state,
        livexIndices: livexIndices,
      };
    }
    case "setPriceHistoryForIndices": {
      const { priceData } = action.payload;
      return {
        ...state,
        priceRecordsForIndices: priceData,
      };
    }
    case "setCellarsError": {
      return {
        ...state,
        priceRecordsForIndices: undefined,
        error: action.payload,
      };
    }
    case "setError": {
      return {
        ...state,
        error: action.payload,
      };
    }
    case "clearMessage": {
      return {
        ...state,
        error: undefined,
        message: undefined,
      };
    }
    case "clearCellarsState": {
      return initialCellarsState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
