export const bottleFormats = [
  "37.5",
  "50",
  "70",
  "75",
  "150",
  "300",
  "450",
  "500",
  "600",
  "900",
  "1200",
  "1500",
  "1800",
  "2700",
];

export const packedAsOptions = [
  "12",
  "11",
  "10",
  "9",
  "8",
  "7",
  "6",
  "5",
  "4",
  "3",
  "2",
  "1",
];
