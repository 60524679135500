export const moneyFormat = (
  value: number | string | Date,
  currencySymbol: string
) =>
  `${currencySymbol}${value.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })}`;


export const getSecondaryDataFormatter = (key: string, currencySymbol: string): () => string => {
  const secondaryDataFormatters: { [key: string]: () => string } = {
    auction_price: function(): string {
      return `<span style="color:${this.color}">Auction Price</span>: ` + 
      // @ts-expect-error
        `<b>${currencySymbol}${this.y.toLocaleString(undefined, { maximumFractionDigits: 2 })} / 12x75cl Cs</b>`
    },
    last_trade: function(): string {
      return  `<span style="color:${this.color}">Last Trade Price</span>: ` + 
      // @ts-expect-error
        `<b>${currencySymbol}${this.y.toLocaleString(undefined, { maximumFractionDigits: 2 })} / 12x75cl Cs</b>`
    },
    average_list_price: function(): string {
      return  `<span style="color:${this.color}">Average List Price</span>: ` + 
      // @ts-expect-error
        `<b>${currencySymbol}${this.y.toLocaleString(undefined, { maximumFractionDigits: 2 })} / 12x75cl Cs</b>`
    },
    average_list_qty: function(): string {
      return  `<span style="color:${this.color}">Average List Quantity</span>: ` + 
      // @ts-expect-error
        `<b>${this.y.toLocaleString(undefined, { maximumFractionDigits: 2 })} 12x75cl units</b>`
    },
    ex_negociant: function(): string {
      return  `<span style="color:${this.color}">Ex Negociant</span>: ` + 
      // @ts-expect-error
        `<b>${currencySymbol}${this.y.toLocaleString(undefined, { maximumFractionDigits: 2 })} / 12x75cl Cs</b>`
    },
    ex_chateau: function(): string {
      return  `<span style="color:${this.color}">Ex Chateau</span>: ` + 
      // @ts-expect-error
        `<b>${currencySymbol}${this.y.toLocaleString(undefined, { maximumFractionDigits: 2 })} / 12x75cl Cs</b>`
    },
    ex_london: function(): string {
      return  `<span style="color:${this.color}">Ex London</span>: ` + 
      // @ts-expect-error
        `<b>${currencySymbol}${this.y.toLocaleString(undefined, { maximumFractionDigits: 2 })} / 12x75cl Cs</b>`
    },
  }

  return secondaryDataFormatters[key];
};
