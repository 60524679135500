import { createMuiTheme } from "@material-ui/core";
import Colors from "./colors"
const theme = createMuiTheme({
    palette: {
        primary: {
            light: Colors.vinousRed,
            main: Colors.vinousRed,
            dark: Colors.vinousRed,
            contrastText: "#FFF",
        },
        secondary: {
            light: "#595",
            main: "#595",
            dark: "#595",
            contrastText: "#FFF"
        },
    },
    typography: {
        h5: {
          fontSize: "36px",
          fontFamily: "'Markazi Text', serif;"        
        },
        h6: {
          fontSize: "28px",
          fontFamily: "'Markazi Text', serif;",
        }
    },
    overrides: {
        MuiTableCell: {
            sizeSmall: {
              padding: "2px"
            }
    }
}
});

export default theme
