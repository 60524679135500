import React from "react";

import { Document } from "@react-pdf/renderer";
import { Cellar } from "../../types/Cellar";
import { CellarWine } from "../../types/CellarWine";
import ReportPage from "./reportPage";

export const CellarReport = (
  cellar: Cellar,
  cellarWines: CellarWine[],
  today: string,
  currency: string
) => (
  <Document
    author="report@vinous.com"
    keywords="vinous, cellar, report"
    subject="Cellar Report"
    title="Cellar Report"
  >
    <ReportPage
      cellar={cellar}
      cellarWines={cellarWines}
      today={today}
      currency={currency}
    />
  </Document>
);
