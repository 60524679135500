import React from "react";
import { loadCellarWines } from "../../context/cellarWinesContext/actions";
import VisibilitySensor from "react-visibility-sensor";
import { useCellarWinesContext } from "../../context/cellarWinesContext";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  CircularProgress,
  Box,
  Button,
  Switch,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { useToggle } from "react-use";
import useStyles from "../util/styles";
import Title from "../title";
import {
  useVinousReviewsDispatch,
  useVinousReviewsState,
  getVinousReviews,
  VinousReview,
} from "../../context/vinousReviewsContext";
import { useAuth } from "../../context/authContext";
import { getCurrentUser, getOldPlan } from "../../context/authContext/actions";
import { Currency } from "../../context/authContext/reducer";
import { generateCellarCSV } from "../../lib/cellarRequests";
import CellarWineTableRow from "./CellarWineTableRow";

type props = {
  currency?: Currency;
  cellarSlug?: string;
  setFormDialogOpen: (t: boolean) => void;
  setUpgradeDialogOpen: (t: boolean) => void;
};

export type VinousReviewsProp = {
  loading: boolean;
  vinousReviews: VinousReview[];
};

export type CellarWineReviews = {
  review_id: string;
  tasting_date: string;
  tasting_note: string;
  score: string;
  cellar_wine_id: string;
}[];

const CellarWinesTable: React.FC<props> = ({
  currency,
  cellarSlug,
  setFormDialogOpen,
  setUpgradeDialogOpen,
}) => {
  const classes = useStyles();
  const {
    state: { isPremiumUser, isProUser, oldPlan, user },
    dispatch: authDispatch,
  } = useAuth();
  const vinousReviews = useVinousReviewsState();
  const vinousReviewsDispatch = useVinousReviewsDispatch();
  const [caseView, toggleCaseView] = useToggle(true);
  const [limit] = React.useState(100);
  const [offset, setOffset] = React.useState(0);
  const {
    state: { cellarWines, loadingWines },
    dispatch: wineDispatch,
  } = useCellarWinesContext();

  const headerColumns = [
    "Region",
    "Color",
    "Wine",
    "Size",
    "Bottles",
    caseView ? "Cost/Cs" : "Cost/Btl",
    caseView ? "Market Value/Cs" : "Market Value/Btl",
    "Total Cost",
    "Total Value",
    "Vs Cost",
    "12m",
    "Vinous Score",
    "Drinking Window",
  ];

  const [sortedField, setSortedField] = React.useState({
    name: "default",
    asc: false,
  });

  React.useEffect(() => {
    if (cellarSlug) getVinousReviews(cellarSlug, vinousReviewsDispatch);
  }, [cellarSlug, vinousReviewsDispatch]);

  React.useEffect(() => {
    if (!user) getCurrentUser(authDispatch);
    if (user && user.old_sub_id) getOldPlan(authDispatch, user.old_sub_id);
  }, [isProUser, authDispatch, user]);

  React.useEffect(() => {
    if(cellarSlug && (cellarWines && !cellarWines.length)) {
      loadCellarWines(wineDispatch, cellarSlug, limit, offset, "default", false, false);
    }
  }, [cellarSlug, cellarWines, limit, offset, wineDispatch]);

  const loadMore = () => {
    setOffset(offset + limit);
    loadCellarWines(wineDispatch, cellarSlug!, limit, offset + limit, sortedField.name, sortedField.asc, false);
  }

  const sortWinesBy = (text: string, ascending: boolean) => {
    switch(text) {
      case "Vinous Score":
        cellarWines?.sort((left, right) => {
          if(vinousReviews && vinousReviews.vinousReviewsByCellarWineId) {
            const leftReview = vinousReviews.vinousReviewsByCellarWineId[left.id]
            const rightReview = vinousReviews?.vinousReviewsByCellarWineId[right.id]
            
            const leftScore = parseInt(leftReview ? leftReview[0]?.score_raw : "0")
            const rightScore = parseInt(rightReview ? rightReview[0]?.score_raw : "0")

            return leftScore > rightScore ? 0 : -1;
          }

          return -1;
        })
        break;
      case "Drinking Window":
        cellarWines?.sort((left, right) => {
          if(vinousReviews && vinousReviews.vinousReviewsByCellarWineId) {
            const leftReview = vinousReviews.vinousReviewsByCellarWineId[left.id]
            const rightReview = vinousReviews?.vinousReviewsByCellarWineId[right.id]
            
            const leftWindow = leftReview ? leftReview[0]?.drinking_window_begin : "0"
            const rightWindow = rightReview ? rightReview[0]?.drinking_window_begin : "0"

            return leftWindow > rightWindow ? 0 : -1;
          }

          return -1;
        })
        break;
      case "Vs Cost":
        cellarWines?.sort((left, right) => {
          return left.performance.vsCost > right.performance.vsCost ? 0 : -1;
        })
        break;
      case "12m":
        cellarWines?.sort((left, right) => {
          return left.performance.perf12month > right.performance.perf12month ? 0 : -1;
        })
        break;
    }

    if(ascending) cellarWines?.reverse()
  }

  const CWHeadTableCell: React.FC<{ text: string }> = ({ text }) => {
    const classes = useStyles();
    // set width on wine column to prevent 10 line wine names
    const columnClass = (): string | undefined => {
      if (text === "Wine") {
        return classes.wineColumn;
      } else {
        return;
      }
    };
    return (
      <TableCell align="left" className={`${classes.cwHeadTableCell}`}>
        <Box className={columnClass()} flexDirection="row" display="flex">
          {sortedField.name === text &&
            (sortedField.asc ? <ArrowDropDown /> : <ArrowDropUp />)}
          <Typography
            className={classes.cwHeadTableCellText}
            onClick={() => {
                console.log(text);
                const localSortFields = ['Vinous Score', 'Drinking Window', 'Vs Cost', '12m'];
                setSortedField({
                  name: text,
                  asc: sortedField.name === text ? !sortedField.asc : false,
                })

                if(localSortFields.indexOf(text) !== -1) {
                  sortWinesBy(text, sortedField.name === text ? !sortedField.asc : false);
                } else {
                  setOffset(0);
                  loadCellarWines(wineDispatch, cellarSlug!, limit, 0, text, sortedField.name === text ? !sortedField.asc : false, true);
                }
              }
            }
          >
            {text}
          </Typography>
        </Box>
      </TableCell>
    );
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        style={{ padding: "8px 16px" }}
        className={`${classes.borderBottom} ${classes.marginZero}`}
      >
        <Title>Wines</Title>
        <Box
          display="flex"
          marginLeft="auto"
          width="100%"
          maxWidth="400px"
          justifyContent="space-between"
        >
          <Button
            color="primary"
            onClick={() => {
              if (
                isPremiumUser ||
                isProUser ||
                (oldPlan &&
                  oldPlan.renewsAt &&
                  Date.parse(oldPlan.renewsAt) > Date.now()) ||
                (cellarWines && cellarWines.length < 5)
              ) {
                // Allow 5 cellar wines for free users
                setFormDialogOpen(true);
              } else {
                // Show upgrade dialog otherwise
                setUpgradeDialogOpen(true);
              }
            }}
          >
            Add Wines
          </Button>
          <Button
            color="primary"
            onClick={() => {
              generateCellarCSV(cellarSlug!).then(response => {
                var page = window.open(response.link, "_blank");
                window.setTimeout(() => {
                  page?.close();
                }, 500);
              })
            }}
          >
            EXPORT CSV
          </Button>
          <Box display="flex" alignItems="center">
            Bottle
            <Switch
              color="primary"
              checked={caseView}
              onChange={toggleCaseView}
              name="Case View Switch"
            />
            Case
          </Box>
        </Box>
      </Box>
      {cellarWines && currency ? (
        <TableContainer
          component={Paper}
          className={`
          ${classes.borderRadiusNone},
          ${classes.boxShadowNone}
          `}
        >
          <Table size="small" aria-label="Cellar Wines Table">
            <TableHead style={{ borderColor: "red" }}>
              <TableRow>
                <TableCell className={classes.cwHeadTableCell} />
                {headerColumns.map((c) => (
                  <CWHeadTableCell key={c} text={c} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {cellarWines.map(
                  (cellarWine, idx) =>
                    cellarWine && (
                      <CellarWineTableRow
                        cellarWine={cellarWine}
                        currencySymbol={currency.symbol}
                        key={idx}
                        caseView={caseView}
                        vinousReviews={{
                          loading: vinousReviews.loading,
                          vinousReviews:
                            vinousReviews.vinousReviewsByCellarWineId &&
                            cellarWine &&
                            vinousReviews.vinousReviewsByCellarWineId[
                              cellarWine.id
                            ]
                              ? vinousReviews.vinousReviewsByCellarWineId[
                                  cellarWine.id
                                ]
                              : [],
                        }}
                      />
                    )
                )}
            </TableBody>
          </Table>

          {
            (cellarWines.length % limit === 0) &&
            <VisibilitySensor 
              intervalDelay={50}
              scrollCheck={true}
              scrollDelay={10}
              onChange={(e) => {
                if(!loadingWines && cellarWines.length && e) {
                  loadMore()
                }
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  loadMore()
                }}
              >
                { loadingWines ? "Loading..." : "Load More" }
              </Button>
            </VisibilitySensor>
          }
        </TableContainer>
      ) : (
        <div className={classes.centerContainer}>
          <CircularProgress style={{ margin: 16 }} />
        </div>
      )}
    </>
  );
};

export default CellarWinesTable;
