import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { Cellar } from "../../types/Cellar";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DuplicateIcon from "@material-ui/icons/FileCopy";
import ChevronRight from "@material-ui/icons/ChevronRight";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import { useCellarsContext } from "../../context/cellarsContext";
import { deleteCellar, duplicateCellar } from "../../context/cellarsContext";
import { Button, makeStyles } from "@material-ui/core";
import { useAuth } from "../../context/authContext";
import { getActiveSubscription } from "../../context/authContext/actions";
import { pdf } from "@react-pdf/renderer";
import { format } from "date-fns";
import { CellarReport } from "../cellarsReport/cellarReport";
import ConfirmDeleteCellarDialog from "../confirmDeleteCellarDialog";
import ConfirmDialog from "../confirmDialog";
import { getCellarWinesForCellar } from "../../lib/cellarRequests";
import { parseCellarWine } from "../../context/cellarWinesContext/reducer";
import { CellarWine } from "../../types/CellarWine"; 

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - ${240}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
}));

export default function Appbar(props: {
  pathName: string;
  title: String;
  cellar?: Cellar;
  open: boolean;
  handleDrawerOpen: () => void;
}) {
  const classes = useStyles();
  const history = useHistory();
  const {
    state: { loading, isPremiumUser, isProUser, oldPlan, user },
    dispatch: authDispatch,
  } = useAuth();
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [
    confirmDuplicateDialogOpen,
    setConfirmDuplicateDialogOpen,
  ] = React.useState(false);

  const {
    dispatch: cellarDispatch,
  } = useCellarsContext();

  React.useEffect(() => {
    if (!loading) {
      getActiveSubscription(authDispatch);
    }
  }, [loading, isPremiumUser, isProUser, authDispatch]);

  const handleDeleteCellar = async (params: { id: string; slug: string }) => {
    await deleteCellar(cellarDispatch, params);
    history.replace("/cellars");
  };

  const handleDuplicateCellar = async (cellar: Cellar) => {
    await duplicateCellar(cellarDispatch, cellar);
    history.replace("/cellars");
    window.location.reload(false);
  };

  const handleExportPdf = async () => {
    if (!props.cellar || !user) {
      return;
    }
    const today = format(new Date(), "dd MMMM yyyy");
    const cellarWines = await getCellarWinesForCellar(props.cellar.slug, 999999, 0, "", false);
    const blob = await pdf(
      CellarReport(
        props.cellar,
        cellarWines.cellarWines.map((cellarWine: CellarWine) => {
          return parseCellarWine(cellarWine);
        }),
        today,
        user.currency
      )
    ).toBlob();
    const blobUrl = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = blobUrl;
    tempLink.setAttribute(
      "download",
      `${today}_${props.cellar ? props.cellar.slug : "cellar"} Report.pdf`
    );
    tempLink.click();
    tempLink.remove();
  };

  const handleUpgrade = async (e: any) => {
    e.preventDefault();
    history.push("/select-plan");
  };

  return (
    <AppBar
      position="absolute"
      className={props.open ? classes.appBarShift : classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={props.handleDrawerOpen}
          className={props.open ? classes.menuButtonHidden : classes.menuButton}
        >
          <ChevronRight />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {props.title}
        </Typography>
        {isProUser !== undefined &&
          !isProUser &&
          !(
            oldPlan &&
            oldPlan.plan === "Pro" &&
            oldPlan.renewsAt !== undefined &&
            Date.parse(oldPlan.renewsAt) > Date.now()
          ) && (
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginRight: 16 }}
              onClick={(e) => {
                handleUpgrade(e);
              }}
            >
              Upgrade
            </Button>
          )}
        {props.cellar && props.pathName !== "editCellar" && (
          <div>
            <ConfirmDeleteCellarDialog
              cellar={props.cellar}
              open={confirmDialogOpen}
              setOpen={setConfirmDialogOpen}
              handleDeleteCellar={handleDeleteCellar}
            />
            <IconButton
              color="inherit"
              title={"Export PDF"}
              onClick={(e) => {
                handleExportPdf();
              }}
            >
              <PdfIcon />
            </IconButton>
            <IconButton
              color="inherit"
              href={`/cellars/${props.cellar.slug}/editCellar`}
              title={"Edit"}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="inherit"
              title={"Delete"}
              onClick={(e) => {
                setConfirmDialogOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              color="inherit"
              title={"Duplicate"}
              onClick={(e) => {
                setConfirmDuplicateDialogOpen(true);
              }}
            >
              <DuplicateIcon />
            </IconButton>
            <ConfirmDialog
              title="Duplicate Cellar?"
              open={confirmDuplicateDialogOpen}
              setOpen={setConfirmDuplicateDialogOpen}
              onConfirm={() => handleDuplicateCellar(props.cellar!)}
            >
              {`Are you sure you want to duplicate cellar ${props.cellar?.name}?`}
            </ConfirmDialog>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
