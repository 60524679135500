const Colors = {
    vinousRed: '#630d0d', //'#6d0e0e',
    white: '#FFFFFF',
    lightestGrey: '#EEEEEE',
    lightGrey: 'rgba(0,0,0,0.25)',
    mediumGrey: '#FAFAFA',
    stagingGrey: '#CCCCCC',
    grey: '#4A4A4A',
};

export default Colors
