import React from "react";
import { useHistory, useParams } from "react-router";
import {
  Button,
  List,
  ListItem,
  TextField,
  Grid,
  Paper,
  Container,
} from "@material-ui/core";
import {
  Cellar as TCellar,
  CellarParams,
} from "../../types/Cellar";
import useStyles from "../../components/util/styles";
import { LayoutWrapper } from "../../components/layoutWrapper";
import {
  useCellarsContext,
  loadCellars,
  editCellar,
} from "../../context/cellarsContext";
import cellarImg from "../../images/cellar1.jpg";
import ImageUpload from "../../components/imageUpload";

const initialState = {
  id: "",
  slug: "",
  name: "",
  description: "",
  image_url: "",
};

export default function EditCellar() {
  const classes = useStyles();
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();
  const {
    state: { cellars, loading, slugs },
    dispatch,
  } = useCellarsContext();
  const cellarId = slug && slugs ? slugs[slug] : undefined;
  const [form, setForm] = React.useState<CellarParams>(initialState);
  const [cellar, setCellar] = React.useState<TCellar | undefined>(
    cellarId && cellars ? cellars[cellarId] : undefined
  );

  React.useEffect(() => {
    if (!loading && !cellars && !(cellars && slug && cellars[slug])) {
      loadCellars(dispatch);
      return;
    }
    if (cellars && cellarId && cellars[cellarId]) {
      const cellarData = cellars[cellarId];
      const { id, slug, name, description, image_url } = cellarData;
      setCellar(cellarData);
      setForm((prevState: CellarParams) => {
        return {
          ...prevState,
          id: id ? id : "",
          slug: slug ? slug : "",
          name: name ? name : "",
          description: description ? description : "",
          image_url: image_url ? image_url : prevState.image_url,
        };
      });
    }
  }, [loading, cellars, slug, dispatch, cellarId]);

  const handleEditCellar = async () => {
    await editCellar(dispatch, form);
    await loadCellars(dispatch);
    history.goBack();
  };

  return (
    <div className="EditCellar">
      <LayoutWrapper pathName="editCellar" cellar={cellar}>
        <Container maxWidth="lg" className={classes.container}>
          <Paper className={classes.paper}>
            {form.id && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItem>
                      <img
                        alt="Current Cellar"
                        style={{
                          margin: 16,
                          maxHeight: 160,
                          maxWidth: 160,
                        }}
                        src={form.image_url !== "" ? form.image_url : cellarImg}
                      />
                    </ListItem>
                    <ListItem>
                      <ImageUpload
                        id={form.id}
                        setImageUrl={(imageUrl) => {
                          setForm({
                            ...form,
                            image_url: imageUrl,
                          });
                        }}
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                        label={"Name: "}
                        style={{
                          marginLeft: 16,
                          marginRight: 16,
                          maxWidth: 320,
                        }}
                        variant={"outlined"}
                        value={form.name}
                        size={"small"}
                        placeholder="Cellar name"
                        onChange={(e) => {
                          setForm({
                            ...form,
                            name: (e.target.value as unknown) as string,
                          });
                        }}
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                        label={"Description: "}
                        multiline={true}
                        style={{
                          marginLeft: 16,
                          marginRight: 16,
                          marginTop: 8,
                          maxWidth: 320,
                        }}
                        variant={"outlined"}
                        value={form.description}
                        size={"small"}
                        placeholder="Cellar description"
                        onChange={(e) => {
                          setForm({
                            ...form,
                            description: (e.target.value as unknown) as string,
                          });
                        }}
                      />
                    </ListItem>
                  </List>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => handleEditCellar()}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Container>
      </LayoutWrapper>
    </div>
  );
}
