import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CreditCard from "@material-ui/icons/CreditCard";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  Select,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Paper,
  Container,
  Grid,
} from "@material-ui/core";
import { format } from "date-fns";
import { useAuth } from "../../context/authContext";
import {
  confirmPasswordError,
  editCurrentUser,
  getActiveSubscription,
  getCurrentUser,
  getOldPlan,
  getPaymentMethods,
  newPasswordError,
  validPassword,
} from "../../context/authContext/actions";
import CustomSnackbar from "../../components/snackbar";
import { currencies } from "../../constants";
import { currencyChanged } from "../../context/cellarsContext/actions";
import { useCellarsContext } from "../../context/cellarsContext";
import useStyles from "../../components/util/styles";
import { LayoutWrapper } from "../../components/layoutWrapper";
import delectableLogo from "../../images/delectable_logo.png";
import ConfirmCancelSubscriptionDialog from "../../components/confirmCancelSubscriptionDialog";
import { cancelSubscriptionRequest } from "../../lib/subscriptionRequests";

const roleOptions = [
  "Enthusiast",
  "Retailer",
  "Restaurant",
  "Winemaker",
  "Distributor",
  "Importer",
  "Broker",
  "Auction House",
  "Media",
];

export type SettingsFormState = {
  email?: string;
  password: string;
  newPassword: string;
  newPasswordError: string;
  confirmNewPassword: string;
  confirmNewPasswordError: string;
  firstName: string;
  lastName?: string;
  role: string;
  street_address?: string;
  street_address_line2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  currency: string;
};

export type ActivePlan = {
  symbol: string;
  plan: string;
  price: string;
  renewsAt?: number;
  canceledAt?: number;
};

const Settings: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    state: {
      loading,
      user,
      oldPlan,
      isPremiumUser,
      isProUser,
      paymentCurrency,
      paymentMethods,
      renewsAt,
      canceledAt,
      isOnFreeTrialUntil,
      planPrice,
      planInterval,
    },
    dispatch: authDispatch,
  } = useAuth();
  const { dispatch: cellarDispatch } = useCellarsContext();
  const [currencyChange, setCurrencyChange] = React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [activePlan, setActivePlan] = useState<ActivePlan>({
    symbol: "$",
    plan: "Basic",
    price: "0",
    renewsAt: undefined,
    canceledAt: undefined,
  });
  const [settingsForm, updateSettingsForm] = React.useState<SettingsFormState>({
    email: "",
    password: "",
    newPassword: "",
    newPasswordError: "",
    confirmNewPassword: "",
    confirmNewPasswordError: "",
    firstName: "",
    lastName: "",
    role: "",
    street_address: "",
    street_address_line2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    currency: "USD",
  });

  React.useEffect(() => {
    if (!loading && !user) {
      getCurrentUser(authDispatch);
    } else if (user) {
      updateSettingsForm((prevState) => {
        return {
          ...prevState,
          email: user.email ? user.email : "",
          firstName: user.name ? user.name : "",
          lastName: user.last_name ? user.last_name : "",
          role: user.role ? user.role : "",
          street_address: user.street_address ? user.street_address : "",
          street_address_line2: user.street_address_line2
            ? user.street_address_line2
            : "",
          city: user.city ? user.city : "",
          state: user.state ? user.state : "",
          zip: user.zip ? user.zip : "",
          country: user.country ? user.country : "",
          currency: user.currency,
        };
      });
    }
  }, [loading, user, authDispatch]);

  React.useEffect(() => {
    if (!paymentMethods.length) {
      getPaymentMethods(authDispatch);
    }
  }, [authDispatch, paymentMethods.length]);

  React.useEffect(() => {
    const now = Date.now();
    if (!oldPlan && user && user.old_sub_id) {
      getOldPlan(authDispatch, user.old_sub_id);
    }
    if (isPremiumUser === undefined) {
      getActiveSubscription(authDispatch);
    }
    if (oldPlan) {
      const currency = currencies.find((c) => c.value === oldPlan.currency);
      setActivePlan((prevState) => {
        if (oldPlan && oldPlan.renewsAt && Date.parse(oldPlan.renewsAt) > now) {
          return {
            symbol: currency ? currency.symbol : prevState.symbol,
            plan: oldPlan.plan,
            price: oldPlan.price,
            renewsAt: Date.parse(oldPlan.renewsAt),
            canceledAt: undefined,
          };
        }
        return prevState;
      });
    }
    if (
      !oldPlan ||
      !oldPlan.renewsAt ||
      Date.parse(oldPlan.renewsAt) <= now ||
      (Date.parse(oldPlan.renewsAt) > now && (isPremiumUser || isProUser))
    ) {

      const currency = currencies.find((c) => c.value === paymentCurrency);
      const plan = isProUser ? "Pro" : isPremiumUser ? "Premium" : "Basic";
      const price = planPrice ? (planPrice / 100 ).toString() : "";

      setActivePlan((prevState) => {
        return {
          symbol: currency ? currency.symbol : prevState.symbol,
          plan,
          price,
          renewsAt,
          canceledAt,
        };
      });
    }
  }, [
    loading,
    isPremiumUser,
    isProUser,
    oldPlan,
    authDispatch,
    paymentCurrency,
    user,
    renewsAt,
    canceledAt,
    isOnFreeTrialUntil,
    planPrice,
    planInterval,
  ]);

  const changingPassword = () => {
    return (
      settingsForm.newPassword !== "" ||
      settingsForm.confirmNewPassword !== "" ||
      settingsForm.password !== ""
    );
  };

  const saveSettings = async (e: any) => {
    e.preventDefault();
    if (user) {
      const userData = { id: user.id, ...settingsForm };
      if (changingPassword()) {
        // User is trying to change the password, validate
        validPassword(settingsForm.newPassword) &&
          validPassword(settingsForm.confirmNewPassword) &&
          settingsForm.password !== settingsForm.newPassword &&
          settingsForm.newPassword === settingsForm.confirmNewPassword &&
          (await editCurrentUser(userData, authDispatch));

        updateSettingsForm({
          ...settingsForm,
          newPasswordError: newPasswordError(
            settingsForm.password,
            settingsForm.newPassword
          ),
          confirmNewPasswordError: confirmPasswordError(
            settingsForm.newPassword,
            settingsForm.confirmNewPassword
          ),
        });
      } else {
        // Skip validation otherwise
        await editCurrentUser(userData, authDispatch);
        if (currencyChange) {
          await currencyChanged(cellarDispatch, true);
        }
      }
    }
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    updateSettingsForm({
      ...settingsForm,
      role: e.target.value,
    });
  };

  const handleCancelSubscription = async () => {
    await cancelSubscriptionRequest();
    setConfirmDialogOpen(false);
    window.location.reload();
  };

  return (
    <LayoutWrapper pathName="settings">
      <Container maxWidth="lg" className={classes.container}>
        <CustomSnackbar />
        <ConfirmCancelSubscriptionDialog
          planDescription={`You are currently subscribed to plan: Cellar Watch ${activePlan.plan} (${activePlan.symbol}${activePlan.price}/${planInterval})`}
          open={confirmDialogOpen}
          setOpen={setConfirmDialogOpen}
          handleCancelSubscription={handleCancelSubscription}
        />
        <Paper className={classes.paper}>
          <form className={classes.form} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <List>
                  <ListItem>
                    <Typography component="h1" variant="h5">
                      Profile
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      autoFocus
                      variant="outlined"
                      disabled
                      id="email"
                      label="Email Address"
                      name="email"
                      value={settingsForm.email}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="fname"
                      name="firstName"
                      id="firstName"
                      label="First Name"
                      value={settingsForm.firstName}
                      onChange={(e) =>
                        updateSettingsForm({
                          ...settingsForm,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="lname"
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      value={settingsForm.lastName}
                      onChange={(e) =>
                        updateSettingsForm({
                          ...settingsForm,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem style={{ marginBottom: 4 }}>
                    <FormControl
                      className={classes.settingsField}
                      required
                      variant={"filled"}
                      size="medium"
                    >
                      <InputLabel shrink htmlFor="age-native-label-placeholder">
                        I'm a
                      </InputLabel>
                      <Select
                        native
                        value={settingsForm.role}
                        onChange={(e) => handleChange(e)}
                      >
                        {roleOptions.map((option: string) => (
                          <option value={option} key={option}>
                            {option}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="streetAddress"
                      id="streetAddress"
                      label="Street Address"
                      name="streetAddress"
                      value={settingsForm.street_address}
                      onChange={(e) =>
                        updateSettingsForm({
                          ...settingsForm,
                          street_address: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      autoComplete="addressContd"
                      id="addressContd"
                      label="Address (contd.)"
                      name="addressContd"
                      value={settingsForm.street_address_line2}
                      onChange={(e) =>
                        updateSettingsForm({
                          ...settingsForm,
                          street_address_line2: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="city"
                      id="city"
                      label="City"
                      name="city"
                      value={settingsForm.city}
                      onChange={(e) =>
                        updateSettingsForm({
                          ...settingsForm,
                          city: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="state"
                      id="state"
                      label="State/Province/Region"
                      name="state"
                      value={settingsForm.state}
                      onChange={(e) =>
                        updateSettingsForm({
                          ...settingsForm,
                          state: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="zip"
                      id="zip"
                      label="Zip/Postal Code"
                      name="zip"
                      value={settingsForm.zip}
                      onChange={(e) =>
                        updateSettingsForm({
                          ...settingsForm,
                          zip: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="country"
                      id="country"
                      label="Country"
                      name="country"
                      value={settingsForm.country}
                      onChange={(e) =>
                        updateSettingsForm({
                          ...settingsForm,
                          country: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <List>
                  <ListItem>
                    <Typography component="h1" variant="h5">
                      Subscription
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography style={{ color: "#b7b7c7" }}>
                      {`You are currently subscribed to plan: Cellar Watch ${activePlan.plan} (${activePlan.symbol}${activePlan.price}/${planInterval})`}
                    </Typography>
                  </ListItem>
                  {activePlan.renewsAt && !isOnFreeTrialUntil && (
                    <ListItem>
                      <Typography style={{ color: "#b7b7c7" }}>
                        {activePlan.canceledAt
                          ? `Your subscription will be canceled automatically on ${format(
                              activePlan.canceledAt,
                              "MMMM do, yyyy"
                            )}`
                          : `Your subscription will automatically renew on ${format(
                              activePlan.renewsAt,
                              "MMMM do, yyyy"
                            )}`}
                      </Typography>
                    </ListItem>
                  )}
                  {!!isOnFreeTrialUntil && (
                    <ListItem>
                      <Typography style={{ color: "#b7b7c7" }}>
                          `You are on a free trial until {format(
                              isOnFreeTrialUntil,
                              "MMMM do, yyyy"
                            )}`
                      </Typography>
                    </ListItem>
                  )}
                  <ListItem>
                    {/* Give option to update credit card at any time. */}
                    <Button
                      startIcon={paymentMethods.length ? <CreditCard /> : null}
                      variant={paymentMethods.length ? "outlined" : "contained"}
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault()
                        history.push("/set-payment-method");
                      }}
                      href="#"
                    >
                      {paymentMethods.length ? "Update Payment Method" : "Add Credit Card"}
                    </Button>
                  </ListItem>
                  {(!!isProUser || !!isPremiumUser) && !canceledAt && (
                    <ListItem>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {
                          setConfirmDialogOpen(true);
                        }}
                      >
                        {"Cancel at Period End"}
                      </Button>
                    </ListItem>
                  )}
                  <ListItem>
                    <Typography component="h1" variant="h5">
                      Password
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="current-password"
                      name="current-password"
                      label="Current Password"
                      type="password"
                      id="current-password"
                      value={settingsForm.password}
                      onChange={(e) =>
                        updateSettingsForm({
                          ...settingsForm,
                          password: e.target.value,
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="new-password"
                      name="new-password"
                      label="New Password"
                      type="password"
                      id="new-password"
                      value={settingsForm.newPassword}
                      error={settingsForm.newPasswordError.length > 0}
                      helperText={settingsForm.newPasswordError}
                      onChange={(e) =>
                        updateSettingsForm({
                          ...settingsForm,
                          newPassword: e.target.value,
                          newPasswordError: newPasswordError(
                            settingsForm.password,
                            e.target.value
                          ),
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      className={classes.settingsField}
                      variant="outlined"
                      required
                      autoComplete="new-password-confirmation"
                      name="new-password-confirmation"
                      label="Confirm New Password"
                      type="password"
                      id="new-password-confirmation"
                      value={settingsForm.confirmNewPassword}
                      error={settingsForm.confirmNewPasswordError.length > 0}
                      helperText={settingsForm.confirmNewPasswordError}
                      onChange={(e) =>
                        updateSettingsForm({
                          ...settingsForm,
                          confirmNewPassword: e.target.value,
                          confirmNewPasswordError: confirmPasswordError(
                            settingsForm.newPassword,
                            e.target.value
                          ),
                        })
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <List>
                  <ListItem>
                    <Typography component="h1" variant="h5">
                      Currency
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <RadioGroup
                      aria-label="currency"
                      name="currency"
                      value={settingsForm.currency}
                      onChange={(e) => {
                        setCurrencyChange(true);
                        updateSettingsForm({
                          ...settingsForm,
                          currency: e.target.value,
                        });
                      }}
                    >
                      {currencies.map(({ value, label }) => (
                        <FormControlLabel
                          value={value}
                          control={<Radio color={"primary"} />}
                          label={label}
                          key={label}
                        />
                      ))}
                    </RadioGroup>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Box mt={5} style={{ display: "flex", justifyContent: "center" }}>
              <Button
                startIcon={
                  <img
                    style={{ width: 32, height: 32 }}
                    src={delectableLogo}
                    alt="Delectable Logo"
                  />
                }
                type="submit"
                variant="outlined"
                className={classes.settingsField}
                href="#"
              >
                My Delectable Account
              </Button>
            </Box>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => saveSettings(e)}
                href="#"
              >
                Save
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </LayoutWrapper>
  );
};

export default Settings;
