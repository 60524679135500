import React from "react";
import {
  Typography,
  Container,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { LayoutWrapper } from "../../components/layoutWrapper";
import { useToggle } from "react-use";
import { useCellarsContext } from "../../context/cellarsContext";
import { useAuth } from "../../context/authContext";
import UpgradeDialog from "../../components/upgradeDialog";
import StripeElementsWrapper from "../../components/stripeElementsWrapper";
import {
  getActiveSubscription,
  getCurrentUser,
  getOldPlan,
  getWineSearches,
  updateWineSearches,
} from "../../context/authContext/actions";
import SearchWineForm from "../../components/searchWineForm";
import CompareWinesTable from "../../components/compareWinesTable";
import { currencies } from "../../constants";
import { getPriceDataList } from "../../lib/livexRequests";
import { numberWithCommas } from "../../components/util/format";
import VintageSelect, { HistoricData } from "./vintageSelect";
import useStyles from "../../components/util/styles";
import Chart from "../../components/chart";
import _ from "lodash";
import Colors from "../../components/util/colors";

export type PriceHistoryRecords = {
  name: string;
  lwin: string;
  data: PriceHistoryRecord[];
};

export type PriceHistoryRecord = {
  vintage: string;
  price: string;
  lwin: string;
};

const useStylesLocal = makeStyles(() => ({
  pricingContainer: {
    marginTop: "60px",
  },
  chartContainer: {
    marginTop: "30px",
  },
  priceDataFrequencyNotice: {
    color: Colors.vinousRed
  }
}));

const PriceHistory: React.FC = () => {
  // merge styles from local with generic
  const classes = Object.assign(useStylesLocal(), useStyles())
  const [chartLoading, toggleChartLoading] = useToggle(false);
  const [pageLoading, setPageLoading] = React.useState(false);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = React.useState(false);
  const {
    state: { isProUser, isPremiumUser, user, oldPlan, wineSearches },
    dispatch: authDispatch,
  } = useAuth();
  const {
    state: { loading },
    dispatch,
  } = useCellarsContext();
  const [historicData, setHistoricData] = React.useState<HistoricData[]>();
  const [currency, setCurrency] = React.useState({
    label: "",
    value: "",
    symbol: "",
  });
  const [winesToCompare, setWinesToCompare] = React.useState<
    PriceHistoryRecords[]
  >([]);

  const addWine = async (wineForm: any) => {
    const { lwin, display_name } = wineForm;
    const winePrices = await getPriceDataList(lwin.substring(0,7), currency.value);

    const priceHistoryRecords = winePrices.prices.map((priceData: any) => {
      const { lwin, dataDetail } = priceData;
      const tempVintage = lwin.substring(7);
      const vintage = tempVintage !== "1000" ? tempVintage : "No Vintage";
      const price = dataDetail[0] && dataDetail[0].priceData
        ? numberWithCommas(dataDetail[0].priceData)
        : "-";
      return { vintage, price, lwin };
    });

    setWinesToCompare([
      ...winesToCompare,
      { name: display_name, lwin, data: priceHistoryRecords },
    ]);
    await updateWineSearches(authDispatch);
  };

  const lwinGrouped = _.chain(historicData).groupBy("lwin11").map((value, key) => {
    const data = value.map((d) => { return [d.date_string, Number(d.value)] as [string, number]; });
    const vintage = key.substring(key.length - 4);
    const wine = winesToCompare?.find((w) => w.lwin === key);

    return {
      data,
      name: wine ? `${vintage} ${wine.name}` : `LWIN: ${key}`,
      formatter: function(): string {
        // @ts-expect-error
        let s =  `<span style="color:${this.color}">${this.series.name}</span>: ` + 
        // @ts-expect-error
          `<b>${(currency && currency.symbol) || "$"}${this.y.toLocaleString(undefined, { maximumFractionDigits: 2 })}</b>`
        
        // @ts-expect-error
        if (this.change) {
          // @ts-expect-error
          s = s + ` (${this.change.toLocaleString(undefined, { maximumFractionDigits: 2 })}%)<br/>`;
        }
  
        return s;
      },
    }
  }).value();

  React.useEffect(() => {
    if (!user) getCurrentUser(authDispatch);
    if (isProUser === undefined) getActiveSubscription(authDispatch);
    if (!oldPlan && user && user.old_sub_id)
      getOldPlan(authDispatch, user.old_sub_id);
    if (user) {
      getWineSearches(authDispatch);
      const userCurrency = currencies.filter(
        (c) => c.value === user.currency
      )[0];
      setCurrency(userCurrency);
    }
  }, [isProUser, isPremiumUser, dispatch, authDispatch, user, oldPlan]);

  return (
    <LayoutWrapper pathName="priceHistory" loading={loading}>
      <StripeElementsWrapper>
        <UpgradeDialog
          open={upgradeDialogOpen}
          setOpen={setUpgradeDialogOpen}
        />
      </StripeElementsWrapper>
      <Container
        maxWidth={false}
        className={`${classes.container} ${classes.marginZero}`}
        style={{ paddingTop: "32px", paddingBottom: "50px" }}
      >
        <Typography component="h1" variant="body1" align="left" style={{paddingBottom: "12px"}} gutterBottom>
          Search a wine to get price history. Then search another to compare.
        </Typography>
        <SearchWineForm
          addWine={addWine}
          setPageLoading={setPageLoading}
          setUpgradeDialogOpen={setUpgradeDialogOpen}
          wineSearches={wineSearches}
          isProUser={isProUser}
          isPremiumUser={isPremiumUser}
          oldPlan={oldPlan}
        />

        {!pageLoading ? (
          !!Object.keys(winesToCompare).length && (
            <>
              <div className={classes.chartContainer}>
                <Typography component="h4" variant="h5" gutterBottom>
                  Historic Wine Data
                </Typography>
                <VintageSelect
                  winesToCompare={winesToCompare}
                  historicData={historicData}
                  setHistoricData={setHistoricData}
                  toggleChartLoading={toggleChartLoading}
                />
                {historicData && (
                  <Chart
                    historicData={lwinGrouped}
                    loading={chartLoading}
                    includeLivexIndices
                    title={"Value over Time"}
                  />
                )}
              </div>
              <div className={classes.pricingContainer}>
                <Typography component="h4" variant="h5" gutterBottom>
                  Current Value by Vintage
                </Typography>
                <CompareWinesTable
                  winesToCompare={winesToCompare}
                  setWinesToCompare={setWinesToCompare}
                  currencySymbol={currency ? currency.symbol : "$"}
                />
                <div className={classes.priceDataFrequencyNotice}>
                  <p>Liv-ex Market Values updated daily</p>
                </div>
              </div>
            </>
          )
        ) : (
          <div className={classes.loadingContainer}>
            <CircularProgress style={{ margin: 32 }} />
          </div>
        )}
      </Container>
    </LayoutWrapper>
  );
};

export default PriceHistory;
