import React from "react";
import {
  Avatar,
  Button,
  Checkbox,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Link,
  Typography,
  Container,
  FormControlLabel,
  ThemeProvider,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Copyright from "../../components/copyright";
import Header from "../../components/header";
import useStyles from "../../components/util/styles";
import { useAuth, login } from "../../context/authContext";
import { RouteComponentProps } from "react-router-dom";
import CustomSnackbar from "../../components/snackbar";
import {
  emailError,
  passwordError,
  validEmail,
  validPassword,
} from "../../context/authContext/actions";
import theme from "../../components/util/theme";

export type LoginFormState = {
  email: string;
  password: string;
  emailError: string;
  passwordError: string;
};

export const Login = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  const { dispatch } = useAuth();

  const [loginForm, setLoginForm] = React.useState<LoginFormState>({
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
  });

  const handleLogin = async (e: any, dispatch: any) => {
    e.preventDefault();

    validEmail(loginForm.email) &&
      validPassword(loginForm.password) &&
      login(dispatch, loginForm.email, loginForm.password).then(() =>
        history.push("/cellars")
      );

    setLoginForm({
      ...loginForm,
      emailError: emailError(loginForm.email),
      passwordError: passwordError(loginForm.password),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.rootLogin}>
        <Header />
        <CustomSnackbar />
        <Container maxWidth="md">
          <Grid
            item
            component={Paper}
            className={classes.paperLogin}
            elevation={6}
            square
          >
            <div className={classes.paperLogin}>
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.avatarContainer}>
                    <Avatar className={classes.avatar}>
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Sign in
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      error={loginForm.emailError.length > 0}
                      helperText={loginForm.emailError}
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      type="email"
                      value={loginForm.email}
                      onChange={(e) =>
                        setLoginForm({
                          ...loginForm,
                          email: e.target.value,
                          emailError: emailError(e.target.value),
                        })
                      }
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      error={loginForm.passwordError.length > 0}
                      helperText={loginForm.passwordError}
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={loginForm.password}
                      onChange={(e) =>
                        setLoginForm({
                          ...loginForm,
                          password: e.target.value,
                          passwordError: passwordError(e.target.value),
                        })
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={async (e) => handleLogin(e, dispatch)}
                    >
                      Sign In
                    </Button>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs style={{ margin: 12 }}>
                    <Link href="/forgotPassword">Forgot password?</Link>
                  </Grid>
                  <Grid
                    item
                    style={{
                      margin: 12,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Link href="/signup">
                      {"Don't have an account? Sign Up"}
                    </Link>
                    <Link href="/onboarding" style={{ marginTop: 4 }}>
                      {
                        "Existing CW user and first time on new site? Click here."
                      }
                    </Link>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </div>
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
