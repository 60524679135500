import React from "react";
import { CellarWine } from "../../types/CellarWine";
import {
  TableRow,
  TableCell,
  IconButton,
} from "@material-ui/core";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import {
  colorForPercentage,
  numberFormattedWithCurrency,
  formatToYear,
} from "../util/format";
import useStyles from "../util/styles";
import { parseScoreRawForDisplay } from "../wines/VinousReviews";
import {
  getCellarWineReviews,
} from "../../lib/cellarRequests";
import { VinousReviewsProp, CellarWineReviews } from "./index";
import CollapseContent from "./CollapseContent";

const CWTableCell: React.FC<{ style?: object }> = ({ children, style }) => (
  <TableCell style={style} align="left">
    {children}
  </TableCell>
);

const CellarWineTableRow: React.FC<{
  cellarWine: CellarWine;
  currencySymbol: string;
  vinousReviews?: VinousReviewsProp;
  caseView: boolean;
}> = ({ cellarWine, currencySymbol, vinousReviews, caseView }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const intitalReviews: CellarWineReviews = [];
  const [wineReviews, setWineReviews] = React.useState(intitalReviews);
  const {
    id,
    region,
    colour,
    vintage,
    displayName,
    bottleCount,
    bottleSize,
    initialCost,
    marketPrice,
    totalCost,
    totalValue,
    performance,
    drinkFrom,
    drinkTo,
    scoreFrom,
    scoreTo,
  } = cellarWine;

  const displayDrinkingWindow = () => {
    let window = "Not Available";
    if (drinkFrom && drinkFrom !== "-" && drinkTo && drinkTo !== "-") {
      window =
        typeof drinkFrom !== "number"
          ? `${formatToYear(drinkFrom)}-${formatToYear(drinkTo)}`
          : `${drinkFrom}-${drinkTo}`;
    } else {
      const drinkingWindow =
        vinousReviews &&
        vinousReviews.vinousReviews &&
        vinousReviews.vinousReviews.length > 0 &&
        vinousReviews.vinousReviews[0];
      if (
        drinkingWindow &&
        drinkingWindow.drinking_window_begin &&
        drinkingWindow.drinking_window_end
      ) {
        window = `${drinkingWindow.drinking_window_begin}-${drinkingWindow.drinking_window_end}`;
      }
    }
    return window;
  };

  const displayScore = () => {
    if (vinousReviews && vinousReviews.vinousReviews?.length > 0) {
      const latestVinousReview = vinousReviews.vinousReviews[0];

      if (latestVinousReview.score_raw) {
        return parseScoreRawForDisplay(
          latestVinousReview.score_raw
        );
      }
    }

    let score = "Not Available";
    if (scoreFrom && scoreFrom !== "-" && scoreTo && scoreTo !== "-") {
      score = `${scoreFrom}-${scoreTo}`;
    } else if (scoreFrom && scoreFrom !== "-") {
      score = scoreFrom;
    } else if (scoreTo && scoreTo !== "-") {
      score = scoreTo;
    }
    return score;
  };

  const handleClick = async () => {
    if (!open && !wineReviews.length) {
      const { wineReviews: wineReviewData } = await getCellarWineReviews(id);
      setWineReviews(wineReviewData);
    }
    setOpen(!open);
  };

  return cellarWine ? (
    <>
      <TableRow>
        <TableCell className={classes.cwTableCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleClick}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <CWTableCell>{`${region}`}</CWTableCell>
        <CWTableCell>{`${colour}`}</CWTableCell>
        <CWTableCell>{`${
          vintage !== "No Vintage" && vintage !== '1000' ? vintage : "NV"
        } ${displayName}`}</CWTableCell>
        <CWTableCell>{`${parseInt(bottleSize).toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })}cl`}</CWTableCell>
        <CWTableCell>{bottleCount}</CWTableCell>
        <CWTableCell>
          {numberFormattedWithCurrency(
            currencySymbol,
            caseView ? initialCost : Math.round(initialCost / Math.round((900 / parseInt(bottleSize))))
          )}
        </CWTableCell>
        <CWTableCell>
          {numberFormattedWithCurrency(
            currencySymbol,
            caseView ? marketPrice : Math.round(marketPrice / Math.round((900 / parseInt(bottleSize))))
          )}
        </CWTableCell>
        <CWTableCell>
          {numberFormattedWithCurrency(currencySymbol, totalCost)}
        </CWTableCell>
        <CWTableCell>
          {numberFormattedWithCurrency(currencySymbol, totalValue)}
        </CWTableCell>
        <CWTableCell
          style={{
            color: colorForPercentage(performance ? performance.vsCost : false),
          }}
        >
          {performance &&
          performance.vsCost !== undefined &&
          performance.vsCost !== null
            ? `${performance.vsCost.toFixed(1).toLocaleString()}%`
            : "-"}
        </CWTableCell>
        <CWTableCell
          style={{
            color: colorForPercentage(
              performance ? performance.perf12month : false
            ),
          }}
        >
          {performance &&
          performance.perf12month !== undefined &&
          performance.perf12month !== null
            ? `${performance.perf12month.toFixed(1).toLocaleString()}%`
            : "-"}
        </CWTableCell>
        <CWTableCell>{displayScore()}</CWTableCell>
        <CWTableCell>{displayDrinkingWindow()}</CWTableCell>
      </TableRow>
      <CollapseContent
        cellarWine={cellarWine}
        wineReviews={wineReviews}
        currencySymbol={currencySymbol}
        setWineReviews={setWineReviews}
        open={open}
        vinousReviews={vinousReviews}
      />
    </>
  ) : (
    <p>Loading...</p>
  );
};

export default CellarWineTableRow;