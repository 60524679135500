import { get, post } from "./api";

export const getLwinSearchRequest = (query: string) => {
  return get("/livex/lwinSearch", { query });
};

export const getPriceDataList = (lwin: string, currency: string) => {
  return get("/livex/priceDataList", { lwin, currency });
};

export const getHistoricDataForLwins = (lwins: string[]) => {
  return post(`/livex/lwinHistoricData`, { lwins });
};

export const getLivexIndicesPerformanceRequest = () => {
  return get("/livex/livexIndicesPerformance");
};

export const getPriceHistoryForIndices = (symbols: string) => {
  return get(`/livex/priceHistoryForIndex`, { symbols });
};
