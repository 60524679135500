export type Action =
  | { type: "setAuth"; payload: any }
  | { type: "setAuthError"; payload: any }
  | { type: "loadingAuth"; payload?: any }
  | { type: any; payload?: any };

export type Dispatch = (action: Action) => void;

export type AuthState = {
  token?: string;
  loading: boolean;
  user?: User;
  error?: string;
  message?: string;
  existingUserFirstName?: string;
  existingUserLastName?: string;
  existingUserEmail?: string;
  isPremiumUser?: boolean;
  isProUser?: boolean;
  paymentCurrency: string;
  renewsAt?: number;
  canceledAt?: number;
  oldPlan?: OldPlan;
  wineSearches: number;
  paymentMethods: any[];
  isOnFreeTrialUntil?: number;
  planPrice?: number;
  planInterval?: string;
};

export type IAuthContext = {
  state: AuthState;
  dispatch: Dispatch;
};

export type User = {
  id: string;
  customer_id: string;
  email?: string;
  name?: string;
  last_name: string;
  role: string;
  admin_role: string;
  street_address: string;
  street_address_line2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  currency: string;
  old_sub_id?: string;
};

export type Currency = {
  symbol: string;
  label: string;
  value: string;
};

export type OldPlan = {
  plan: string;
  currency: string;
  price: string;
  renewsAt?: string;
};

export const authReducerInitialState = {
  token: undefined,
  user: undefined,
  loading: false,
  error: undefined,
  message: undefined,
  existingUserFirstName: undefined,
  existingUserLastName: undefined,
  existingUserEmail: undefined,
  isPremiumUser: undefined,
  isProUser: undefined,
  paymentCurrency: "USD",
  renewsAt: undefined,
  canceledAt: undefined,
  oldPlan: undefined,
  wineSearches: 0,
  paymentMethods: [],
  isOnFreeTrialUntil: undefined,
  planPrice: undefined,
  planInterval: undefined,
};

export const authReducer = (state: AuthState, action: Action) => {
  switch (action.type) {
    case "loadingAuth": {
      return {
        ...state,
        message: undefined,
        loading: true,
      };
    }
    case "startOnboarding": {
      return {
        ...state,
        error: undefined,
        message: action.payload,
        existingUserFirstName: undefined,
        existingUserLastName: undefined,
        existingUserEmail: undefined,
        loading: false,
      };
    }
    case "setActiveSubscription": {
      return {
        ...state,
        loading: false,
        isPremiumUser: action.payload.isPremiumUser,
        isProUser: action.payload.isProUser,
        paymentCurrency: action.payload.paymentCurrency,
        renewsAt: action.payload.renewsAt,
        canceledAt: action.payload.canceledAt,
        isOnFreeTrialUntil: action.payload.isOnFreeTrialUntil,
        planPrice: action.payload.planPrice,
        planInterval: action.payload.planInterval,
      };
    }
    case "setPaymentMethods": {
      return {
        ...state,
        loading: false,
        paymentMethods: action.payload.paymentMethods,
      };
    }
    case "setOldPlan": {
      return {
        ...state,
        oldPlan: action.payload.oldPlan,
      };
    }
    case "setWineSearches": {
      return {
        ...state,
        wineSearches: action.payload.wineSearches,
      };
    }
    case "setAuthUser": {
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        paymentCurrency: action.payload.user.currency,
        error: undefined,
        existingUserFirstName: undefined,
        existingUserLastName: undefined,
        existingUserEmail: undefined,
        loading: false,
      };
    }
    case "setMessage": {
      return {
        ...state,
        message: action.payload.message,
      };
    }
    case "setAuthError": {
      return {
        ...state,
        error: action.payload,
        message: undefined,
        existingUserFirstName: undefined,
        existingUserLastName: undefined,
        existingUserEmail: undefined,
        loading: false,
      };
    }
    case "clearAuth": {
      return authReducerInitialState;
    }
    case "postingUser": {
      return {
        ...state,
        message: undefined,
        loading: true,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
