import React from "react";
import { cellarWinesReducer, ICellarWinesContext } from "./reducer";
import {
  loadCellarWines,
  createCellarWine,
  editCellarWine,
  deleteCellarWine,
  transferCellarWine,
} from "./actions";

const CellarWinesContext = React.createContext<ICellarWinesContext | undefined>(
  undefined
);

const CellarWinesProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(cellarWinesReducer, {
    loadingWines: false,
    cellarWines: [],
    disposedWines: []
  });

  const cellarsValue = { state, dispatch };

  return (
    <CellarWinesContext.Provider value={cellarsValue}>
      {children}
    </CellarWinesContext.Provider>
  );
};

const useCellarWinesContext = () => {
  const context = React.useContext(CellarWinesContext);

  if (!context) {
    throw new Error("useCellarWinesContext must be used within CellarWinesProvider");
  }
  return context;
};

export {
  CellarWinesProvider,
  useCellarWinesContext,
  loadCellarWines,
  createCellarWine,
  editCellarWine,
  deleteCellarWine,
  transferCellarWine,
};
