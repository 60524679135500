import withRoot from "./modules/withRoot";
// --- Post bootstrap -----
import React from "react";
import ProductContactUs from "./modules/views/ProductContactUs";
import AppFooter from "./modules/views/AppFooter";
import ProductHero from "./modules/views/ProductHero";
import ProductValues from "./modules/views/ProductValues";
import ProductCTA from "./modules/views/ProductCTA";
import Header from "../../components/header";
import ProductCategories from "./modules/views/ProductCategories";

function Index() {
  return (
    <React.Fragment>
      <Header />
      <ProductHero />
      <ProductValues />
      <ProductCategories />
      <ProductCTA />
      <ProductContactUs />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
