import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import analysisIcon from "../../../../images/ic_analysis.png";
import searchIcon from "../../../../images/ic_search.png";
import wineIcon from "../../../../images/ic_wine.png";

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(16),
    display: "flex",
    position: "relative",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 100,
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: "#F3F3F3",
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
  },
});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img className={classes.image} src={searchIcon} alt="" />
              <Typography align="center" variant="h6" className={classes.title}>
                REAL-TIME AND HISTORIC PRICES
              </Typography>
              <Typography
                align="center"
                variant="h5"
                style={{ color: "#F3F3F3" }}
              >
                {
                  "Search wines to see reviews from Vinous and exclusive access to trusted pricing data from Liv-ex."
                }
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img className={classes.image} src={wineIcon} alt="" />
              <Typography align="center" variant="h6" className={classes.title}>
                WINE CELLARS
              </Typography>
              <Typography
                align="center"
                variant="h5"
                style={{ color: "#F3F3F3" }}
              >
                {
                  "Create cellars to monitor your wine portfolios and track performance with valuation reports."
                }
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img className={classes.image} src={analysisIcon} alt="" />
              <Typography align="center" variant="h6" className={classes.title}>
                EXCLUSIVE INSIGHT
              </Typography>
              <Typography
                align="center"
                variant="h5"
                style={{ color: "#F3F3F3" }}
              >
                {
                  "Receive the latest analysis on the wine market and your wine portfolio valuation straight in your inbox."
                }
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
