import React from "react";
import { TextField, CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useToggle } from "react-use";
import { getLwinSearchRequest } from "../../lib/livexRequests";

type Option = {
  lwin?: string;
  displayName?: string;
};

type SearchWineFormProps = {
  addWine: (wineForm: any) => void;
  setPageLoading: Function;
  setUpgradeDialogOpen: Function;
  wineSearches: number;
  isProUser?: boolean;
  isPremiumUser?: boolean;
  oldPlan: any;
};

// TODO: Merge component with LwinSearchField Component

const SearchWineForm: React.FC<SearchWineFormProps> = ({
  addWine,
  setPageLoading,
  setUpgradeDialogOpen,
  wineSearches,
  isProUser,
  isPremiumUser,
  oldPlan,
}) => {
  const [value, setValue] = React.useState("");
  const [vintage, setVintage] = React.useState("");
  const [options, setOptions] = React.useState<Option[]>([]);
  const [open, toggleOpen] = useToggle(false);
  const [resultsLoading, toggleResultsLoading] = useToggle(false);

  const fetchOptions = async (value: string) => {
    const { results } = await getLwinSearchRequest(value);
    return results;
  };

  const getCentury = (value: string) => {
    if(value.length === 4) return value;
    const currentYear = new Date().getFullYear().toString().substr(-2);

    if(value <= currentYear) {
      return "20" + value;
    } else {
      return "19" + value;
    }
  }

  const parseVintage = (value: string) => {
    const regex = new RegExp(/(?:\d{4}|\d{2})/g);
    const results = value.match(regex);

    if(results && results.length > 0) {
      setVintage(getCentury(results[0]));
      setValue(value.replace(results[0], "").trim());
    } else {
      setValue(value.trim());
    }
  }

  React.useEffect(() => {
    let active = true;

    setOptions((prevState: Option[]) => (value ? prevState : []));
    if (!value) {
      return;
    }

    toggleResultsLoading();
    fetchOptions(value).then((res) => {
      setOptions(() => (active ? res : []));
    });
    toggleResultsLoading();

    return () => {
      active = false;
    };
  }, [value, toggleResultsLoading]);

  React.useEffect(() => {
    setOptions((prevState: Option[]) => (open ? prevState : []));
  }, [open]);

  return (
    <Autocomplete
      open={open}
      onOpen={toggleOpen}
      onClose={toggleOpen}
      getOptionLabel={(option) => option.display_name}
      filterOptions={(passedOptions: Option[]) => passedOptions}
      options={options}
      loading={resultsLoading}
      onInputChange={async (_event: any, value: string) => {
        parseVintage(value);
      }}
      onChange={(_event, value) => {
        if (!value) {
          return;
        }
        if (
          wineSearches < 5 ||
          isProUser ||
          isPremiumUser ||
          (oldPlan &&
            oldPlan.renewsAt &&
            Date.parse(oldPlan.renewsAt) > Date.now())
        ) {
          if(vintage) {
            value.lwin += vintage;
          }
          console.log(value);
          setPageLoading(true);
          addWine(value);
          setPageLoading(false);
        } else {
          setUpgradeDialogOpen(true);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Lookup Wine"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {resultsLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchWineForm;
