import React from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@material-ui/core";
import { useCellarsContext, createCellar } from "../../context/cellarsContext";
import { useHistory } from "react-router-dom";

type CellarFormState = {
  name: string;
};

type CellarFormProps = {
  open: boolean;
  setOpen: (toggle: boolean) => void;
};

const CellarForm: React.FC<CellarFormProps> = ({ open, setOpen }) => {
  const [cellarForm, setCellarForm] = React.useState<CellarFormState>({
    name: "",
  });
  const history = useHistory();
  const { dispatch } = useCellarsContext();

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-create-cellar"
    >
      <DialogTitle id="form-dialog-title">New Cellar</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Cellar Name"
          value={cellarForm.name}
          onChange={(e) =>
            setCellarForm({ ...cellarForm, name: e.target.value })
          }
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={(e) => createCellar(dispatch, cellarForm.name, history)}
          color="primary"
        >
          Create Cellar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CellarForm;
