import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useCellarsContext } from "../../context/cellarsContext";
import { useCellarWinesContext } from "../../context/cellarWinesContext";
import useStyles from "../util/styles";
import { loadCellars, setError } from "../../context/cellarsContext/actions";
import { transferCellarWine } from "../../context/cellarWinesContext/actions";

const TransferWine: React.FC<{ wine: any }> = ({ wine }) => {
  const [form, setForm] = React.useState({ transferCellerId: "" });
  const [isNoActionModalOpen, setNoActionModal] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [isModalOpen, setModalOpen] = React.useState(false);
  const { id, cellarId } = wine;
  const classes = useStyles();
  const { 
    state: { cellars },
    dispatch,
  } = useCellarsContext();
  const {
    state: { cellarWines },
    dispatch: wineDispatch
  } = useCellarWinesContext();
  const otherCellars =
    cellars && Object.keys(cellars).filter((id) => id !== cellarId);
  const canTransfer = otherCellars && otherCellars.length > 0;

  const NoOtherCellarsModal = () => (
    <Dialog
      open={isNoActionModalOpen}
      onClose={() => setNoActionModal(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent id="form-dialog-title">
        <Typography>
          You do not have another cellar to transfer wines to.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setNoActionModal(false)} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  const onSubmit = async (e: any, form: any) => {
    e.preventDefault();
    type Errors = {
      [key: string]: boolean;
    };
    let errors: Errors = {
      transferCellerId: false,
    };
    if (!form.transferCellerId) {
      errors["transferCellerId"] = true;
    }
    if (Object.keys(errors).some((key: string) => errors[key])) {
      setFormErrors(errors);
    } else {
      if (
        !cellarWines?.length
      ) {
        await setError(
          dispatch,
          `This wine already exists within ${
            cellars && cellars[form.transferCellerId].name
          }`
        );
      } else {
        await transferCellarWine(wineDispatch, id, cellarId, form.transferCellerId);
        await loadCellars(dispatch);
        setModalOpen(false);
      }
    }
  };
  const onClose = () => {
    setModalOpen(false);
    setForm({ transferCellerId: "" });
    setFormErrors({});
  };

  return (
    <>
      {!canTransfer ? (
        <NoOtherCellarsModal />
      ) : (
        <Dialog open={isModalOpen} onClose={onClose} fullWidth maxWidth="sm">
          <DialogTitle id="form-dialog-title">
            <span style={{ fontWeight: 700 }}>Transfer Wine</span>
          </DialogTitle>
          <DialogContent>
            <form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel style={{ fontSize: "12px" }} htmlFor="size">
                    Cellar
                  </InputLabel>
                  <Select
                    classes={{ root: classes.selectRoot }}
                    labelId="size"
                    value={form.transferCellerId}
                    fullWidth
                    error={formErrors.transferCellerId}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        transferCellerId: (e.target.value as unknown) as string,
                      });
                    }}
                    inputProps={{
                      name: "Size",
                      id: "size",
                    }}
                  >
                    {otherCellars &&
                      cellars &&
                      otherCellars.map((cellar) => (
                        <MenuItem key={cellar} value={cellar}>
                          {cellars[cellar] ? cellars[cellar].name : ""}
                        </MenuItem>
                      ))}
                  </Select>
                  {formErrors.transferCellerId && (
                    <Typography
                      style={{
                        color: "#f44336",
                        fontSize: ".75rem",
                        marginTop: "8px",
                      }}
                    >
                      Required
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button onClick={(e) => onSubmit(e, form)} color="primary">
              Transfer
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Button
        color="primary"
        onClick={() =>
          canTransfer ? setModalOpen(true) : setNoActionModal(true)
        }
      >
        Transfer Wine
      </Button>
    </>
  );
};

export default TransferWine;
