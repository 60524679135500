import React from "react";
import Link from "@material-ui/core/Link";
import useStyles from "../util/styles";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../../images/logo_cellar_watch.png";
import Button from "@material-ui/core/Button";
import { logout, useAuth } from "../../context/authContext";
import { useCellarsContext } from "../../context/cellarsContext";

export default function Header() {
  const classes = useStyles();
  const {
    state: { user },
    dispatch,
  } = useAuth();
  const { dispatch: cellarsDispatch } = useCellarsContext();

  return (
    <Toolbar className={classes.toolbarHome}>
      <div style={{ flex: 1 }}>
        <Link href="/">
          <img src={logo} alt="" className={classes.toolbarLogo} />
        </Link>
      </div>
      <Button className={classes.headerButton} size="small" href="/pricing">
        Pricing
      </Button>
      {user && (
        <Button
          size="small"
          className={classes.headerButton}
          onClick={() => logout(dispatch, cellarsDispatch)}
        >
          Sign Out
        </Button>
      )}
      {user && (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          href="/cellars"
          className={classes.headerButton}
        >
          Dashboard
        </Button>
      )}
      {!user && (
        <Button
          type="submit"
          size="small"
          href="/login"
          className={classes.headerButton}
        >
          Log In
        </Button>
      )}
      {!user && (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          href="/signup"
          className={classes.headerButton}
        >
          Sign Up
        </Button>
      )}
    </Toolbar>
  );
}
