import React from "react";
import { LayoutWrapper } from "../../components/layoutWrapper";
import { 
  Container, 
  Button, 
  TextField,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Select,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStyles from "../../components/util/styles";
import CsvUpload from "../../components/csvUpload";
import { uploadCellarCsvRequest } from "../../lib/cellarRequests";
import { getAllUsersRequest, getCellarsForUser } from "../../lib/userRequests";
import Typography from "@material-ui/core/Typography";
import { loadCellars } from "../../context/cellarsContext";

type CsvParams = {
  id: string;
  csv_filename: string;
  users: any[];
  selected_user: any;
  cellars: any[],
  selected_cellar: any,
  cellar_name: string,
  upload_errors: string[];
  success_message: string;
  successfull_cw_imports: number;
  error_cw_imports: number;
};

const initialState = {
  id: "",
  csv_filename: "",
  users: [],
  selected_user: undefined,
  cellars: [],
  selected_cellar: "",
  cellar_name: "",
  upload_errors: [],
  success_message: "",
  successfull_cw_imports: 0,
  error_cw_imports: 0,
};

const UploadCellar: React.FC = () => {
  const classes = useStyles();
  const [form, setForm] = React.useState<CsvParams>(initialState);

  const { 
    users,
    selected_user,
    cellars,
    selected_cellar
  } = form;

  const uploadCellar = async () => {
    try {
      const response = await uploadCellarCsvRequest(form.csv_filename, form.selected_user.id, form.selected_cellar);
      
      setForm({
        ...initialState,
        success_message: `Successfully uploaded cellar for ${form.selected_user.name} ${form.selected_user.last_name}`,
        successfull_cw_imports: response.data.successfull_cw_imports,
        error_cw_imports: response.data.error_cw_imports,
      })
    } catch (e) {
      setForm({
        ...form,
        upload_errors: e ? e.upload_errors : [ "Something unexpected happened. "],
        successfull_cw_imports: e ? e.successfull_cw_imports : 0,
        error_cw_imports: e ? e.error_cw_imports : 0,
      });
    }
  }

  const loadCellars = async (user_id: string) => {
    const response = await getCellarsForUser(user_id);

    setForm({
      ...form,
      cellars: response.cellars
    })
  }

  React.useEffect(() => {
    if (users.length === 0) {
      getAllUsersRequest().then((res) => {
        setForm({
          ...form,
          users: res.users,
        });
      });
    } else if (selected_user && cellars.length === 0) {
      loadCellars(selected_user.id);
    }
  }, [users, form, setForm])

  const margin = "10px";
  
  return (
    <LayoutWrapper pathName="admin/uploadCellar">
      <Container maxWidth="lg" className={classes.container}>
        <h1>Upload a cellar</h1>
        <Typography 
          color="textSecondary"
          component="p"
          style= {{marginTop: margin}}
        >
          Select user for cellar upload
        </Typography>
        <Autocomplete
          id="users-dropown"
          style={{
            marginBottom: "20px"
          }}
          options={form.users}
          getOptionLabel={(option) => option.email ? `${option.email} - ${option.name} ${option.last_name}` : ''}
          value={form.selected_user || ""}
          renderInput={(params) => 
            <TextField 
              {...params} 
              fullWidth 
              variant="outlined" 
            />
          }
          onChange={(event, value) => {
            setForm({
              ...form,
              selected_user: value,
              success_message: "",
            });
          }}
        />
        {form.selected_user && <>
          <Typography 
            color="textSecondary"
            component="p"
            style= {{marginTop: margin}}
          >
            Select existing cellar or create a new one
          </Typography>
          <Select
            id="cellars-dropown"
            displayEmpty
            variant="outlined"
            style={{
              width: "100%"
            }}
            // options={form.cellars}
            // getOptionLabel={(option) => option.name }
            value={form.selected_cellar}
            onChange={(e) => {
              setForm({
                ...form,
                selected_cellar: e.target.value,
                success_message: "",
              });
            }}
          >
            <MenuItem value="">Create New Cellar</MenuItem>
            {
              form.cellars.length > 0 &&
              form.cellars.map((cellar) => {
                return (
                  <MenuItem 
                    key={cellar.id} 
                    value={cellar.id}
                  >
                    <b>{ cellar.name }</b> <span style={{ paddingLeft: "5px" }}>- { cellar.num_wines } wines</span>
                  </MenuItem>
                )
              })
            }
          </Select>
          <Typography
            color="textSecondary"
            component="p"
            style={{marginTop: margin}}
          >
            Drag and Drop csv or click to upload
          </Typography>
          <CsvUpload
            id={form.id}
            uploadUser={form.selected_user}
            setCsvInformation={(filename) => {
              setForm({
                ...form,
                csv_filename: filename,
                success_message: "",
              });
            }}
          />
        </>}
        {form.csv_filename !== "" && 
          <Typography 
            color="textSecondary"
            component="p"
          >
            csv uploaded!
          </Typography>
        }
        <Button
          onClick={(e) => uploadCellar()}
          color="primary"
          variant="outlined"
          disabled={form.csv_filename === "" || !form.selected_user}
          style= {{marginTop: margin, marginBottom: margin}}
        >
          Upload
        </Button>
        {form.success_message !== "" && 
          <>
            <Typography>{form.success_message}</Typography>
            <Typography>{`Imported ${form.successfull_cw_imports} wines.`}</Typography>
          </>
        }
        {form.upload_errors.length > 0 && 
          <>
            <Typography>{`Valid wines: ${form.successfull_cw_imports}`}</Typography>
            <Typography>{`Error wines: ${form.error_cw_imports}`}</Typography>
            <Typography>Upload Errors:</Typography>
            <Table size="small">
              <TableBody>
                {form.upload_errors.map((u_e, index) => {
                  return (<TableRow key={`upload-errors-${index}`}>
                    <TableCell style={{ color: "red" }}>{u_e}</TableCell>
                  </TableRow>)
                })}
              </TableBody>
            </Table>
          </>
        }
      </Container>
    </LayoutWrapper>
  )
};

export default UploadCellar;