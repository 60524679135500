import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../components/Typography";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: "2px solid currentColor",
    borderRadius: 0,
    height: "auto",
    padding: theme.spacing(2, 2),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buoy: {
    width: 60,
  },
});

function ProductContactUs(props) {
  const { classes } = props;

  return (
    <Container className={classes.root} component="section">
      <Button className={classes.button} href={"mailto:cw@vinous.com"}>
        <Typography variant="h5" component="span">
          Questions? Contact Us!
        </Typography>
      </Button>
    </Container>
  );
}

ProductContactUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductContactUs);
