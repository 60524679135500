import React from "react";
import {
  TableRow,
  TableCell,
  Typography,
  Collapse,
  IconButton,
  Grid,
} from "@material-ui/core";
import moment from "moment-timezone";
import {
  Delete,
} from "@material-ui/icons";
import useStyles from "../util/styles";
import EditWine from "../wines/EditWine";
import SellWine from "../wines/SellWine";
import TransferWine from "../wines/TransferWine";
import DeleteWine from "../wines/DeleteWine";
import AddReview from "../wines/AddReview";
import { VinousReviews } from "../wines/VinousReviews";
import {
  deleteCellarWineReviewRequest,
} from "../../lib/cellarRequests";
import Chart from "../chart";
import { CellarWineReviews, VinousReviewsProp } from "./index";
import { getSecondaryDataFormatter } from "../chart/helpers";


const CollapseContent: React.FC<{
  cellarWine: any;
  wineReviews: CellarWineReviews;
  setWineReviews: any;
  currencySymbol: string;
  open: boolean;
  vinousReviews?: VinousReviewsProp;
}> = ({
  cellarWine,
  wineReviews,
  setWineReviews,
  currencySymbol,
  open,
  vinousReviews,
}) => {
  const classes = useStyles();

  const deleteReview = async (reviewId: string) => {
    await deleteCellarWineReviewRequest(reviewId);
    const removedReviewArr = wineReviews.filter(
      ({ review_id }) => review_id !== reviewId
    );
    await setWineReviews(removedReviewArr);
  };

  const { secondaryPriceData, historicData } = cellarWine;
  let secondaryChartData: any[] = [];

  if (secondaryPriceData && historicData) {
    const priceDataEntries = Object.entries(secondaryPriceData);
    const fmtdSecondaryChartData = priceDataEntries?.flatMap(([key, value]) => {
      const entries = Object.entries(cellarWine.historicData);
      const formattedEntries = entries.map(([key, _]) => { return [key, parseInt(value as unknown as string)]; });

      return value && entries ? { 
        name: key,
        data: formattedEntries,
        formatter: getSecondaryDataFormatter(key, currencySymbol),
      } : [];
    });
    
    if (fmtdSecondaryChartData.length > 0) secondaryChartData = fmtdSecondaryChartData;
  }

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Grid container spacing={2} style={{ padding: 16 }}>
            <Grid container spacing={0}>
              <Grid item xs={4} className={classes.gridItem}>
                <Typography className={classes.wineInfoLabelGrid4}>
                  LWIN:
                </Typography>
                <Typography>{cellarWine.lwin}</Typography>
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                <Typography className={classes.wineInfoLabelGrid4}>
                  Purchase date:
                </Typography>
                <Typography>
                  {cellarWine.purchaseDate
                    ? `${moment(Date.parse(cellarWine.purchaseDate))
                        .tz("Europe/London")
                        .format("D MMMM yyyy")}`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                <Typography className={classes.wineInfoLabelGrid4}>
                  Packed as:
                </Typography>
                <Typography>
                  {cellarWine.bottlePackedAs && cellarWine.bottleSize
                    ? `${cellarWine.bottlePackedAs}x${parseInt(
                        cellarWine.bottleSize
                      )}cl`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                <Typography className={classes.wineInfoLabelGrid4}>
                  Stored at:
                </Typography>
                <Typography>{cellarWine.storedAt || "-"}</Typography>
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                <Typography className={classes.wineInfoLabelGrid4}>
                  Supplier:
                </Typography>
                <Typography>{cellarWine.supplier || "-"}</Typography>
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                <Typography className={classes.wineInfoLabelGrid4}>
                  Condition:
                </Typography>
                <Typography>{cellarWine.conditionNotes || "-"}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.gridItem}
                style={{ marginLeft: -8 }}
              >
                <EditWine wine={cellarWine} />
                <SellWine wine={cellarWine} />
                <TransferWine wine={cellarWine} />
                <DeleteWine wine={cellarWine} />
                <AddReview
                  cellarWineId={cellarWine.id}
                  setWineReviews={setWineReviews}
                  wineReviews={wineReviews}
                />
              </Grid>
              {cellarWine.historicData && (
                <Grid item xs={12}>
                  <Chart
                    historicData={[{
                      data: Object.entries(cellarWine.historicData),
                      name: `${cellarWine.vintage} ${cellarWine.displayName}` || "",
                      formatter: function() {
                        // @ts-expect-error
                        let s =  `<span style="color:${this.color}">${this.series.name}</span>: ` + 
                        // @ts-expect-error
                          `<b>${currencySymbol || "$"}${this.y.toLocaleString(undefined, { maximumFractionDigits: 2 })} Value over Time (/12x75cl Cs)</b>`
                        
                        // @ts-expect-error
                        if (this.change) {
                          // @ts-expect-error
                          s = s + ` (${this.change.toLocaleString(undefined, { maximumFractionDigits: 2 })}%)<br/>`;
                        }

                        return s;
                      }
                    }, ...secondaryChartData]}
                    loading={!open}
                    includeLivexIndices
                    title={"Value over Time"}
                    showRebaseToggle
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography component="h2" variant="h6" gutterBottom>
                  Tasting Notes
                </Typography>
                {wineReviews.length ? (
                  wineReviews.map((wineReview, index) => {
                    const {
                      review_id,
                      score,
                      tasting_date,
                      tasting_note,
                      cellar_wine_id,
                    } = wineReview;
                    return (
                      <div
                        key={review_id || `${cellar_wine_id}${index}`}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography>
                            <b>Score:</b> {score || "-"}
                          </Typography>
                          <Typography
                            style={{ marginLeft: "15px", color: "grey" }}
                          >
                            {tasting_date && tasting_date !== "-"
                              ? moment(
                                  Date.parse(cellarWine.purchaseDate)
                                ).format("D MMMM yyyy")
                              : ""}
                          </Typography>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            disabled={!review_id}
                            style={{ padding: 0, marginLeft: "10px" }}
                            onClick={() => deleteReview(review_id)}
                          >
                            <Delete color="primary" />
                          </IconButton>
                        </div>
                        <Typography variant="body1">
                          {tasting_note || "No tasting note on record"}
                        </Typography>
                      </div>
                    );
                  })
                ) : (
                  <AddReview
                    cellarWineId={cellarWine.id}
                    setWineReviews={setWineReviews}
                    wineReviews={wineReviews}
                  />
                )}
              </Grid>
            </Grid>
            {vinousReviews && <VinousReviews vinousInfo={vinousReviews} />}
          </Grid>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default CollapseContent;
