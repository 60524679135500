import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Title from "../title/index";
import { useCellarsContext } from "../../context/cellarsContext";
import useStyles from "../../components/util/styles";

export default function Performance() {
  const classes = useStyles();

  const {
    state: { cellars },
  } = useCellarsContext();

  return (
    <>
      <div style={{padding: "8px 16px"}} className={`${classes.borderBottom}`}>
        <Title>Performance</Title>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* paddingLeft is due to non-standardized table rows. Will have to do for now */}
            <TableCell style={{paddingLeft: "14px"}}>Cellar name</TableCell>
            <TableCell>Vs Cost</TableCell>
            <TableCell>30d</TableCell>
            <TableCell>12m</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cellars &&
            Object.keys(cellars).map((c: string) => {
              const cellar = cellars[c];
              if (cellar)
                return (
                  <TableRow key={cellar.id}>
                    {/* paddingLeft is due to non-standardized table rows. Will have to do for now */}
                    <TableCell style={{paddingLeft: "14px"}}>{cellar.name}</TableCell>
                    <TableCell
                      style={{
                        color:
                          cellar.valueChangeVsCost &&
                          cellar.valueChangeVsCost < 0
                            ? "red"
                            : cellar.valueChangeVsCost &&
                              cellar.valueChangeVsCost > 0
                            ? "green"
                            : "inherit",
                      }}
                    >
                      {cellar.valueChangeVsCost && cellar.valueChangeVsCost > 0
                        ? "+"
                        : ""}
                      {cellar.valueChangeVsCost
                        ? cellar.valueChangeVsCost.toFixed(1)
                        : 0.0}
                      %
                    </TableCell>
                    <TableCell
                      style={{
                        color:
                          cellar.valueChange30d && cellar.valueChange30d < 0
                            ? "red"
                            : cellar.valueChange30d && cellar.valueChange30d > 0
                            ? "green"
                            : "inherit",
                      }}
                    >
                      {cellar.valueChange30d && cellar.valueChange30d > 0
                        ? "+"
                        : ""}
                      {cellar.valueChange30d
                        ? cellar.valueChange30d.toFixed(1)
                        : 0.0}
                      %
                    </TableCell>
                    <TableCell
                      style={{
                        color:
                          cellar.valueChange12m && cellar.valueChange12m < 0
                            ? "red"
                            : cellar.valueChange12m && cellar.valueChange12m > 0
                            ? "green"
                            : "inherit",
                      }}
                    >
                      {cellar.valueChange12m && cellar.valueChange12m > 0
                        ? "+"
                        : ""}
                      {cellar.valueChange12m
                        ? cellar.valueChange12m.toFixed(1)
                        : 0.0}
                      %
                    </TableCell>
                  </TableRow>
                );
              return null;
            })}
        </TableBody>
      </Table>
    </>
  );
}
