import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useAuth } from "../../context/authContext";
import { useCellarsContext } from "../../context/cellarsContext";
import { clearAuth } from "../../context/authContext/actions";
import { clearMessage } from "../../context/cellarsContext/actions";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
}));

export default function CustomSnackbar() {
  const classes = useStyles();
  const {
    state: { message: CellarMessage, error: CellarError },
    dispatch: cellarsDispatch,
  } = useCellarsContext();
  const {
    state: { error: AuthError, message: AuthMessage },
    dispatch: authDispatch,
  } = useAuth();

  let error = AuthError || CellarError;
  let message = AuthMessage || CellarMessage;

  const handleClose = async (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    if (AuthError || AuthMessage) await clearAuth(authDispatch);
    if (CellarError || CellarMessage) await clearMessage(cellarsDispatch);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={error !== undefined || message !== undefined}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={error !== undefined ? "error" : "success"}
        >
          {error !== undefined
            ? error.toString()
            : message !== undefined
            ? message.toString()
            : ""}
        </Alert>
      </Snackbar>
    </div>
  );
}
