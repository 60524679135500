import React from "react";
import { LayoutWrapper } from "../../components/layoutWrapper";
import { Container, List, ListItem, ListItemText } from "@material-ui/core";
import useStyles from "../../components/util/styles";

const MARKET_REPORTS = [
  { text: "December 2023", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_December_2023.pdf" },
  { text: "November 2023", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_November_2023.pdf" },
  { text: "October 2023", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_October_2023.pdf" },
  { text: "September 2023", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_September_2023.pdf" },
  { text: "August 2023", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_August_2023.pdf" },
  { text: "July 2023", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_July_2023.pdf" },
  { text: "June 2023", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_June_2023.pdf" },
  { text: "May 2023", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_May_2023.pdf" },
  { text: "April 2023", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_April_2023.pdf" },
  { text: "March 2023", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_March_2023.pdf" },
  { text: "February 2023", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_February_2023.pdf" },
  { text: "January 2023", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_January_2023.pdf" },
  { text: "December 2022", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_December_2022.pdf" },
  { text: "November 2022", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_November_2022.pdf" },
  { text: "October 2022", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_October_2022.pdf" },
  { text: "September 2022", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_September_2022.pdf" },
  { text: "August 2022", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_August_2022.pdf" },
  { text: "July 2022", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_July_2022.pdf" },
  { text: "June 2022", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_June_2022.pdf" },
  { text: "May 2022", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_May_2022.pdf" },
  { text: "April 2022", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_April_2022.pdf" },
  { text: "March 2022", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_March_2022.pdf" },
  { text: "February 2022", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_February_2022.pdf" },
  { text: "January 2022", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_January_2022.pdf" },
  { text: "December 2021", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_December_2021.pdf" },
  { text: "November 2021", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/market_report_november_2021.pdf" },
  { text: "August 2021", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_August+2021.pdf" },
  { text: "July 2021", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/market_report_july_2021.pdf" },
  { text: "June 2021", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/market_report_june_2021.pdf" },
  { text: "May 2021", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_May+2021.pdf" },
  { text: "April 2021", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW+April_2021.pdf" },
  { text: "March 2021", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_March_2021.pdf" },
  { text: "February 2021", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/LXMR_CW_Feb_2021.pdf" },
  { text: "January 2021", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/market_report_january_2021.pdf" },
  { text: "December 2020", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/market_report_december_2020.pdf" },
  { text: "November 2020", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/market_report_november_2020.pdf" },
  { text: "October 2020", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/market_report_october_2020.pdf" },
  { text: "September 2020", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/market_report_september_2020.pdf" },
  { text: "August 2020", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/market_report_august_2020.pdf" },
  { text: "July 2020", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/market_report_july_2020.pdf" },
  { text: "June 2020", url: "https://production-vinous-cellars-uploads.s3.amazonaws.com/marketReports/market_report_june_2020.pdf" },
];

const MarketReports: React.FC = () => {
  const classes = useStyles();

  return (
    <LayoutWrapper pathName="marketReports">
      <Container maxWidth="lg" className={classes.container}>
        <h1>Market Reports</h1> 

        <List>
          { MARKET_REPORTS.map(({ text, url}) => {
            return (
              <ListItem button component="a" href={url} target="_blank">
                <ListItemText primary={text} />
              </ListItem>
            );
          })}
        </List>
      </Container>
    </LayoutWrapper>
  )

}

export default MarketReports;
