import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import Button from "../components/Button";
import cellarImage from "../../../../images/cellar.jpg";
import { useHistory } from "react-router";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: 0,
    display: "flex",
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(8, 3),
  },
  cardContent: {
    maxWidth: 400,
  },
  view: {
    width: "100%",
    margin: theme.spacing(2),
    color: "#F3F3F3",
  },
  imagesWrapper: {
    position: "relative",
  },
  image: {
    position: "absolute",
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: "100%",
    maxWidth: 600,
  },
});

function ProductCTA(props) {
  const { classes } = props;
  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    history.push("/signup");
  };

  return (
    <Container className={classes.root} component="section">
      <Grid container>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
            <form onSubmit={handleSubmit} className={classes.cardContent}>
              <Typography
                variant="h2"
                component="h2"
                gutterBottom
                className={classes.view}
              >
                BUILD YOUR CELLAR
              </Typography>
              <Typography variant="h5" className={classes.view}>
                Use the tools trusted by industry professionals to understand
                your wine collection as never before.
              </Typography>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.view}
              >
                Sign Up
              </Button>
            </form>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          <Hidden smDown>
            <img src={cellarImage} alt="" className={classes.image} />
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
}

ProductCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCTA);
