import React from "react";
import { Button } from "@material-ui/core";
import { sellCellarWine } from "../../context/cellarWinesContext/actions";
import { useCellarWinesContext } from "../../context/cellarWinesContext";
import CellarModalForm from "../CellarModalForm";

const SellWine: React.FC<{ wine: any }> = ({ wine }) => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const { id, cellarId } = wine;
  const { dispatch } = useCellarWinesContext();
  const submitSellModalForm = async (e: any, form: any, cb: any) => {
    e.preventDefault();
    type Errors = {
      [key: string]: boolean;
    };
    let errors: Errors = {
      sellDate: false,
      bottles: false,
      soldFor: false,
    };
    if (!form.sellDate) {
      errors["sellDate"] = true;
    }
    if (!form.bottles) {
      errors["bottles"] = true;
    }
    if (!form.soldFor) {
      errors["soldFor"] = true;
    }
    if (Object.keys(errors).some((key: string) => errors[key])) {
      setFormErrors(errors);
    } else {
      sellCellarWine(
        dispatch,
        id,
        cellarId,
        form.sellDate,
        form.soldFor,
        form.bottles
      );
      cb();
      setModalOpen(false);
    }
  };
  return (
    <>
      <CellarModalForm
        type="Sell"
        title="Sell Wine"
        open={isModalOpen}
        buttonText="Sell"
        setOpen={setModalOpen}
        setFormErrors={setFormErrors}
        initialValues={wine}
        onSubmit={submitSellModalForm}
        errors={formErrors}
      />
      <Button color="primary" onClick={() => setModalOpen(true)}>
        Sell Wine
      </Button>
    </>
  );
};

export default SellWine;
