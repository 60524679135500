import React from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import useStyles from "../util/styles";
import {
  PriceHistoryRecord,
  PriceHistoryRecords,
} from "../../pages/priceHistory";

const CWTableCell: React.FC<{ style?: object }> = ({ children, style }) => {
  const classes = useStyles();
  return (
    <TableCell style={style} className={classes.cwTableCell} align="right">
      {children}
    </TableCell>
  );
};

const CellarWineTableRow: React.FC<{
  rowCells: string[];
  currencySymbol: string;
}> = ({ rowCells, currencySymbol }) => {
  return rowCells ? (
    <>
      <TableRow>
        {rowCells.map((text, index) => {
          return (
            <CWTableCell key={text}>{`${
              index !== 0 && text !== "-" ? currencySymbol : ""
            }${text}`}</CWTableCell>
          );
        })}
      </TableRow>
    </>
  ) : (
    <p>Loading...</p>
  );
};

type CompareWinesTableProps = {
  winesToCompare: PriceHistoryRecords[];
  setWinesToCompare: Function;
  currencySymbol: string;
};

const CompareWinesTable: React.FC<CompareWinesTableProps> = ({
  winesToCompare,
  setWinesToCompare,
  currencySymbol,
}) => {
  const CWHeadTableCell: React.FC<{ text: string }> = ({ text }) => {
    const classes = useStyles();

    return (
      <TableCell align="right" className={classes.cwHeadTableCell}>
        <div>
          <Typography
            className={classes.cwHeadTableCellText}
            onClick={(e) => {
              const removedData = winesToCompare.filter(
                ({ name }: PriceHistoryRecords) => text !== name
              );
              setWinesToCompare(removedData);
            }}
          >
            {text}
          </Typography>
        </div>
      </TableCell>
    );
  };

  return (
    <TableContainer component={Paper} style={{ maxHeight: "150px" }}>
      <Table stickyHeader size="small" aria-label="Wine Prices Table">
        <TableHead style={{ borderColor: "red" }}>
          <TableRow>
            <CWHeadTableCell text={`Market Value/Cs (${currencySymbol})`} />
            {winesToCompare.map(({ name }) => {
              return <CWHeadTableCell key={name} text={name} />;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {winesToCompare[0].data.map(
            ({ vintage }: PriceHistoryRecord, i: number) => {
              const rowCells: string[] = [];
              rowCells.push(vintage);
              winesToCompare.map(({ data }: PriceHistoryRecords) =>
                rowCells.push(data[i].price)
              );
              return (
                <CellarWineTableRow
                  rowCells={rowCells}
                  currencySymbol={currencySymbol}
                  key={vintage}
                />
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompareWinesTable;
