import React from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, makeStyles, Paper } from "@material-ui/core";
import Chart from "../../components/chart";
import { LayoutWrapper } from "../../components/layoutWrapper";
import CellarModalForm from "../../components/CellarModalForm";
import {
  currencyChanged,
  loadHistoricDataForCellar,
} from "../../context/cellarsContext/actions";
import {
  createCellarWine,
  getDisposedWines,
  loadCellarWines
} from "../../context/cellarWinesContext/actions";
import { useCellarsContext, loadCellars } from "../../context/cellarsContext";
import { useCellarWinesContext } from "../../context/cellarWinesContext";
import { Cellar as TCellar } from "../../types/Cellar";
import { DisposedWine } from "../../types/CellarWine";
import Performance from "../../components/performance";
import CustomSnackbar from "../../components/snackbar";
import StripeElementsWrapper from "../../components/stripeElementsWrapper";
import UpgradeDialog from "../../components/upgradeDialog";
import CellarCard from "../../components/cellarCard";
import { useAuth } from "../../context/authContext";
import { getCurrentUser } from "../../context/authContext/actions";
import { currencies } from "../../constants";
import CellarWinesTable from "../../components/CellarWinesTable";
import DisposedWinesTable from "../../components/disposedWinesTable";
import LivexIndices from "../../components/livexIndices";
import useStyles from "../../components/util/styles";

const initialValues = {
  id: "",
  cellarId: "",
  lwin: "",
  lwinDetail: { lwin: "", displayName: "" },
  conditionNotes: null,
  currency: "GBP",
  totalCost: "",
  tastingDate: undefined,
  tastingNote: undefined,
  score: undefined,
  isVisible: undefined,
  purchaseDate: new Date(),
  storedAt: undefined,
  supplier: null,
  cacheValue: null,
  bottleSize: 75,
  bottleCount: null,
  bottlePackedAs: null,
  vintage: "",
  updatedAt: "",
  createdAt: "",
  deletedAt: null,
  drinkFrom: null,
  drinkTo: null,
};

const useStylesLocal = makeStyles(() => ({
  modalButton: {
    margin: "5px 0px",
  },
  appBar: {
    position: "relative",
  },
}));

const Cellar: React.FC = () => {
  // merge styles from local with generic
  const classes = Object.assign(useStylesLocal(), useStyles());
  const [formDialogOpen, setFormDialogOpen] = React.useState(false);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [disposedWinesLoaded, setDisposedWinesLoaded] = React.useState(false)
  const { slug } = useParams<{ slug: string }>();
  const {
    state: {
      cellars,
      loading,
      slugs,
      currencyChange,
    },
    dispatch,
  } = useCellarsContext();
  const {
    state: {
      cellarWines,
      disposedWines
    },
    dispatch: wineDispatch
  } = useCellarWinesContext();
  const {
    state: { user },
    dispatch: userDispatch,
  } = useAuth();
  const cellarId = slug && slugs ? slugs[slug] : null;
  const [cellar, setCellar] = React.useState<TCellar | undefined>(
    cellarId && cellars ? cellars[cellarId] : undefined
  );
  let soldWines: any[] = [];
  let consumedWines: any[] = [];

  const submitModalForm = async (e: any, form: any, cb: any) => {
    e.preventDefault();
    type Errors = {
      [key: string]: boolean;
    };
    let errors: Errors = {
      totalCost: false,
      LWIN: false,
      vintage: false,
      bottles: false,
    };
    if (!form.lwin) {
      errors["LWIN"] = true;
    }
    if (!form.totalCost) {
      errors["totalCost"] = true;
    }
    if (!form.vintage) {
      errors["vintage"] = true;
    }
    if (!form.bottleCount) {
      errors["bottles"] = true;
    }

    if (Object.keys(errors).some((key: string) => errors[key])) {
      setFormErrors(errors);
    } else {
      await createCellarWine(wineDispatch, {
        ...form,
        cellarId,
      });
      cb();
      setFormDialogOpen(false);
      await loadCellars(dispatch);
      await loadCellarWines(wineDispatch, cellar!.slug, 25, 0, "default", false, true);
    }
  };

  React.useEffect(() => {
    if (cellar && (disposedWines && !disposedWines.length) && !disposedWinesLoaded) {
      getDisposedWines(wineDispatch, cellar.id);
      setDisposedWinesLoaded(true);
    }
  }, [cellar, disposedWines, disposedWinesLoaded, wineDispatch]);

  React.useEffect(() => {
    if (cellar && !cellar.historicData)
      loadHistoricDataForCellar(dispatch, cellar.id);
  }, [cellar, dispatch]);

  React.useEffect(() => {
    if (!loading && !user) getCurrentUser(userDispatch);
  }, [user, userDispatch, loading]);

  React.useEffect(() => {
    if ((!loading && !cellars) || currencyChange) {
      loadCellars(dispatch);
      currencyChanged(dispatch, false);
    }
  }, [loading, cellars, currencyChange, dispatch]);

  React.useEffect(() => {
    if (
      cellars &&
      cellarId &&
      cellars[cellarId] &&
      cellar !== cellars[cellarId]
    ) {
      setCellar(cellars[cellarId]);
    }
  }, [cellar, cellars, cellarId]);

  const currencyObj =
    user && currencies.find((currency) => currency.value === user.currency);

  if (
    cellar &&
    cellarWines &&
    disposedWines
  ) {
    disposedWines.map((disposedWine: DisposedWine) => {
      const cellarWine = cellarWines.find((cw) => {
        return cw && cw.id === disposedWine.cellar_wine_id;
      });
      if (cellarWine) {
        const newDisposedWine = {
          ...disposedWine,
          region: cellarWine.region,
          color: cellarWine.colour,
          vintage: cellarWine.vintage,
          displayName: cellarWine.displayName,
        };
        newDisposedWine.disposal_type.toLowerCase() === "consumed"
          ? consumedWines.push(newDisposedWine)
          : soldWines.push(newDisposedWine);
      }
      return null;
    });
  }

  return (
    <LayoutWrapper
      pathName={`/cellars/${slug}`}
      cellar={cellar}
      loading={loading}
    >
      {cellar && user && (
        <Container
          maxWidth={false}
          className={`${classes.container} ${classes.marginZero}`}
        >
          <CustomSnackbar />
          <StripeElementsWrapper>
            <UpgradeDialog
              open={upgradeDialogOpen}
              setOpen={setUpgradeDialogOpen}
            />
          </StripeElementsWrapper>
          <CellarModalForm
            type="Add"
            title="Add Wine"
            buttonText="Add to Cellar"
            open={formDialogOpen}
            setOpen={setFormDialogOpen}
            setFormErrors={setFormErrors}
            initialValues={initialValues}
            onSubmit={submitModalForm}
            errors={formErrors}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CellarCard
                cellar={cellar}
                showingDetails={true}
                currencySymbol={currencyObj && currencyObj.symbol}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper
                className={`${classes.paper} ${classes.border} ${classes.borderRadiusNone} ${classes.boxShadowNone}`}
              >
                <CellarWinesTable
                  cellarSlug={slug}
                  currency={currencies.find(
                    (currency) => currency.value === user.currency
                  )}
                  setFormDialogOpen={setFormDialogOpen}
                  setUpgradeDialogOpen={setUpgradeDialogOpen}
                />
              </Paper>
            </Grid>
            {consumedWines.length > 0 && (
              <Grid item xs={12}>
                <Paper className={`${classes.paper} ${classes.paperBorder}`}>
                  <DisposedWinesTable
                    disposedType={"consumed"}
                    disposedWines={consumedWines}
                    currency={currencies.find((currency) =>
                      cellar && cellar.base_currency
                        ? currency.value === cellar.base_currency
                        : currency.value === user.currency
                    )}
                  />
                </Paper>
              </Grid>
            )}
            {soldWines.length > 0 && (
              <Grid item xs={12}>
                <Paper className={`${classes.paper} ${classes.paperBorder}`}>
                  <DisposedWinesTable
                    disposedType={"sold"}
                    disposedWines={soldWines}
                    currency={currencies.find((currency) =>
                      cellar && cellar.base_currency
                        ? currency.value === cellar.base_currency
                        : currency.value === user.currency
                    )}
                  />
                </Paper>
              </Grid>
            )}
            {/* Chart */}
            <Grid item xs={12}>
              <Paper className={`${classes.paper} ${classes.paperBorder}`}>
                { cellar.historicData && <Chart
                  historicData={[{
                    data: Object.entries(cellar.historicData),
                    name: cellar.name || "",
                    formatter: function() {
                      // @ts-expect-error
                      let s =  `<span style="color:${this.color}">${this.series.name}</span>: ` +
                        // @ts-expect-error
                        `<b>${(currencyObj && currencyObj.symbol) || "$"}${this.y.toLocaleString(undefined, { maximumFractionDigits: 2 })}</b>`

                      // @ts-expect-error
                      if (this.change) {
                        // @ts-expect-error
                        s = s + ` (${this.change.toLocaleString(undefined, { maximumFractionDigits: 2 })}%)<br/>`;
                      }

                      return s;
                    }
                  }]}
                  loading={!cellar.historicData}
                  includeLivexIndices
                  title={"Cellar Value Over Time"}
                />
                }
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper} style={{ padding: "16px" }}>
                <LivexIndices />
              </Paper>
            </Grid>
            {cellars &&
            Object.keys(cellars).length > 1 && ( // Show cellars performance if user has more than 1 cellar
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Performance />
                  </Paper>
                </Grid>
              )}
          </Grid>
        </Container>
      )}
    </LayoutWrapper>
  );
};

export default Cellar;
