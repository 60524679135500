import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import image1 from "../../../../images/image1.png";
import image2 from "../../../../images/image2.png";
import image3 from "../../../../images/image3.png";
import image4 from "../../../../images/image4.png";
import image5 from "../../../../images/image5.png";
import image6 from "../../../../images/image6.png";
import image7 from "../../../../images/image7.png";
import image8 from "../../../../images/image8.png";
import image9 from "../../../../images/image9.png";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 180,
    },
    "&:hover": {
      zIndex: 1,
    },
    "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
});

function ProductCategories(props) {
  const { classes } = props;

  const images = [
    {
      url: image1,
      title: "Squares & Circles: Bordeaux ‘10 At Ten by Neal Martin",
      width: "40%",
      link:
        "https://vinous.com/articles/squares-circles-bordeaux-10-at-ten-apr-2020",
    },
    {
      url: image2,
      title: "1990 Soldera by Antonio Galloni",
      width: "20%",
      link:
        "https://vinous.com/articles/cellar-favorite-1990-soldera-case-basse-brunello-di-montalcino-feb-2020",
    },
    {
      url: image3,
      title:
        "Vintage Retrospective: The Great 2010 Napa Valley Cabernets by Stephen Tanzer",
      width: "40%",
      link:
        "https://vinous.com/articles/vintage-retrospective-the-great-2010-napa-valley-cabernets-may-2020",
    },
    {
      url: image4,
      title: "Northern Rhône: A First Look at the 2019s by Josh Raynolds",
      width: "38%",
      link:
        "https://vinous.com/articles/northern-rhone-a-first-look-at-the-2019s-may-2020",
    },
    {
      url: image5,
      title: "2016 Barolo: Right Place, Right Time by Antonio Galloni",
      width: "38%",
      link:
        "https://vinous.com/articles/2016-barolo-right-place-right-time-feb-2020",
    },
    {
      url: image6,
      title: "Germany 2018: The Nahe – Leading by a Nose by David Schildknecht",
      width: "24%",
      link:
        "https://vinous.com/articles/germany-2018-the-nahe-leading-by-a-nose-jun-2020",
    },
    {
      url: image7,
      title: "Domaine Dujac Clos Saint-Denis 1970-2004 by Neal Martin",
      width: "40%",
      link:
        "https://vinous.com/articles/domaine-dujac-clos-saint-denis-1970-2004-mar-2020",
    },
    {
      url: image8,
      title: "Remember, Remember: 1945 Bordeaux by Neal Martin",
      width: "20%",
      link:
        "https://vinous.com/articles/remember-remember-1945-bordeaux-may-2020",
    },
    {
      url: image9,
      title:
        "2015 Brunello di Montalcino: The Vintage We Have Been Waiting For? by Eric Guido",
      width: "40%",
      link:
        "https://vinous.com/articles/2015-brunello-di-montalcino-the-vintage-we-have-been-waiting-for-apr-2020",
    },
  ];

  return (
    <Container className={classes.root} component="section">
      <Typography variant="h4" marked="center" align="center" component="h2">
        VINOUS INSIGHT
      </Typography>
      <div className={classes.images}>
        {images.map((image) => (
          <ButtonBase
            key={image.title}
            className={classes.imageWrapper}
            href={image.link}
            style={{
              width: image.width,
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className={classes.imageTitle}
              >
                {image.title}
                <div className={classes.imageMarked} />
              </Typography>
            </div>
          </ButtonBase>
        ))}
      </div>
    </Container>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCategories);
