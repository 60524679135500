import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { Cellar } from "../../types/Cellar";
import logo from "../../images/logo_cellar_watch.png";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#112131",
    borderBottomStyle: "solid",
    alignItems: "stretch",
  },
  detailColumn: {
    flexDirection: "column",
    flexGrow: 9,
    justifyContent: "center",
    alignItems: "center",
  },
  linkColumn: {
    flexDirection: "column",
    flexGrow: 2,
    alignSelf: "flex-end",
  },
  name: {
    margin: 2,
    fontSize: 12,
    alignSelf: "flex-end",
  },
  subtitle: {
    margin: 2,
    fontSize: 10,
    alignSelf: "flex-end",
  },
  link: {
    margin: 2,
    fontSize: 8,
    color: "blue",
    textDecoration: "none",
    alignSelf: "flex-end",
  },
});

const CellarReportHeader = (
  props: { 
    cellar: Cellar; 
    today: string; 
    currency: string 
  }) => {
    return (
      <View style={styles.container}>
        <View style={styles.linkColumn}>
          <Image src={logo} style={{ maxWidth: 240, height: 51, margin: 8 }} />
        </View>
        <View style={styles.detailColumn}>
          <Text style={styles.name}>
            Monthly cellar valuation for {props.cellar.name}
          </Text>
          <Text style={styles.subtitle}>
            All values in {props.currency} per 12x75cl case
          </Text>
          <Text style={styles.subtitle}>{props.today}</Text>
        </View>
      </View>
    )
  }

export default CellarReportHeader;