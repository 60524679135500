import { get, post } from "./api";

export const getCurrentUserRequest = () => {
  return get("/user/currentUser");
};

export const getAllUsersRequest = () => {
  return get("/user/allUsers");
};

export const getCellarsForUser = (id: string) => {
  return get("/user/cellars", { id });
}

export const getWineSearchesRequest = () => {
  return get("/user/wineSearches");
};

export const updateWineSearchesRequest = () => {
  return post("/user/wineSearches", {});
};

export const editCurrentUserRequest = (params: any) => {
  return post("/user/currentUser", { ...params });
};

export const getPaymentMethodsRequest = () => {
  return get("/user/paymentMethods");
};

export const setPaymentMethodRequest = (paymentMethodId: string) => {
  return post("/user/setPaymentMethod", { paymentMethodId });
};
