import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { Cellar } from "../../types/Cellar";
import { CellarWine } from "../../types/CellarWine";
import Colors from "../util/colors";
import { numberWithCommas } from "../util/format";
import leLogo from "../../images/le-logo.jpg";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 10,
    paddingLeft: 15,
  },
  detailLeftColumn: {
    marginTop: 5,
    flexDirection: "column",
  },
  leftColumn: {
    flexDirection: "column",
    flexGrow: 1,
  },
  section: {
    fontSize: 12,
    marginBottom: 10,
    marginTop: 20,
    textTransform: "uppercase",
  },
  item: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  keyData: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  head: {
    justifyContent: "space-between",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: Colors.vinousRed,
    borderBottomStyle: "solid",
    marginBottom: 10,
  },
  keyDataItem: {
    fontSize: 11,
    marginBottom: 8,
  },
  itemContentLarge: {
    fontSize: 7,
    marginBottom: 5,
    width: 150,
  },
  itemContentSmall: {
    fontSize: 7,
    marginBottom: 5,
    minWidth: 20,
  },
  itemContent: {
    fontSize: 7,
    marginBottom: 5,
    minWidth: 30,
  },
  itemContentMedium: {
    fontSize: 7,
    marginBottom: 5,
    minWidth: 60,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0
  }
});

const sortNumeric = (a: number, b: number): number => {
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  return 0;
};

const getSortedWines = (cellarWines: any[]) => {
  let mergedCWs: any[] = [];

  const regionToWines: { [region: string]: any[] } = {};
  cellarWines.forEach((cw) => {
    if (cw) {
      if (regionToWines[cw.region]) {
        regionToWines[cw.region].push(cw);
      } else {
        regionToWines[cw.region] = [cw];
      }
    }
  });
  Object.keys(regionToWines)
    .sort((a: any, b: any) => a.localeCompare(b))
    .forEach((region) => {
      const winesFromSameRegion = regionToWines[region];
      const winesWithSameColor: { [color: string]: any[] } = {};

      winesFromSameRegion.forEach((cw) => {
        if (cw) {
          if (winesWithSameColor[cw.color]) {
            winesWithSameColor[cw.color].push(cw);
          } else {
            winesWithSameColor[cw.color] = [cw];
          }
        }
      });

      Object.keys(winesWithSameColor)
        .sort((a, b) => a.localeCompare(b))
        .forEach((color) => {
          const sameColorWines = winesWithSameColor[color];
          const displayNameToVintages: {
            [displayName: string]: any[];
          } = {};

          sameColorWines.forEach((wine) => {
            if (wine) {
              if (displayNameToVintages[wine.displayName]) {
                displayNameToVintages[wine.displayName].push(wine);
              } else {
                displayNameToVintages[wine.displayName] = [wine];
              }
            }
          });

          Object.keys(displayNameToVintages)
            .sort((a, b) => a.localeCompare(b))
            .forEach((displayName) => {
              const sameWines = displayNameToVintages[displayName];
              const sameWinesSortedByVintage = sameWines.sort((a, b) =>
                a.vintage.localeCompare(b.vintage)
              );
              mergedCWs.push(...sameWinesSortedByVintage);
            });
        });
    });

  return mergedCWs;
};

const Body = (props: {
  cellar: Cellar;
  cellarWines: CellarWine[];
  currency: string;
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.keyData}>
        <View style={styles.detailLeftColumn}>
          <Text style={styles.keyDataItem}>
            {" "}
            Market value of cellar:{" "}
            {numberWithCommas(props.cellar.valueAtCurrent)}
          </Text>
          <Text style={styles.keyDataItem}>
            {" "}
            Cost of cellar: {numberWithCommas(props.cellar.valueAtCost)}
          </Text>
          <Text style={styles.keyDataItem}>
            {" "}
            Return on cost: {props.cellar.valueChangeVsCost > 0 ? "+" : ""}
            {props.cellar.valueChangeVsCost.toFixed(1)}%
          </Text>
        </View>
        <View style={styles.detailLeftColumn}>
          <Text style={styles.keyDataItem}>
            {" "}
            Number of bottles: {numberWithCommas(props.cellar.numBottles)}
          </Text>
          <Text style={styles.keyDataItem}>
            {" "}
            Number of wines: {numberWithCommas(props.cellar.numWines)}
          </Text>
          <Text style={styles.keyDataItem}>
            {" "}
            Average value per bottle:{" "}
            {numberWithCommas(
              Math.round(props.cellar.valueAtCurrent / props.cellar.numBottles)
            )}
          </Text>
        </View>
      </View>
      <Text style={styles.section}>Top 5 performing wines</Text>
      <View style={styles.head}>
        <Text style={styles.itemContentMedium}>{"Region"}</Text>
        <Text style={styles.itemContentSmall}>{"Color"}</Text>
        <Text style={styles.itemContentLarge}>{"Wine"}</Text>
        <Text style={styles.itemContentSmall}>{"Size"}</Text>
        <Text style={styles.itemContentSmall}>{"Bottles"}</Text>
        <Text style={styles.itemContent}>{"Cost/Cs"}</Text>
        <Text style={styles.itemContent}>{"Market/Cs"}</Text>
        <Text style={styles.itemContent}>{"Total Cost"}</Text>
        <Text style={styles.itemContent}>{"Total Value"}</Text>
        <Text style={styles.itemContent}>{"Vs Cost"}</Text>
        <Text style={styles.itemContent}>{"12m"}</Text>
      </View>
      {props.cellarWines
        .filter(
          (cellarWine) =>
            cellarWine.cellarId === props.cellar.id &&
            cellarWine.bottleCount > 0
        )
        .sort((a: any, b: any) => {
          if (a.performance && b.performance) {
            return sortNumeric(a.performance.vsCost, b.performance.vsCost);
          } else {
            return 0;
          }
        })
        .slice(0, 5)
        .map((cellarWine) => {
          const {
            vintage,
            displayName,
            bottleCount,
            bottleSize,
            marketPrice,
            initialCost,
            totalValue,
            totalCost,
            region,
            colour,
            performance: { perf12month, vsCost },
          } = cellarWine;

          const wine = `${displayName}, ${
            vintage !== "No Vintage" ? vintage : ""
          }`;
          return (
            <View style={styles.item}>
              <Text style={styles.itemContentMedium}>{region}</Text>
              <Text style={styles.itemContentSmall}>{colour}</Text>
              <Text style={styles.itemContentLarge}>{wine}</Text>
              <Text style={styles.itemContentSmall}>{bottleSize || "-"}cl</Text>
              <Text style={styles.itemContentSmall}>{bottleCount}</Text>
              <Text style={styles.itemContent}>
                {initialCost.toFixed(2).toLocaleString()}
              </Text>
              <Text style={styles.itemContent}>
                {marketPrice.toFixed(2).toLocaleString()}
              </Text>
              <Text style={styles.itemContent}>
                {totalCost.toFixed(2).toLocaleString()}
              </Text>
              <Text style={styles.itemContent}>
                {totalValue.toFixed(2).toLocaleString()}
              </Text>
              <Text style={styles.itemContent}>
                {vsCost > 0 ? "+" : ""}
                {vsCost ? `${vsCost.toFixed(1).toLocaleString()}%` : "-"}
              </Text>
              <Text style={styles.itemContent}>
                {perf12month > 0 ? "+" : ""}
                {perf12month
                  ? `${perf12month.toFixed(1).toLocaleString()}%`
                  : "-"}
              </Text>
            </View>
          );
        })}
      <Text style={styles.section}>Cellar contents</Text>
      <View style={styles.head}>
        <Text style={styles.itemContentMedium}>{"Region"}</Text>
        <Text style={styles.itemContentSmall}>{"Color"}</Text>
        <Text style={styles.itemContentLarge}>{"Wine"}</Text>
        <Text style={styles.itemContentSmall}>{"Size"}</Text>
        <Text style={styles.itemContentSmall}>{"Bottles"}</Text>
        <Text style={styles.itemContent}>{"Cost/Cs"}</Text>
        <Text style={styles.itemContent}>{"Market/Cs"}</Text>
        <Text style={styles.itemContent}>{"Total Cost"}</Text>
        <Text style={styles.itemContent}>{"Total Value"}</Text>
        <Text style={styles.itemContent}>{"Vs Cost"}</Text>
        <Text style={styles.itemContent}>{"12m"}</Text>
      </View>
      {getSortedWines(
        props.cellarWines.filter(
          (cellarWine) =>
            cellarWine.cellarId === props.cellar.id &&
            cellarWine.bottleCount > 0
        )
      ).map((cellarWine) => {
        const {
          vintage,
          displayName,
          bottleCount,
          bottleSize,
          marketPrice,
          initialCost,
          totalValue,
          totalCost,
          region,
          colour,
          performance: { perf12month, vsCost },
        } = cellarWine;

        const wine = `${displayName}, ${vintage}`;
        return (
          <View style={styles.item}>
            <Text style={styles.itemContentMedium}>{region}</Text>
            <Text style={styles.itemContentSmall}>{colour}</Text>
            <Text style={styles.itemContentLarge}>{wine}</Text>
            <Text style={styles.itemContentSmall}>{bottleSize || "-"}cl</Text>
            <Text style={styles.itemContentSmall}>{bottleCount}</Text>
            <Text style={styles.itemContent}>
              {initialCost.toFixed(2).toLocaleString()}
            </Text>
            <Text style={styles.itemContent}>
              {marketPrice.toFixed(2).toLocaleString()}
            </Text>
            <Text style={styles.itemContent}>
              {totalCost.toFixed(2).toLocaleString()}
            </Text>
            <Text style={styles.itemContent}>
              {totalValue.toFixed(2).toLocaleString()}
            </Text>
            <Text style={styles.itemContent}>
              {vsCost > 0 ? "+" : ""}
              {vsCost ? `${vsCost.toFixed(1).toLocaleString()}%` : "-"}
            </Text>
            <Text style={styles.itemContent}>
              {perf12month > 0 ? "+" : ""}
              {perf12month
                ? `${perf12month.toFixed(1).toLocaleString()}%`
                : "-"}
            </Text>
          </View>
        );
      })}
      <View style={styles.footer}>
        <Image src={leLogo} style={{ width: 61, height: 29, marginLeft: "auto", marginRight: "auto" }} />
      </View>
    </View>
  );
};

export default Body;
