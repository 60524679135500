import React from "react";
import {
  Button, Dialog, DialogTitle, DialogContent, FormGroup, FormControlLabel, Checkbox, DialogActions, useMediaQuery, useTheme
} from "@material-ui/core"

type LivexIndexOption = {
  name: string;
  symbol: string;
};

type Props = {
  open: boolean;
  handleClose: () => void;
  livexIndexOptions: LivexIndexOption[];
  selectedIndices: string[];
  handleClick: (symbol: string) => void;
};

const LivexIndicesDialog = ({ open, handleClose, livexIndexOptions, selectedIndices, handleClick }: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
      >
        <DialogTitle id="responsive-dialog-title">{"Select Indices to Compare"}</DialogTitle>
        <DialogContent>
          <FormGroup>
            {livexIndexOptions.map(({ name, symbol }) => (
              <FormControlLabel
                key={symbol}
                label={name}
                control={
                  <Checkbox
                    color="primary"
                    checked={selectedIndices.includes(symbol)}
                    onChange={() => handleClick(symbol)}
                  />
                }
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Select
          </Button>
        </DialogActions>
      </Dialog>
  )
};

export default LivexIndicesDialog;