import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export default function Copyright() {
  return (
    <div style={{ marginBottom: 16 }}>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://vinous.com/">
          Vinous
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Pricing data © "}
        <Link color="inherit" href="https://www.liv-ex.com/">
          Liv-ex
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
    </div>
  );
}
