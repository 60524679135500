import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import vinousLogo from "../../../../images/logo_vinous.png";
import delectableLogo from "../../../../images/logo_delectable.png";

function Copyright() {
  return (
    <React.Fragment>
      <Typography style={{ color: "#fff" }}>
        © Vinous {new Date().getFullYear()}
      </Typography>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: "flex",
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: "flex",
  },
  icon: {
    maxHeight: 50,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    display: "flex",
    justifyContent: "center",
  },
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12} sm={12} md={12} className={classes.listItem}>
            <Link style={{ margin: 8 }} href="http://vinous.com">
              <img
                className={classes.icon}
                style={{ marginTop: 4 }}
                src={vinousLogo}
                alt=""
              />
            </Link>
            <Link style={{ margin: 8 }} href="http://delectable.com">
              <img className={classes.icon} src={delectableLogo} alt="" />
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className={classes.listItem}>
            <Copyright />
          </Grid>
          <Grid item xs={12} sm={12} md={12} className={classes.listItem}>
            <Link style={{ color: "#fff", margin: 8 }} href="/terms">
              Terms
            </Link>
            <Link style={{ color: "#fff", margin: 8 }} href="/privacy">
              Privacy
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
