import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Title from "../title/index";
import { useCellarsContext } from "../../context/cellarsContext";
import { LivexIndex } from "../../types/Livex";
import { loadLivexIndicesPerformance } from "../../context/cellarsContext/actions";

export default function LivexIndices() {
  const {
    state: { livexIndices },
    dispatch
  } = useCellarsContext();


  React.useEffect(() => {
    if (!livexIndices) loadLivexIndicesPerformance(dispatch);
  }, [dispatch, livexIndices]);

  return (
    <>
      <Title>Livex Indices</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>30d</TableCell>
            <TableCell>YTD</TableCell>
            <TableCell>12m</TableCell>
            <TableCell>5y</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {livexIndices &&
            livexIndices.map((livexIndex: LivexIndex) => {
              if (livexIndex)
                return (
                  <TableRow key={livexIndex.name}>
                    <TableCell>{livexIndex.name}</TableCell>
                    <TableCell>{livexIndex.level}</TableCell>
                    <TableCell
                      style={{
                        color:
                          livexIndex.momPerf && livexIndex.momPerf < 0
                            ? "red"
                            : livexIndex.momPerf && livexIndex.momPerf > 0
                            ? "green"
                            : "inherit",
                      }}
                    >
                      {livexIndex.momPerf && livexIndex.momPerf > 0 ? "+" : ""}
                      {livexIndex.momPerf ? livexIndex.momPerf.toFixed(1) : 0.0}
                      %
                    </TableCell>
                    <TableCell
                      style={{
                        color:
                          livexIndex.ytdPerf && livexIndex.ytdPerf < 0
                            ? "red"
                            : livexIndex.ytdPerf && livexIndex.ytdPerf > 0
                            ? "green"
                            : "inherit",
                      }}
                    >
                      {livexIndex.ytdPerf && livexIndex.ytdPerf > 0 ? "+" : ""}
                      {livexIndex.ytdPerf ? livexIndex.ytdPerf.toFixed(1) : 0.0}
                      %
                    </TableCell>
                    <TableCell
                      style={{
                        color:
                          livexIndex.oneYearPerf && livexIndex.oneYearPerf < 0
                            ? "red"
                            : livexIndex.oneYearPerf &&
                              livexIndex.oneYearPerf > 0
                            ? "green"
                            : "inherit",
                      }}
                    >
                      {livexIndex.oneYearPerf && livexIndex.oneYearPerf > 0
                        ? "+"
                        : ""}
                      {livexIndex.oneYearPerf
                        ? livexIndex.oneYearPerf.toFixed(1)
                        : 0.0}
                      %
                    </TableCell>
                    <TableCell
                      style={{
                        color:
                          livexIndex.fiveYearPerf && livexIndex.fiveYearPerf < 0
                            ? "red"
                            : livexIndex.fiveYearPerf &&
                              livexIndex.fiveYearPerf > 0
                            ? "green"
                            : "inherit",
                      }}
                    >
                      {livexIndex.fiveYearPerf && livexIndex.fiveYearPerf > 0
                        ? "+"
                        : ""}
                      {livexIndex.fiveYearPerf
                        ? livexIndex.fiveYearPerf.toFixed(1)
                        : 0.0}
                      %
                    </TableCell>
                  </TableRow>
                );
              return null;
            })}
        </TableBody>
      </Table>
    </>
  );
}
