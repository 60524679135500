import React from "react";
import { CssBaseline, Paper, Grid, Container } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../components/util/theme";
import useStyles from "../../components/util/styles";
import CustomSnackbar from "../../components/snackbar";
import StripeElementsWrapper from "../../components/stripeElementsWrapper";
import CardDetailsForm from "./cardDetailsForm";

export const CardDetailsPage = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.rootLogin}>
        <CustomSnackbar />
        <Container maxWidth="md">
          <Grid
            item
            component={Paper}
            className={classes.paperLogin}
            elevation={6}
            square
          >
            <div className={classes.paperLogin}>
              <StripeElementsWrapper>
                <CardDetailsForm />
              </StripeElementsWrapper>
            </div>
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
};
