import React from "react";

import { Page, StyleSheet, View } from "@react-pdf/renderer";
import Header from "./cellarReportHeader";
import Body from "./cellarReportBody";
import { Cellar } from "../../types/Cellar";
import { CellarWine } from "../../types/CellarWine";

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  container: {
    flex: 1,
    flexDirection: "row",
  },
});

const ReportPage = (props: {
  cellar: Cellar;
  cellarWines: CellarWine[];
  today: string;
  currency: string;
}) => (
  <Page style={styles.page}>
    <Header
      cellar={props.cellar}
      today={props.today}
      currency={props.currency}
    />
    <View style={styles.container}>
      <Body
        cellar={props.cellar}
        cellarWines={props.cellarWines}
        currency={props.currency}
      />
    </View>
  </Page>
);

export default ReportPage;
