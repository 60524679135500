import React from "react";
import { LayoutWrapper } from "../../components/layoutWrapper";
import { Container, List, ListItem, ListItemText } from "@material-ui/core";
import useStyles from "../../components/util/styles";

const GLOSSARY_INFO = [
  { text: "About The Market Price", url: "https://www.liv-ex.com/knowledge/liv-ex-market-price/" },
  { text: "About Liv-ex Indices", url: "https://www.liv-ex.com/news-insights/indices/" }
];

const Glossary: React.FC = () => {
  const classes = useStyles();

  return (
    <LayoutWrapper pathName="glossary">
      <Container maxWidth="lg" className={classes.container}>
        <h1>Glossary</h1> 

        <List>
          { GLOSSARY_INFO.map(({ text, url }) => { 
            return (
              <ListItem button component="a" href={url} target="_blank">
                <ListItemText primary={text} />
              </ListItem>
            );
          })}
        </List>
      </Container>
    </LayoutWrapper>
  )
};

export default Glossary;
