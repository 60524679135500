import React from "react";
import { Button } from "@material-ui/core";
import { loadCellars } from "../../context/cellarsContext/actions";
import { editCellarWine } from "../../context/cellarWinesContext/actions";
import { useCellarsContext } from "../../context/cellarsContext";
import CellarModalForm from "../CellarModalForm";
import { useCellarWinesContext } from "../../context/cellarWinesContext";

const EditWine: React.FC<{ wine: any }> = ({ wine }) => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const { dispatch: cellarDispatch } = useCellarsContext();
  const { dispatch: wineDispatch } = useCellarWinesContext();

  const submitEditModalForm = async (e: any, form: any) => {
    e.preventDefault();
    type Errors = {
      [key: string]: boolean;
    };
    let errors: Errors = {
      costPerBottle: false,
      bottles: false,
    };
    if (!form.costPerBottle) {
      errors["costPerBottle"] = true;
    }
    if (!form.bottleCount) {
      errors["bottles"] = true;
    }

    if (Object.keys(errors).some((key: string) => errors[key])) {
      setFormErrors(errors);
    } else {
      await editCellarWine(wineDispatch, {
        ...form,
      });
      await loadCellars(cellarDispatch);
      setModalOpen(false);
    }
  };
  return (
    <>
      <CellarModalForm
        type="Edit"
        title="Edit Wine"
        open={isModalOpen}
        buttonText="Save"
        setOpen={setModalOpen}
        setFormErrors={setFormErrors}
        initialValues={wine}
        onSubmit={submitEditModalForm}
        errors={formErrors}
      />
      <Button color="primary" onClick={() => setModalOpen(true)}>
        Edit Wine
      </Button>
    </>
  );
};

export default EditWine;
