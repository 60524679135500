import axios, { AxiosError } from "axios";
import { hasToken } from "./localStorage";

let override: string | null = null;
// Uncomment to make it work locally.
// override = "http://localhost:3000";

// Create Axios instance with base parameters
const instance = axios.create({
  baseURL: override !== null ? override : process.env.REACT_APP_API_URL,
  timeout: 300000,
});

const baseHeadersForRequest = () => {
  return {
    Authorization: hasToken()
      ? `Bearer ${localStorage.getItem("token")}`
      : null,
    "Content-Type": "application/json",
  };
};

type Error = {
  status: number;
  err: string;
  code: number;
  stack: any;
};

/**
 * Format error from Axios request for us
 * @param err Axios error object
 */
export const axiosErr = (err: AxiosError): Error | null => {
  if (err.response && err.response.data) {
    console.log("AxiosErr", err);
    return err.response.data;
  }
  return null;
};

export const get = async (url: string, params?: object | null) => {
  return instance({
    url,
    params: params,
    method: "GET",
    headers: baseHeadersForRequest(),
  }).then((res) => res.data);
};

export const post = async (url: string, params: object) => {
  return instance({
    url,
    method: "POST",
    data: params,
    headers: baseHeadersForRequest(),
  }).catch((err) => {
    const error = axiosErr(err);
    throw error ? error.err : err;
  });
};

export const put = async (url: string, params: object, headers?: object) => {
  return instance({
    url,
    method: "PUT",
    data: params,
    headers: headers ? headers : baseHeadersForRequest(),
  }).catch((err) => {
    const error = axiosErr(err);
    throw error ? error.err : err;
  });
};

export const deleteReq = async (url: string, params: object | null) => {
  return instance({
    url,
    method: "DELETE",
    data: params,
    headers: baseHeadersForRequest(),
  });
};

const client = {
  get,
  post,
  put,
  deleteReq,
};

export default client;
