import React from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useHistory, useLocation } from "react-router";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CssBaseline,
  Grid,
  Typography,
  Container,
  Switch,
  Box,
} from "@material-ui/core";
import useStyles from "../../components/util/styles";
import Header from "../../components/header";
import {
  createCheckoutSessionRequest,
  subscribeToFreePlanRequest,
  upgradeSubscriptionRequest,
  validateAndProcessCoupon,
  validateCouponCode,
} from "../../lib/subscriptionRequests";
import { useAuth } from "../../context/authContext";
import {
  getActiveSubscription,
  getCurrentUser,
  getOldPlan,
  showMessage,
} from "../../context/authContext/actions";
import Copyright from "../../components/copyright";
import CustomSnackbar from "../../components/snackbar";

export default function Pricing() {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const {
    state: { loading, user, isPremiumUser, paymentCurrency, oldPlan, token },
    dispatch: authDispatch,
  } = useAuth();
  const [curr, setCurr] = React.useState("USD");

  const coupon = params.get("coupon");
  const title = params.get("title");
  const localToken = localStorage.getItem("token");

  React.useEffect(() => {
    if(!loading) {
      if(paymentCurrency) {
        setCurr(paymentCurrency);  
      }

      if(!user && (token || localToken)) {
        getCurrentUser(authDispatch);
        getActiveSubscription(authDispatch);
      }

      if (!oldPlan && user && user.old_sub_id) {
        getOldPlan(authDispatch, user.old_sub_id);
      }

      if((coupon && coupon !== "null") && user) {
        validateCoupon(coupon);
      } else if (title && user) {
        if (title === "Basic") {
          const redirectToFree = async () => {
            await subscribeToFreePlanRequest(curr);
            history.push("/cellars");
            return;
          };
          redirectToFree();
        } else {
          redirectToCheckout(title, coupon);
        }
      }
    }
  }, 
  // eslint-disable-next-line
  [
    loading, 
    paymentCurrency, 
    user, 
    token, 
    localToken, 
    authDispatch, 
    oldPlan, 
    coupon, 
    title,
    curr,
    history,
  ]);

  const tiers = [
    {
      title: "Basic",
      priceUSD: "0",
      priceGBP: "0",
      description: [
        "\u2022 Five wine page views per day",
        "\u2022 Five price searches per day",
        "\u2022 Create one cellar (five wines only)",
      ],
      buttonText: "Sign up for free",
      buttonVariant: "outlined",
    },
    {
      title: "Premium",
      subheader: "",
      priceUSD: "9.99",
      priceGBP: "7.99",
      description: [
        "\u2022 Unlimited price searches",
        "\u2022 Unlimited wine page views",
        "\u2022 Receive Liv-ex's Trade-Only Market Report with Exclusive Vinous Additions",
        "\u2022 Receive a Monthly Cellar Valuation Report of your Cellar",
        "\u2022 Create one cellar (unlimited wines)",
      ],
      buttonText: `Sign up ${curr === "GBP" ? "£7.99" : "$9.99"}`,
      buttonVariant: "contained",
    },
    {
      title: "Pro",
      priceUSD: "24.99",
      priceGBP: "19.99",
      description: [
        "\u2022 Same as Premium, plus:",
        "\u2022 Create unlimited cellars",
      ],
      buttonText: `Sign up ${curr === "GBP" ? "£19.99" : "$24.99"}`,
      buttonVariant: "outlined",
    },
  ];

  const toggleCurrency = async (e: any) => {
    curr === "USD" ? setCurr("GBP") : setCurr("USD");
  };

  const validateCoupon = async (code: string | null) => {
    if(!code || !code.length) return false;
    showMessage(authDispatch, "Validating coupon. Please wait...");

    let response = await validateCouponCode(code);

    if(response.promoCode.count > 0) {
      let promoCode = response.promoCode.data[0];

      if(promoCode.active && promoCode.coupon.valid && promoCode.coupon.percent_off === 100) {
        skipCheckout(promoCode.coupon.id);
      } else {
        redirectToCheckout("Premium", promoCode.coupon.id);
      }
    } else {
      showMessage(authDispatch, "Invalid coupon.");
    }
  }

  const skipCheckout = async(coupon: string) => {
    const response = await validateAndProcessCoupon(title, coupon ? coupon : null, paymentCurrency);
    if(response.data.subscription_id) {
      showMessage(authDispatch, "Your subscription has been created successfully.");
      history.push('/cellars');
    } else if(response.data.message) {
      showMessage(authDispatch, response.data.message);
    }
  }

  const redirectToCheckout = async(title: string, coupon: string | null) => {
    if(!stripe || !elements) return setTimeout(() => { window.location.reload(); }, 500);

    const response = await createCheckoutSessionRequest(title, coupon ? coupon : null, curr);
      stripe
        .redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: response.data.session_id,
        })
        .then(function (result) {
          console.log("redirectToCheckout failed with: ", result.error.message);
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        });
  }

  const handleSubmit = async (e: any, title: string) => {
    e.preventDefault();
    let coupon = params.get("coupon");

    if (!stripe || !elements) {
      // Stripe.js or User has not yet loaded.
      return;
    }

    if (user) {
      if (title === "Basic") {
        await subscribeToFreePlanRequest(curr);
        history.push("/cellars");
        return;
      }
  
      if (!!isPremiumUser && title === "Pro") {
        // Upgrading to the Pro tier
        await upgradeSubscriptionRequest("Pro", curr);
        history.push("/cellars");
      } else {
        redirectToCheckout(title, coupon);
      }
    } else {
      let url = "/signup";
      if(title) url += "?title=" + title;
      if(coupon) url += "&coupon=" + coupon;
      if(curr) url += "&currency=" + curr;

      history.push(url);
    }
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="lg">
        {!user && <Header />}
        {/* Hero unit */}
        <Container
          maxWidth="sm"
          component="main"
          className={classes.heroContent}
        >
          <Typography
            component="h4"
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            {!!isPremiumUser
              ? "Upgrade to Pro plan"
              : "Choose the plan that's right for you"}
          </Typography>
          {!user &&
              <Grid
                component="label"
                container
                alignItems="center"
                justify="center"
                spacing={1}
              >
                <Grid item>USD</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={curr === "GBP"}
                    onChange={toggleCurrency}
                    name="currency"
                  />
                </Grid>
                <Grid item>GBP</Grid>
              </Grid>
            }
        </Container>
        {/* End hero unit */}
        <CustomSnackbar />
        <Container maxWidth="md" component="main">
          <Grid container spacing={2} alignItems="flex-start">
            {tiers.map((tier) => (
              <Grid item key={tier.title} xs={12} sm={4}>
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    subheaderTypographyProps={{ align: "center" }}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography
                        component="h2"
                        variant="h3"
                        color="textPrimary"
                      >
                        {tier.title === "Basic"
                          ? "Free"
                          : `${curr === "GBP" ? "£" + tier.priceGBP : "$" + tier.priceUSD}`}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        {tier.title === "Basic" ? "" : "/mo"}
                      </Typography>
                    </div>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions className={classes.cardPricing}>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={
                        (!!isPremiumUser && tier.title !== "Pro") ||
                        (isPremiumUser !== undefined &&
                          tier.title === "Basic") ||
                        (oldPlan &&
                          oldPlan.renewsAt !== undefined &&
                          Date.parse(oldPlan.renewsAt) > Date.now() &&
                          (tier.title !== "Pro" || oldPlan.plan === "Pro"))
                      }
                      onClick={(e) => {
                        handleSubmit(e, tier.title);
                      }}
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
      <Box mt={5}>
        <Copyright />
      </Box>
    </>
  );
}
