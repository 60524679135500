import { Action, Dispatch } from "../../types/Action";
import { CellarWine, DisposedWine } from "../../types/CellarWine";

export const parseCellarWine = (cellarWine: any) => {
  const {
    id,
    cellar_id: cellarId,
    wine_lwin: lwin,
    display_name: displayName,
    region,
    condition_notes: conditionNotes,
    total_cost: totalCost,
    cost_per_bottle: costPerBottle,
    is_visible: isVisible,
    purchase_date: purchaseDate,
    stored_at: storedAt,
    supplier,
    cache_value: cacheValue,
    bottle_size: bottleSize,
    bottle_count: bottleCount,
    bottle_packed_as: bottlePackedAs,
    vintage,
    marketPrice,
    initialCost,
    totalValue,
    performance,
    secondaryPriceData,
    image_url,
    updated_at: updatedAt,
    created_at: createdAt,
    deleted_at: deletedAt,
    drink_from: drinkFrom,
    drink_till: drinkTo,
    score_from: scoreFrom,
    score_to: scoreTo,
    tasting_note: tastingNote,
    colour,
    historicData,
  } = cellarWine;

  const lwinDetail = { lwin: "", displayName: "" };

  return {
    id,
    cellarId,
    displayName,
    region,
    lwin,
    lwinDetail,
    conditionNotes,
    totalCost,
    costPerBottle,
    isVisible,
    purchaseDate,
    storedAt,
    supplier,
    cacheValue,
    bottleSize,
    bottleCount,
    bottlePackedAs,
    vintage,
    marketPrice,
    initialCost,
    totalValue,
    performance,
    image_url,
    updatedAt,
    createdAt,
    deletedAt,
    drinkFrom,
    drinkTo,
    scoreFrom,
    scoreTo, 
    tastingNote,
    colour,
    historicData,
    secondaryPriceData,
  } as CellarWine;
};

export type ICellarWinesContext = {
  state: CellarWinesState;
  dispatch: Dispatch;
};

export type CellarWinesState = {
  loadingWines?: boolean,
  cellarWines?: CellarWine[],
  disposedWines?: DisposedWine[],
}

const initialCellarWinesState: CellarWinesState = {
  loadingWines: false,
  cellarWines: [],
  disposedWines: []
}

export const cellarWinesReducer = (state: CellarWinesState, action: Action) => {
  switch (action.type) {
    case "loadingCellarWines": {
      return { ...state, loadingWines: true };
    }
    case "setCellarWines": {
      const parsedWines = action.payload.cellarWines.map((cellarWine: CellarWine) => {
        return parseCellarWine(cellarWine);
      });

      return {
        ...state,
        loadingWines: false,
        cellarWines: action.payload.override ? [...parsedWines] : [...state.cellarWines!, ...parsedWines]
      };
    }
    case "setCellarWineChartData": {
      const { lwin11, data } = action.payload;
      const wineIds =
        state.cellarWines &&
        Object.values(state.cellarWines)
          .filter(
            ({ lwin, vintage, bottleCount }: CellarWine) =>
              `${lwin}${vintage}` === lwin11 && bottleCount > 0
          )
          .map(({ id }: CellarWine) => id);

      const newObj =
        wineIds &&
        wineIds
          .map((id) => {
            if (state.cellarWines) {
              return {
                [id]: { ...state.cellarWines, historicData: data },
              };
            }
            return null;
          })
          .filter((a: any) => a)
          .reduce((acc: any, curr: any) => {
            return { ...acc, ...curr };
          }, {});

      return {
        ...state,
        cellarWines: {
          ...state.cellarWines,
          ...newObj,
        },
      };
    }
    case "deleteCellarWine": {
      const { updated_cellar_wines } = action.payload;

      if (state.cellarWines) {
        delete state.cellarWines[state.cellarWines!.findIndex(wine => wine.id === updated_cellar_wines[0].id)];

        return { ...state };
      } else {
        return { ...state };
      }
    }
    case "sellCellarWine": {
      const { cellar_wines_disposals } = action.payload;
      var stateWine: CellarWine = state.cellarWines!.find(wine => wine.id === cellar_wines_disposals[0].cellar_wine_id) as CellarWine;

      if(stateWine) {
        stateWine.bottleCount = stateWine.bottleCount - parseInt(cellar_wines_disposals[0].disposed_qty);
        stateWine.totalCost = stateWine.cost_per_bottle! * stateWine.bottleCount;
      }

      return {
        ...state
      };
    }
    case "transferCellarWine": {
      const {
        wineIdTransferred,
      } = action.payload;

      delete state.cellarWines![state.cellarWines!.findIndex(wine => wine.id === wineIdTransferred)];

      return { ...state };
    }
    case "editCellarWine": {
      const { cellarWine } = action.payload;
      const cw = parseCellarWine(cellarWine);

      // eslint-disable-next-line
      var stateWine: CellarWine = state.cellarWines!.find(wine => wine.id === cw.id) as CellarWine;

      (Object.keys(stateWine) as Array<keyof CellarWine>).forEach(<K extends keyof CellarWine>(key: K) => {
        if(cw[key] && cw[key] !== stateWine[key]) {
          stateWine[key] = cw[key]
        }
      })

      return {
        ...state
      };
    }
    case "setDisposedWines": {
      const { disposedWines } = action.payload;

      return {
        ...state,
        disposedWines: disposedWines
      };
    }
    case "clearCellarWineState": {
      return initialCellarWinesState;
    }
    case "setCellarWinesError": {
      return state;
      // return initialCellarWinesState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
