export type Action = {type: 'loginError', payload: any } |
  { type: "signupError", payload: any } |
  { type: any, payload?: any };

export type Dispatch = (action: Action) => void;

export type ErrorMessage = {
  code: number;
  message: string;
}

export type ErrorState = {
  visible: boolean;
  errorMessage: string;
};

export const errorReducerInitialState = {
  visible: false,
  errorMessage: "test error message",
};

export const errorReducer = (state: ErrorState, action: Action) => {
  console.log('errorReducer state, action', state, action);
  switch (action.type) {
    case "loginError": {
      return {
        ...state,
        visible: true,
        errorMessage: state.errorMessage
      }
    }
    case "signupError": {
      return {
        ...state,
        visible: true,
        errorMessage: state.errorMessage
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
};
