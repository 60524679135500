import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  TextField,
  Grid,
  Box,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import useStyles from "../util/styles";
import { bottleFormats, packedAsOptions } from "../../config";

const getCostByType = (form: any) => {
  if(!form.costPerBottle) form.costPerBottle = form.totalCost / form.bottleCount;
  switch(form.costType) {
    case "case":
      return form.bottlePackedAs * form.costPerBottle
    case "bottle":
      return form.costPerBottle
    case "total_cost":
    default:
      return form.bottleCount * form.costPerBottle
  }
}

const getCostPerBottleByType = (form: any, cost: number) => {
  switch(form.costType) {
    case "case":
      return cost / form.bottlePackedAs
    case "bottle":
      return cost
    case "total_cost":
    default:
      return cost / form.bottleCount
  }
}

const EditFields: React.FC<{
  form: any;
  setForm: (obj: any) => void;
  setFormErrors: (obj: any) => void;
  errors: any;
  currency: string;
}> = ({ form, setForm, errors, setFormErrors, currency }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <TextField
          label="Bottles"
          id="edit-bottles"
          helperText={errors.bottles && "Required"}
          error={errors.bottles}
          onChange={(e) => {
            errors.bottles && setFormErrors({ ...errors, bottles: false });
            setForm({
              ...form,
              bottleCount: (e.target.value as unknown) as number,
            });
          }}
          defaultValue={form.bottleCount}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <InputLabel style={{ fontSize: "12px" }} htmlFor="size">
          Size
        </InputLabel>
        <Select
          classes={{ root: classes.selectRoot }}
          labelId="size"
          value={form.bottleSize ? parseInt(form.bottleSize) : "75"}
          fullWidth
          onChange={(e) => {
            setForm({
              ...form,
              bottleSize: (e.target.value as unknown) as string,
            });
          }}
          inputProps={{
            name: "Size",
            id: "size",
          }}
        >
          {bottleFormats.map((f) => (
            <MenuItem key={f} value={f}>{`${f}cl`}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={3}>
        <InputLabel style={{ fontSize: "12px" }} htmlFor="packed-as">
          Packed As
        </InputLabel>
        <Select
          fullWidth
          labelId="Packed as"
          classes={{ root: classes.selectRoot }}
          value={form.bottlePackedAs || ""}
          onChange={(e) => {
            setForm({
              ...form,
              bottlePackedAs: (e.target.value as unknown) as number,
            });
          }}
          inputProps={{
            name: "Packed As",
            id: "packed-as",
          }}
        >
          {packedAsOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option !== "Packed As"
                ? `${option}x${parseFloat(form.bottleSize)}cl`
                : option}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box display="flex">
          <TextField
            id="edit-total-cost"
            helperText={errors.costPerBottle && "Invalid cost"}
            error={errors.costPerBottle}
            label={`Cost (${currency})`}
            onChange={(e) => {
              const numbers = /^[0-9\b]+$/;
              errors.costPerBottle && setFormErrors({ ...errors, costPerBottle: false });
              if (e.target.value === "" || numbers.test(e.target.value)) {
                const costPerBottle = (e.target.value as unknown) as number;
                setForm({
                  ...form,
                  costPerBottle: getCostPerBottleByType(form, costPerBottle),
                });
              } else {
                setFormErrors({ ...errors, costPerBottle: true });
              }
            }}
            value={Math.round(getCostByType(form))}
          />
          <Select
            classes={{ root: classes.selectRoot }}
            value={form.costType || "total_cost"}
            onChange={(e) => {
              setForm({
                ...form,
                costType: (e.target.value as unknown) as string,
              });
            }}
            inputProps={{
              name: "Packed As",
              id: "packed-as",
            }}
          >
            <MenuItem value="total_cost">/ Total</MenuItem>
            <MenuItem value="bottle">/ Bottle</MenuItem>
            <MenuItem value="case">/ Case</MenuItem>
          </Select>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          classes={{ root: classes.textRoot }}
          label="Stored at"
          onChange={(e) =>
            setForm({
              ...form,
              storedAt: (e.target.value as unknown) as number,
            })
          }
          defaultValue={form.storedAt}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          classes={{ root: classes.textRoot }}
          fullWidth
          label="Condition"
          onChange={(e) =>
            setForm({
              ...form,
              conditionNotes: (e.target.value as unknown) as number,
            })
          }
          defaultValue={form.conditionNotes}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          classes={{ root: classes.textRoot }}
          fullWidth
          label="Supplier"
          onChange={(e) =>
            setForm({
              ...form,
              supplier: (e.target.value as unknown) as number,
            })
          }
          defaultValue={form.supplier}
        />
      </Grid>
      <Grid item xs={12} sm={6} classes={{ root: classes.datePickerContainer }}>
        <KeyboardDatePicker
          format="MM/dd/yyyy"
          fullWidth
          margin="none"
          id="purchase-date-picker-inline"
          label="Purchase Date"
          value={form.purchaseDate || new Date()}
          onChange={(e) => setForm({ ...form, purchaseDate: e as Date })}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <KeyboardDatePicker
          id="drink-from-picker-inline"
          label="Drink From"
          value={form.drinkFrom}
          onChange={(e) => setForm({ ...form, drinkFrom: e as Date })}
          views={["year"]}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <KeyboardDatePicker
          id="drink-to-picker-inline"
          label="Drink To"
          value={form.drinkTo}
          onChange={(e) => setForm({ ...form, drinkTo: e as Date })}
          views={["year"]}
        />
      </Grid>
    </Grid>
  );
};

export default EditFields;
