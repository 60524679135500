import withRoot from "./modules/withRoot";
// --- Post bootstrap -----
import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "./modules/components/Typography";
import AppFooter from "./modules/views/AppFooter";
import Header from "../../components/header";
import Markdown from "./modules/components/Markdown";
import terms from "./terms.md";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = { md: "" };
  }

  componentWillMount() {
    fetch(terms)
      .then((res) => res.text())
      .then((md) => {
        this.setState({ md });
      });
  }

  render() {
    let { md } = this.state;

    return (
      <React.Fragment>
        <Header />
        <Container>
          <Box mt={7} mb={12}>
            <Typography
              variant="h4"
              gutterBottom
              marked="center"
              align="center"
            >
              Terms Of Service
            </Typography>
            <Markdown children={md} />
          </Box>
        </Container>
        <AppFooter />
      </React.Fragment>
    );
  }
}

export default withRoot(Terms);
