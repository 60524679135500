import React from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Link,
  Typography,
  Container,
  ThemeProvider,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "../../components/util/styles";
import { useAuth, createUser } from "../../context/authContext";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Copyright from "../../components/copyright";
import Header from "../../components/header";
import { RouteComponentProps, useParams } from "react-router-dom";
import CustomSnackbar from "../../components/snackbar";
import {
  emailError,
  firstNameError,
  lastNameError,
  passwordError,
  validEmail,
  validName,
  validPassword,
} from "../../context/authContext/actions";
import theme from "../../components/util/theme";

export type SignupFormState = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  emailError: string;
  passwordError: string;
  firstNameError: string;
  lastNameError: string;
  loading: boolean;
};

export default function SignUp({ history, location }: RouteComponentProps) {
  const classes = useStyles();
  const params = new URLSearchParams(location.search);
  const { dispatch } = useAuth();
  const { email, token, fname, lname } = useParams<{ email: string, token: string, fname: string, lname: string }>();

  const [signupForm, updateSignupForm] = React.useState<SignupFormState>({
    firstName: fname ? fname : "",
    lastName: lname ? lname : "",
    email: email ? email : "",
    password: "",
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    passwordError: "",
    loading: false,
  });

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const customerId = params.get("customer_id");
    const currency = params.get("currency") || "USD";

    updateSignupForm({
      ...signupForm,
      loading: true,
    });

    validName(signupForm.firstName) &&
      validName(signupForm.lastName) &&
      validEmail(signupForm.email) &&
      validPassword(signupForm.password) &&
      (await createUser(
        dispatch,
        {
          ...signupForm,
          verifyEmailToken: token,
          customerId: customerId || null,
          currency: currency
        },
        history,
        params
      ));

    updateSignupForm({
      ...signupForm,
      firstNameError: firstNameError(signupForm.firstName),
      lastNameError: lastNameError(signupForm.lastName),
      emailError: emailError(signupForm.email),
      passwordError: passwordError(signupForm.password),
      loading: false,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.rootLogin}>
        <Header />
        <CustomSnackbar />
        <Container maxWidth="md">
          <Grid
            item
            component={Paper}
            className={classes.paperLogin}
            elevation={6}
            square
          >
            <div className={classes.paperLogin}>
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.avatarContainer}>
                    <Avatar className={classes.avatar}>
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Sign up
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      error={signupForm.firstNameError.length > 0}
                      helperText={signupForm.firstNameError}
                      id="firstName"
                      label="First Name"
                      value={signupForm.firstName}
                      onChange={(e) =>
                        updateSignupForm({
                          ...signupForm,
                          firstName: e.target.value,
                          firstNameError: firstNameError(e.target.value),
                        })
                      }
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      error={signupForm.lastNameError.length > 0}
                      helperText={signupForm.lastNameError}
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      value={signupForm.lastName}
                      onChange={(e) =>
                        updateSignupForm({
                          ...signupForm,
                          lastName: e.target.value,
                          lastNameError: lastNameError(e.target.value),
                        })
                      }
                      autoComplete="lname"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      disabled={!!email}
                      error={signupForm.emailError.length > 0}
                      helperText={signupForm.emailError}
                      id="email"
                      label="Email Address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={signupForm.email}
                      onChange={(e) =>
                        updateSignupForm({
                          ...signupForm,
                          email: e.target.value,
                          emailError: emailError(e.target.value),
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      error={signupForm.passwordError.length > 0}
                      helperText={signupForm.passwordError}
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={signupForm.password}
                      onChange={(e) => {
                        updateSignupForm({
                          ...signupForm,
                          password: e.target.value,
                          passwordError: passwordError(e.target.value),
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {signupForm.loading ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleFormSubmit(e)}
                        href="#"
                      >
                        Sign Up
                      </Button>
                    )}
                  </Grid>
                  {!email && (
                    <Grid container>
                      <Grid item xs style={{ margin: 12 }}>
                        <Link href="/forgotPassword">Forgot password?</Link>
                      </Grid>
                      <Grid
                        item
                        style={{
                          margin: 12,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Link href="/login">
                          {"Already have an account? Sign In"}
                        </Link>
                        <Link href="/onboarding" style={{ marginTop: 4 }}>
                          {
                            "Existing CW user and first time on new site? Click here."
                          }
                        </Link>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </div>
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
}
