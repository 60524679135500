import { Dispatch } from "../../types/Action";
import { CellarWineParams } from "../../types/CellarWine";
import { 
  getCellarWinesForCellar, 
  getDisposedWinesForCellarRequest, 
  createCellarWine as createCellarWineRequest,
  sellCellarWine as sellCellarWineRequest,
  editCellarWine as editCellarWineRequest,
  deleteCellarWine as deleteCellarWineRequest,
  transferCellarWine as transferCellarWineRequest 
} from "../../lib/cellarRequests"

export const transferCellarWine = async (
  dispatch: Dispatch,
  wineId: string,
  startingCellarId: string,
  transferCellarId: string
) => {
  try {
    const response = await transferCellarWineRequest(wineId, transferCellarId);
    const { transfer } = response.data;
    const wineIdTransferred = transfer[0].id;

    dispatch({
      type: "transferCellarWine",
      payload: {
        wineIdTransferred,
        startingCellarId,
        transferCellarId,
        message: "Wine successfully transferred",
      },
    });
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const loadCellarWines = async (
    dispatch: Dispatch, 
    slug: string, 
    limit: number, 
    offset: number, 
    sortColumn: string, 
    sortDirection: boolean,
    override: boolean
) => {
  try {
    dispatch({
      type: "loadingCellarWines"
    });

    const response = await getCellarWinesForCellar(slug, limit, offset, sortColumn, sortDirection)

    dispatch({
      type: "setCellarWines",
      payload: {
        cellarWines: response.cellarWines,
        override: override
      }
    })
  } catch(err) {
    dispatch({
      type: "setCellarWinesError",
      payload: err
    })
  }
}

export const createCellarWine = async (
  dispatch: Dispatch,
  params: CellarWineParams
) => {
  try {
    await createCellarWineRequest(params);
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const deleteCellarWine = async (
  dispatch: Dispatch,
  wineId: string,
  cellarId: string
) => {
  try {
    const response = await deleteCellarWineRequest(wineId);
    const { updated_cellar_wines } = response.data;
    dispatch({
      type: "deleteCellarWine",
      payload: {
        updated_cellar_wines,
        cellarId,
        message: "Wine successfully deleted",
      },
    });
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const sellCellarWine = async (
  dispatch: Dispatch,
  wineId: string,
  cellarId: string,
  sellDate: string,
  value: string,
  bottles: number
) => {
  try {
    const response = await sellCellarWineRequest(
      cellarId,
      wineId,
      sellDate,
      value,
      bottles
    );
    const { cellar_wines_disposals } = response.data;
    dispatch({
      type: "sellCellarWine",
      payload: {
        cellar_wines_disposals,
        cellarId,
        message: "Wine successfully sold",
      },
    });
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const getDisposedWines = async (
  dispatch: Dispatch,
  cellarId: string
) => {
  try {
    const response = await getDisposedWinesForCellarRequest(cellarId);

    const { disposedWines } = response;

    dispatch({
      type: "setDisposedWines",
      payload: { disposedWines, cellarId },
    });
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const editCellarWine = async (
  dispatch: Dispatch,
  params: CellarWineParams
) => {
  try {
    const response = await editCellarWineRequest(params);

    const { updated_cellar_wines } = response.data;
    const cellarWine = updated_cellar_wines[0];

    dispatch({
      type: "editCellarWine",
      payload: { cellarWine },
    });
  } catch (err) {
    dispatch({
      type: "setCellarsError",
      payload: err,
    });
  }
};

export const clearCellarWines = async (dispatch: Dispatch) => {
  dispatch({
    type: "clearCellarWineState"
  })
}